import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Candidate } from '../../candidate/types/api';
import Column from '../../core/components/Column';
import PageHeading from '../../core/components/PageHeading';
import Row from '../../core/components/Row'; import Text from '../../core/components/Text';
import { useReducerContext } from '../../core/contexts/ReducerContext';
import { encodeCandidateId } from '../utils';

interface CandidateOverviewProps {
    candidate: Candidate;
}

const LocalPageHeading = styled(PageHeading)`
  font-size: 20px;
  line-height: 29px;
`;

const Container = styled.div`
  width: 100%;
  padding: 20px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  border-radius: 5px;
`;

const Avatar = styled.div`
  width: 53px;
  height: 53px;
  background: #D5D5D5;
  border-radius: 5px;
  
  img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
  }
`;

const Information = styled.div`
  display: grid;
  grid-template-columns: min-content auto;
  grid-column-gap: 20px;
  grid-row-gap: 5px;
  width: 100%;
  margin-top: 20px;
  overflow: hidden;
  
  > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  > *:nth-child(odd) {
    color: #999999;
  }
`;

const CandidateOverview: FunctionComponent<CandidateOverviewProps> = ({
    candidate,
}) => {
    const { state } = useReducerContext();
    const { t } = useTranslation();

    return (
        <Container>
            <Row style={{ gap: 20 }} center>
                {state.isPartner && (
                    <Avatar>
                        {candidate.image && (
                            <img alt="avatar" src={candidate.image.url} />
                        )}
                    </Avatar>
                )}
                <Column style={{ gap: 8 }}>
                    {state.isPartner
                        ? (
                            <LocalPageHeading>
                                {candidate.enFirstName}
                                {' '}
                                {candidate.enMiddleName}
                                {' '}
                                {candidate.enLastName}
                            </LocalPageHeading>
                        )
                        : (
                            <LocalPageHeading>
                                {encodeCandidateId(candidate.id)}
                            </LocalPageHeading>
                        )
                    }
                    <Row>
                        <Text>
                            {candidate.age
                                ? t('scout.age', { number: candidate.age})
                                : '-'
                            }
                        </Text>
                        <Text style={{ marginLeft: 8 }}>
                            {candidate.gender
                                ? t(`core.${candidate.gender}`)
                                : '-'
                            }
                        </Text>
                        <Text style={{ marginLeft: 8 }}>
                            {state.nationalities[candidate.nationalityId]?.name ?? '-'}
                        </Text>
                    </Row>
                </Column>
            </Row>

            <Information>
                <Text>{t('candidate.candidate_visa_status')}</Text>
                <Text>
                    {candidate.visaStatus
                        ? t(`candidate.${candidate.visaStatus}`)
                        : '-'
                    }
                </Text>
                <Text>{t('candidate.japanese_conversation_skills')}</Text>
                <Text>
                    {candidate.japaneseConversationSkills
                        ? t(`candidate.${candidate.japaneseConversationSkills}`)
                        : '-'
                    }
                </Text>
                <Text>{t('candidate.jlpt_level')}</Text>
                <Text>{candidate.jlptLevel?.toUpperCase() ?? '-'}</Text>
                {state.isPartner && (
                    <>
                        <Text>{t('candidate.current_address')}</Text>
                        <Text>{candidate.currentAddress}</Text>
                    </>
                )}
            </Information>
        </Container>
    );
};

export default CandidateOverview;