import { FunctionComponent, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import ButtonGroup from '../../../core/components/ButtonGroup';
import CategoryHeading from '../../../core/components/CategoryHeading';
import Checkbox from '../../../core/components/Checkbox';
import Field from '../../../core/components/Field';
import HelperText from '../../../core/components/HelperText';
import Label from '../../../core/components/Label';
import MultiTextInput from '../../../core/components/MultiTextInput';
import PostalCodeInput from '../../../core/components/PostalCodeInput';
import Radio from '../../../core/components/Radio';
import Row from '../../../core/components/Row';
import Section from '../../../core/components/Section';
import SectionHeading from '../../../core/components/SectionHeading';
import Select, { SelectOption } from '../../../core/components/Select';
import Text from '../../../core/components/Text';
import TextInput from '../../../core/components/TextInput';
import DatePicker from '../../../core/components/DatePicker';
import { useJobPostOptions } from '../../../core/hooks';

const createEmploymentDateOptions = async (language: string) => {
    try {
        await import(`moment/locale/${language}`);
    } catch (e) {
    }

    const localMoment = moment();
    localMoment.locale(language);
    return new Array(20)
        .fill(0)
        .map((item, index) => {
            const date: Moment = localMoment
                .clone()
                .add(index, 'month')
                .set({
                    date: 1,
                    hour: 0,
                    minute: 0,
                    second: 0,
                    millisecond: 0,
                });
            return {
                label: date.format('MMMM YYYY'),
                value: date.format('YYYY-MM-DD'),
            };
        });
};

interface BasicInformationSectionProps {
    jobOfferTitle?: string;
    numberOfPositions?: string;
    applicationDeadline?: Date;
    plannedEmploymentDate?: string;
    industry?: string;
    jobType?: string;
    contractType?: string;
    contractPeriod?: string;
    contractPeriodYears?: string;
    contractRenewal?: string;
    overseasResidentsAccepted?: boolean;
    postalCode?: string;
    prefecture?: string;
    city?: string;
    town?: string;
    address?: string;
    building?: string;
    description?: string;
    onJobOfferTitleChange?: (jobOfferTitle: string) => void;
    onNumberOfPositionsChange?: (numberOfPositions: string) => void;
    onApplicationDeadlineChange?: (applicationDeadline: Date) => void;
    onPlannedEmploymentDateChange?: (plannedEmploymentDate: string) => void;
    onIndustryChange?: (industry: string) => void;
    onJobTypeChange?: (jobType: string) => void;
    onContractTypeChange?: (contractType: string) => void;
    onContractPeriodChange?: (contractPeriod: string) => void;
    onContractPeriodYearsChange?: (contractPeriodYears: string) => void;
    onContractRenewalChange?: (contractRenewal: string) => void;
    onOverseasResidentsAccepted?: (overseasResidentsAccepted: boolean) => void;
    onPostalCodeAddressIdChange?: (id: string) => void;
    onPostalCodeChange?: (postalCode: string) => void;
    onPrefectureChange?: (prefecure: string) => void;
    onCityChange?: (city: string) => void;
    onTownChange?: (town: string) => void;
    onAddressChange?: (address: string) => void;
    onBuildingChange?: (building: string) => void;
    onDescriptionChange?: (description: string) => void;
};

const BasicInformationSection: FunctionComponent<BasicInformationSectionProps> = ({
    jobOfferTitle,
    numberOfPositions,
    applicationDeadline,
    plannedEmploymentDate,
    industry,
    jobType,
    contractType,
    contractPeriod,
    contractPeriodYears,
    contractRenewal,
    overseasResidentsAccepted,
    postalCode,
    prefecture,
    city,
    town,
    address,
    building,
    description,
    onJobOfferTitleChange,
    onNumberOfPositionsChange,
    onApplicationDeadlineChange,
    onPlannedEmploymentDateChange,
    onIndustryChange,
    onJobTypeChange,
    onContractTypeChange,
    onContractPeriodChange,
    onContractPeriodYearsChange,
    onContractRenewalChange,
    onOverseasResidentsAccepted,
    onPostalCodeAddressIdChange,
    onPostalCodeChange,
    onPrefectureChange,
    onCityChange,
    onTownChange,
    onAddressChange,
    onBuildingChange,
    onDescriptionChange,
}) => {
    const [employmentDateOptions, setEmploymentDateOptions] = useState<SelectOption[]>([]);
    const { industryOptions, jobTypeOptionsDic } = useJobPostOptions();
    const jobTypeOptions = industry ? jobTypeOptionsDic[industry] : [];
    const isEmpty = (value: any) => !(!!value);
    const { t, i18n } = useTranslation();
 

    useEffect(() => {
        (async () => {
            const employmentDateOptions = await createEmploymentDateOptions(i18n.language);
            setEmploymentDateOptions(employmentDateOptions);
        })();
    }, [i18n.language]);

    return (
        <Section>
            <SectionHeading>{t('job.basic_information')}</SectionHeading>

            <Field style={{ width: '100%' }}>
                <Label required>{t('job.post_title')}</Label>
                <TextInput
                    placeholder={t('job.post_title_example')}
                    style={{ width: '100%' }}
                    value={jobOfferTitle}
                    error={isEmpty(jobOfferTitle)}
                    onTextChange={onJobOfferTitleChange}
                />
                <HelperText>{t('job.post_title_description')}</HelperText>
                {   isEmpty(jobOfferTitle) &&
                    <Text error={true} style={ {fontSize: 12}}>{t('core.input_prompt.text_field')}</Text>
                }
            </Field>

            <Row>
                <Field>
                    <Label required>{t('job.number_of_positions')}</Label>
                    <TextInput
                        type="number"
                        placeholder="0"
                        style={{ width: '45px' }}
                        value={numberOfPositions}
                        error={isEmpty(numberOfPositions)}
                        onTextChange={onNumberOfPositionsChange}
                    />
                    {   isEmpty(numberOfPositions) &&
                        <Text error={true} style={ {fontSize: 12}}>{t('core.input_prompt.text_field')}</Text>
                    }
                </Field>
                <Field style={{ marginLeft: '16px' }}>
                    <Label>{t('job.application_deadline')}</Label>
                    <DatePicker
                        selected={applicationDeadline}
                        onChange={onApplicationDeadlineChange}
                        customInput={<TextInput style={{ width: '165px' }} />}
                    />
                </Field>
                <Field style={{ marginLeft: '16px' }}>
                    <Label>{t('job.planned_date_of_employment')}</Label>
                    <Select
                        placeholder="core.select"
                        style={{ width: '165px`' }}
                        options={employmentDateOptions}
                        value={plannedEmploymentDate}
                        onValueChange={onPlannedEmploymentDateChange}
                    />
                </Field>
            </Row>

            <Row>
                <Field>
                    <Label required>{t('job.industry')}</Label>
                    <Select
                        placeholder="core.select"
                        style={{ width: '165px`' }}
                        options={industryOptions}
                        value={industry}
                        error={isEmpty(industry)}
                        onValueChange={onIndustryChange}
                    />
                    {   isEmpty(industry) &&
                        <Text error={true} style={ {fontSize: 12}}>{t('core.input_prompt.dropdown_menu')}</Text>
                    }   
                </Field>
                <Field style={{ marginLeft: '50px' }}>
                    <Label required>{t('job.job_type')}</Label>
                    <Select
                        placeholder="core.select"
                        style={{ width: '165px`' }}
                        disabled={!industry}
                        options={jobTypeOptions}
                        value={jobType}
                        error={isEmpty(jobType)}
                        onValueChange={onJobTypeChange}
                    />
                    {   isEmpty(jobType) &&
                        <Text error={true} style={ {fontSize: 12}}>{t('core.input_prompt.dropdown_menu')}</Text>
                    }
                </Field>
            </Row>

            <CategoryHeading>{t('job.contract')}</CategoryHeading>

            {(industry === 'agriculture_industry' || industry === 'fishery_industry') && (
                <Field>
                    <Label>{t('job.type_of_contract')}</Label>
                    <ButtonGroup style={{ marginTop: '8px' }}>
                        <Radio
                            checked={contractType === 'contract'}
                            value="contract"
                            onValueChange={onContractTypeChange}
                        >
                            {t('job.contract_contract')}
                        </Radio>
                        <Radio
                            checked={contractType === 'dispatch'}
                            value="dispatch"
                            onValueChange={onContractTypeChange}
                        >
                            {t('job.contract_dispatch')}
                        </Radio>
                    </ButtonGroup>
                </Field>
            )}

            <Field>
                <Label>{t('job.contract_period')}</Label>
                <Row center>
                    <ButtonGroup>
                        <Radio
                            value="fixed_term"
                            checked={contractPeriod === 'fixed_term'}
                            onValueChange={onContractPeriodChange}
                        >
                            {t('job.fixed_term')}
                        </Radio>
                        <Radio
                            value="indefinite"
                            checked={contractPeriod === 'indefinite'}
                            onValueChange={onContractPeriodChange}
                        >
                            {t('job.indefinite')}
                        </Radio>
                    </ButtonGroup>
                    <Text style={{ marginLeft: '60px' }}>
                        {t('job.period_length')}
                    </Text>
                    <TextInput
                        type="number"
                        placeholder="1"
                        style={{ width: '45px', marginLeft: '8px' }}
                        disabled={contractPeriod !== 'fixed_term'}
                        value={contractPeriodYears}
                        onTextChange={onContractPeriodYearsChange}
                    />
                    <Text style={{ marginLeft: '8px' }}>{t('job.years')}</Text>
                </Row>
            </Field>

            <Field>
                <Label>{t('job.contract_renewal')}</Label>
                <ButtonGroup style={{ marginTop: '8px' }}>
                    <Radio
                        value="automatic"
                        disabled={!contractPeriod}
                        checked={contractRenewal === 'automatic'}
                        onValueChange={onContractRenewalChange}
                    >
                        {t('job.automatic_renewal')}
                    </Radio>
                    <Radio
                        value="possible"
                        disabled={!contractPeriod}
                        checked={contractRenewal === 'possible'}
                        onValueChange={onContractRenewalChange}
                    >
                        {t('job.possible_renewal')}
                    </Radio>
                    <Radio
                        value="no"
                        disabled={!contractPeriod}
                        checked={contractRenewal === 'no'}
                        onValueChange={onContractRenewalChange}
                    >
                        {t('job.no_renewal')}
                    </Radio>
                </ButtonGroup>
            </Field>

            <Field>
                <Label>{t('job.acceptance_of_overseas_residents')}</Label>
                <Checkbox
                    checked={overseasResidentsAccepted}
                    onValueChange={onOverseasResidentsAccepted}
                >
                    {t('job.acceptance_of_overseas_residents_acceptable')}
                </Checkbox>
            </Field>

            <CategoryHeading>{t('job.work_location')}</CategoryHeading>
            <HelperText style={{ marginTop: '4px'}}>
                {t('job.work_location_description')}
            </HelperText>

            <PostalCodeInput
                value={postalCode}
                prefecture={prefecture}
                city={city}
                town={town}
                error={isEmpty(postalCode)}
                onTextChange={onPostalCodeChange}
                onPostalCodeAddressIdChange={onPostalCodeAddressIdChange}
                onPrefectureChange={onPrefectureChange}
                onCityChange={onCityChange}
                onTownChange={onTownChange}
            />

            <Row>
                <Field>
                    <Label>{t('core.locality')}</Label>
                    <TextInput
                        style={{ width: '170px' }}
                        value={address}
                        onTextChange={onAddressChange}
                    />
                </Field>
                <Field style={{ marginLeft: '30px' }}>
                    <Label>{t('core.building_etc')}</Label>
                    <TextInput
                        style={{ width: '170px' }}
                        value={building}
                        onTextChange={onBuildingChange}
                    />
                </Field>
            </Row>

            <Field style={{ width: '100%'}}>
                <Label>{t('job.job_description')}</Label>
                <HelperText>{t('job.job_description_description')}</HelperText>
                <MultiTextInput
                    placeholder={t('job.job_description_example')}
                    style={{ width: '100%', height: '105px' }}
                    value={description}
                    onTextChange={onDescriptionChange}
                />
            </Field>
        </Section>
    );
};

export default BasicInformationSection;