import React, { FunctionComponent, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { useComponentVisible } from '../hooks'
import { useTranslation } from 'react-i18next';
import { MOBILE_DEVICE } from '../constants/styles';
import { useReducerContext } from '../contexts/ReducerContext';
import ACTIONS from '../constants/actions';
import { signOut } from '../apis/auth';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

interface MenuProps {
    visible?: boolean;
}

const Menu = styled.div<MenuProps>`
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 5;
  background: white;
  box-shadow: 2px 2px 8px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  visibility: ${(props: MenuProps) => props.visible ? 'visible' : 'hidden'};
  
  @media ${MOBILE_DEVICE} {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    
    > * {
      border-bottom: 1px solid #D5D5D5;
  }
`;

const MenuItem = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left: 10px;
  text-decoration: none;
  
  width: 142px;
  height: 38px;
  font: normal normal normal 14px/24px Hiragino Kaku Gothic ProN;
  color: #2C2C2C;

  &:hover {
    border-radius: 0;
    background: #FF730D;
    color: white !important;
  }
  
  @media ${MOBILE_DEVICE} {
    width: 100%;
    height: 48px;
  } 
`;

interface UserMenuSelectorProps extends HTMLAttributes<HTMLDivElement> {
}

const UserMenuSelector: FunctionComponent<UserMenuSelectorProps> = ({
    ...props
}) => {
    const { state, dispatch } = useReducerContext();
    const { ref, visible, setVisible } = useComponentVisible(false)
    const isMobile = useMediaQuery({ query: MOBILE_DEVICE });
    const iconPath = isMobile? '/images/icon-settings.svg' : '/images/icon-profile.svg'
    const { t } = useTranslation();
    const onClickMenuItem = (event: React.MouseEvent) => {
      setVisible(false);
      event.stopPropagation();
    }
    const onClickSignOut = async (event: React.MouseEvent) => {
      dispatch({ type: ACTIONS.START_LOADING, payload: { message: t('core.logging_out')}});

      try {
        await signOut();
      } catch (e) {
      }
      dispatch({ type: ACTIONS.LOGOUT });
      dispatch({ type: ACTIONS.STOP_LOADING });
      dispatch({
          type: ACTIONS.SET_PROMPT,
          payload: {
              type: 'success',
              message: t('core.logout_success'),
          },
      });

      onClickMenuItem(event);
  };

    return (
        <Container onClick={() => setVisible(true)} {...props}>
            <img alt="Profile" src={iconPath} />
            <Menu ref={ref} visible={visible}>
                { state.isCandidate &&
                    <MenuItem to="/profile" onClick={onClickMenuItem}>
                        {t('core.profile')}
                    </MenuItem>
                }
                <MenuItem to="/personal_settings" onClick={onClickMenuItem}>
                    {t('core.personal_setting.header')}
                </MenuItem>
                <MenuItem to="#" onClick={onClickSignOut}>
                    {t('core.logout')}
                </MenuItem>
            </Menu>
        </Container>
    )
}

export default UserMenuSelector