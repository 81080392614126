import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonGroup from '../../../core/components/ButtonGroup';
import Field from '../../../core/components/Field';
import HelperText from '../../../core/components/HelperText';
import Label from '../../../core/components/Label';
import Message from '../../../core/components/Message';
import Radio from '../../../core/components/Radio';
import Row from '../../../core/components/Row';
import Section from '../../../core/components/Section';
import SectionHeading from '../../../core/components/SectionHeading';
import Text from '../../../core/components/Text';
import TextInput from '../../../core/components/TextInput';

type WorkHoursSectionProps = {
    annualizedWorkingHours?: boolean;
    hoursPerDay?: string;
    minutesPerDay?: string;
    shiftSystem?: boolean;
    annualWorkHours?: string;
    monthlyWorkDays?: string;
    averageOvertime?: string;
    annualDaysOff?: string;
    onHoursPerDayChange?: (hoursPerDay: string) => void;
    onMinutesPerDayChange?: (minutesPerDay: string) => void;
    onShiftSystemChange?: (shiftSystem: boolean) => void;
    onAnnualWorkHoursChange?: (annualWorkHours: string) => void;
    onMonthlyWorkDaysChange?: (monthlyWorkDays: string) => void;
    onAverageOvertimeChange?: (averageOvertime: string) => void;
    onAnnualDaysOff?: (annualDaysOff: string) => void;
};

const WorkHoursSection: FunctionComponent<WorkHoursSectionProps> = ({
    annualizedWorkingHours,
    hoursPerDay,
    minutesPerDay,
    shiftSystem,
    annualWorkHours,
    monthlyWorkDays,
    averageOvertime,
    annualDaysOff,
    onHoursPerDayChange,
    onMinutesPerDayChange,
    onShiftSystemChange,
    onAnnualWorkHoursChange,
    onMonthlyWorkDaysChange,
    onAverageOvertimeChange,
    onAnnualDaysOff,
}) => {
    const isEmpty = (value: any) => !(!!value);
    const { t } = useTranslation();

    return (
        <Section>
            <SectionHeading>{t('job.job_working_hours')}</SectionHeading>

            {annualizedWorkingHours && (
                <Message style={{ marginTop: '24px' }}>
                    {t('job.annual_variable_working_hour_description')}
                </Message>
            )}

            <Field>
                <Label required>{t('job.basic_work_hours')}</Label>
                <Row center>
                    <TextInput
                        style={{ width: '45px' }}
                        type="number"
                        placeholder="0"
                        value={hoursPerDay}
                        error={isEmpty(hoursPerDay)}
                        max={23}
                        min={0}
                        onTextChange={onHoursPerDayChange}
                    />
                    <Text style={{ marginLeft: '4px' }}>{t('job.hours')}</Text>
                    <TextInput
                        style={{ width: '45px', marginLeft: '4px' }}
                        type="number"
                        placeholder="0"
                        value={minutesPerDay}
                        error={isEmpty(minutesPerDay)}
                        max={59}
                        min={0}
                        onTextChange={onMinutesPerDayChange}
                    />
                    <Text style={{ marginLeft: '4px' }}>{t('job.minutes')}</Text>
                </Row>
                {   isEmpty(hoursPerDay && minutesPerDay) &&
                    <Text error={true} style={{ fontSize: 12 }}>{t('core.input_prompt.text_field')}</Text>
                }
            </Field>

            <Row>
                <Field>
                    <Label>{t('job.work_style')}</Label>
                    <ButtonGroup style={{ marginTop: '8px' }}>
                        <Radio
                            checked={shiftSystem === false}
                            onValueChange={() => onShiftSystemChange && onShiftSystemChange(false)}
                        >
                            {t('job.fixed_hours')}
                        </Radio>
                        <Radio
                            checked={shiftSystem === true}
                            onValueChange={() => onShiftSystemChange && onShiftSystemChange(true)}
                        >
                            {t('job.shift_system')}
                        </Radio>
                    </ButtonGroup>
                </Field>
            </Row>

            <Row style={{ display: 'none' }}>
                <Field>
                    <Label>{t('job.start_end_time_of_work')}</Label>
                    <ButtonGroup style={{ marginTop: '8px' }}>
                        <Radio>{t('job.fixed')}</Radio>
                        <Radio>{t('job.variable')}</Radio>
                    </ButtonGroup>
                </Field>
            </Row>

            <Row center style={{ display: 'none' }}>
                <TextInput type="number" placeholder="00" style={{ width: '45px' }} />
                <Text style={{ marginLeft: '4px' }}>:</Text>
                <TextInput type="number" placeholder="00" style={{ width: '45px', marginLeft: '4px' }} />
                <Text style={{ marginLeft: '4px' }}>〜</Text>
                <TextInput type="number" placeholder="00" style={{ width: '45px', marginLeft: '4px' }} />
                <Text style={{ marginLeft: '4px' }}>:</Text>
                <TextInput type="number" placeholder="00" style={{ width: '45px', marginLeft: '4px' }} />
                <Text style={{ marginLeft: '30px' }}>{t('job.break')}</Text>
                <TextInput type="number" placeholder="0" style={{ width: '45px', marginLeft: '4px' }} />
                <Text style={{ marginLeft: '4px' }}>{t('job.minutes')}</Text>
            </Row>

            {annualizedWorkingHours && (
                <Field>
                    <Label>{t('job.annual_work_hours')}</Label>
                    <Row center>
                        <TextInput
                            type="number"
                            placeholder="0"
                            style={{ width: '45px' }}
                            value={annualWorkHours}
                            onTextChange={onAnnualWorkHoursChange}
                        />
                        <Text style={{ marginLeft: '4px' }}>{t('job.hours')}</Text>
                    </Row>
                </Field>
            )}

            <Field>
                <Label>{t('job.monthly_work_days')}</Label>
                <Row center>
                    <TextInput
                        type="number"
                        placeholder="0"
                        style={{ width: '45px' }}
                        value={monthlyWorkDays}
                        min={0}
                        onTextChange={onMonthlyWorkDaysChange}
                    />
                    <Text style={{ marginLeft: '4px' }}>{t('job.days')}</Text>
                </Row>
                <HelperText>{t('job.decimals_allowed')}</HelperText>
            </Field>

            <Field>
                <Label>{t('job.monthly_overtime')}</Label>
                <Row center>
                    <TextInput
                        type="number"
                        placeholder="0"
                        style={{ width: '45px' }}
                        value={averageOvertime}
                        min={0}
                        onTextChange={onAverageOvertimeChange}
                    />
                    <Text style={{ marginLeft: '4px' }}>{t('job.hours')}</Text>
                </Row>
            </Field>

            <Field>
                <Label>{t('job.annual_days_off')}</Label>
                <Row center>
                    <TextInput
                        type="number"
                        placeholder="0"
                        style={{ width: '45px' }}
                        value={annualDaysOff}
                        min={0}
                        onTextChange={onAnnualDaysOff}
                    />
                    <Text style={{ marginLeft: '4px' }}>{t('job.days')}</Text>
                </Row>
                <HelperText>{t('job.decimals_allowed')}</HelperText>
            </Field>
        </Section>
    );
};

export default WorkHoursSection;