import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Text from '../../core/components/Text';

interface ContactInformationProps {
    email?: string;
    lineId?: string;
    facebookProfileLink?: string;
}

const Container = styled.div`
  display: grid;
  grid-template-columns: min-content auto;
  grid-column-gap: 10px;
  grid-row-gap: 5px;
`;

const Label = styled(Text)`
  color: #999999;
`;

const ContactInformation: FunctionComponent<ContactInformationProps> = ({
    email,
    lineId,
    facebookProfileLink,
}) => {
    const { t } = useTranslation();

    return (
        <Container>
            <Label>
                {t('candidate.email')}
            </Label>
            <Text style={{ color: email ? 'inherit' : '#999999' }}>
                {email || t('core.not_entered')}
            </Text>
            <Label>
                {t('candidate.line_id')}
            </Label>
            <Text style={{ color: lineId ? 'inherit' : '#999999' }}>
                {lineId || t('core.not_entered')}
            </Text>
            <Label>
                {t('candidate.facebook_link')}
            </Label>
            <Text style={{ color: lineId ? 'inherit' : '#999999' }}>
                {facebookProfileLink || t('core.not_entered')}
            </Text>
        </Container>
    );
};

export default ContactInformation;