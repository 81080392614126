import en from './en.json';
import ja from './ja.json';
import vi from './vi.json';

export default {
    en: {
        translation: en,
    },
    ja: {
        translation: ja,
    },
    vi: {
        translation: vi,
    },
};