import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import CategoryHeading from '../../core/components/CategoryHeading';
import Text from '../../core/components/Text';
import { MOBILE_DEVICE } from '../../core/constants/styles';
import { useMediaQuery } from 'react-responsive';
import Column from '../../core/components/Column';

interface ContainerProps {
    active: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 560px;
  height: 262px;
  padding: 14px 18px 20px 20px;
  background: #FFFFFF;
  border: 1px solid ${(props: ContainerProps) => props.active ? '#FF730D' : '#E5E5E5'};
  border-radius: 5px;
  cursor: pointer;
  box-sizing: border-box;
  
  &:hover {
    border-color: #FFC194;
  }
  
  @media ${MOBILE_DEVICE} {
    width: 100%;
    height: unset;
    padding: 15px;
  }
`

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
`

const TopLeftContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  > *:not(:first-child) {
    margin-top: 4px;
  }
  
  @media ${MOBILE_DEVICE} {
    > *:not(:first-child) {
      margin-top: 10px;
    }
  }
`

const BottomContainer = styled.div`
  flex: 1;
  width: 100%;
  margin-top: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  
  @media ${MOBILE_DEVICE} {
    margin-top: 10px;
  }
`

const Thumbnail = styled.img`
  width: 150px;
  height: 101px;
  margin-left: 14px;
  border-radius: 5px;
  object-fit: cover;
  
  @media ${MOBILE_DEVICE} {
    width: 100%;
    height: 147px;
    margin-left: unset;
  }
`

const Description = styled(Text)`
  @media ${MOBILE_DEVICE} {
    color: #999999;
  }
`;

const LocalCategoryHeading = styled(CategoryHeading)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: unset;
  max-height: 46px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface JobCardProps {
    active: boolean;
    thumbnail: string;
    title: string;
    location: string;
    industry: string;
    jobType: string;
    grossSalary?: number;
    grossSalaryUpperLimit?: number;
    description: string;
    onClick?: () => void;
}

const JobCard: FunctionComponent<JobCardProps> = ({
    active,
    thumbnail,
    title,
    location,
    industry,
    jobType,
    grossSalary,
    grossSalaryUpperLimit,
    description,
    onClick,
}) => {
    const { t } = useTranslation();
    const isMobileDevice = useMediaQuery({
        query: MOBILE_DEVICE,
    });

    return (
        <Container active={active} onClick={onClick}>
            <TopContainer>
                <TopLeftContainer>
                    <LocalCategoryHeading>
                        {title}
                    </LocalCategoryHeading>
                    {isMobileDevice && (
                        <Thumbnail src={thumbnail} />
                    )}
                    {isMobileDevice
                        ? (
                            <Column>
                                <Text style={{ color: '#999999' }}>{location}</Text>
                                <Text>{industry} - {jobType}</Text>
                            </Column>
                        )
                        : (
                            <>
                                <Text style={{ color: '#999999' }}>{location}</Text>
                                <Text>{industry} - {jobType}</Text>
                            </>
                        )
                    }
                    <Text style={{ fontWeight: isMobileDevice ? 700 : 500 }}>
                        {
                          grossSalaryUpperLimit
                              ? t('job.dollars', { number: (`${grossSalary?.toLocaleString()} ~ ${grossSalaryUpperLimit.toLocaleString()}`)})
                              : t('job.dollars', { number: grossSalary?.toLocaleString() ?? '-' })
                        }
                    </Text>
                </TopLeftContainer>
                {!isMobileDevice && (
                    <Thumbnail src={thumbnail} />
                )}
            </TopContainer>
            <BottomContainer>
                <Description>{description}</Description>
            </BottomContainer>
        </Container>
    )
}

export default JobCard
