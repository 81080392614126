import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Button from '../../components/Button';
import Section from '../../components/Section';
import SectionHeading from '../../components/SectionHeading';
import ACTIONS from '../../constants/actions';
import { useReducerContext } from '../../contexts/ReducerContext';
import Label from '../../components/Label';
import Text from '../../components/Text';
import { SUPPORTED_LANGUAGE_OPTIONS } from '../../constants/options';
import Select from '../../components/Select';
import { updateUserSettings } from '../../apis/user';
import { MOBILE_DEVICE } from '../../constants/styles';
import { getLanguageIdFromCode } from '../../utils';
import MultiSelect from '../../components/MultiSelect';
import Checkbox from '../../components/Checkbox';
import { useJobPostOptions } from '../../hooks';

interface PersonalSettingsProps {}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 800px;
  width: 100%;
`;

const LocalCheckbox = styled(Checkbox)`
  margin-bottom: 15px;
  padding-left: 10px;
`;

const LocalSection = styled(Section)`
  width: 100%;
  padding: 20px;
  margin-top: 10px;
`;

const LocalSectionHeading = styled(SectionHeading)`
  @media ${MOBILE_DEVICE} {
    padding: 0 20px;
  }
`;

const Separator = styled.hr`
  color: #D5D5D5;
  width: 100%;
  margin: 5px 0 15px 0;
`;

const SubSectionHeader = styled.h3`
  color: #444444;
  font-size: 16px;
  margin: 0;
`;

const LanguageSection = styled.section`
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  gap: 5px;
  width: 100%;
`;

const EmailSection = styled.section`
  width: 100%;
`;

const SaveButton = styled(Button)`
  width: 110px;
`;

const DiscardButton = styled(Button)`
  width: 38px;
`;

const IndustrySelectMultipleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
  padding-left: 20px;
  margin-bottom: 20px;
`;

const Action = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
`;


const PersonalSettings: FunctionComponent<PersonalSettingsProps> = () => {
    const { state, dispatch } = useReducerContext();
    const [currentLanguage, setCurrentLanguage] = useState<string>(state.userSetting.language?.code ?? '');
    const [currentIndustries, setCurrentIndustries] = useState<string[]>(state.userSetting.notifiedIndustries.map(i => i.id.toString())); 
    const [newLanguage, setNewLanguage] = useState<string>(currentLanguage);
    const [newIndustries, setNewIndustries] = useState<string[]>(currentIndustries); 
    const [currentNotifyNewJobs, setCurrentNotifyNewJobs] = useState<boolean>(state.userSetting.notifyNewJobs); 
    const [newNotifyNewJobs, setNewNotifyNewJobs] = useState<boolean>(currentNotifyNewJobs); 
    const [currentNotifyScout, setCurrentNotifyScout] = useState<boolean>(state.userSetting.notifyScout);
    const [newNotifyScout, setNewNotifyScout] = useState(currentNotifyScout);
    const { t ,i18n } = useTranslation();
    const { industryOptions } = useJobPostOptions();

    const canUpdateSetting = (): boolean =>  {
      const languageUpdated = newLanguage !== currentLanguage;
      const industriesUpdated = JSON.stringify(currentIndustries) !== JSON.stringify(newIndustries);
      const notifyNewJobsUpdated = currentNotifyNewJobs !== newNotifyNewJobs;
      const notifyScoutUpdated = currentNotifyScout !== newNotifyScout;
      return languageUpdated || industriesUpdated || notifyNewJobsUpdated || notifyScoutUpdated;
    };
    const onClickDiscard = () =>  {
      setNewLanguage(currentLanguage);
      setNewIndustries(currentIndustries);
      setNewNotifyNewJobs(currentNotifyNewJobs);
      setNewNotifyScout(currentNotifyScout);
    };
    const onClickSave = async () => {
        dispatch({ type: ACTIONS.START_LOADING });
        try {            
          const userSetting = await updateUserSettings(state.userSetting.id, { 
            languageId: getLanguageIdFromCode(newLanguage),
            notifyNewJobs: newNotifyNewJobs,
            notifiedIndustryIds: newIndustries,
            notifyScout: newNotifyScout
          }); 
          i18n.changeLanguage(newLanguage);
          localStorage.setItem('lng', newLanguage);

          setCurrentLanguage(newLanguage);
          setCurrentIndustries(newIndustries); 
          setCurrentNotifyNewJobs(newNotifyNewJobs); 
          setCurrentNotifyScout(newNotifyScout);

          dispatch({ 
            type: ACTIONS.UPDATE_USER_SETTING, 
            payload: userSetting
          });

          dispatch({ 
            type: ACTIONS.SET_PROMPT, 
            payload: { 
              type: 'success', 
              message: t('core.personal_setting.update_success') 
            }
          });

        } catch (e) {
          dispatch({ 
            type: ACTIONS.SET_PROMPT, 
            payload: { 
              type: 'warning', 
              message: t('core.personal_setting.update_failed') 
            }
          });

        } finally {
          dispatch({ type: ACTIONS.STOP_LOADING });
        }
    };

    return (
        <Container>
            <LocalSectionHeading>{t('core.personal_setting.header')}</LocalSectionHeading>
            <LocalSection>
                {/* Language setting  */}
                <LanguageSection>
                  <Label><Text>{t('core.personal_setting.language')}</Text></Label>
                  <Select
                    placeholder="core.select"
                    options={SUPPORTED_LANGUAGE_OPTIONS}
                    value={newLanguage || currentLanguage}
                    onValueChange={setNewLanguage}
                  />                    
                </LanguageSection>

                {/* Notification settings */}
                <EmailSection>
                  <SubSectionHeader>{t('core.personal_setting.email_notifications')}</SubSectionHeader>
                  <Separator/>
                  
                  <LocalCheckbox
                    checked={newNotifyNewJobs}
                    onValueChange={setNewNotifyNewJobs}
                  >
                    { t('core.personal_setting.notify_me_about_jobs')}
                  </LocalCheckbox>

                  <IndustrySelectMultipleContainer>
                    <Label><Text>{t('core.personal_setting.industries_to_notify')}</Text></Label>
                    <MultiSelect
                      style={{ minWidth: 200 }}
                      placeholder="core.select"
                      options={industryOptions}
                      value={newIndustries}
                      onValueChange={setNewIndustries}
                    />
                  </IndustrySelectMultipleContainer>

                  <LocalCheckbox
                    checked={newNotifyScout}
                    onValueChange={setNewNotifyScout}
                  >
                    {t('core.personal_setting.notificatios_for_scouts')}
                  </LocalCheckbox>
               </EmailSection>
                
                <Action>
                    <SaveButton variant='primary-small' 
                      onClick={onClickSave} 
                      disabled={!canUpdateSetting()}>
                        {t('core.personal_setting.save_changes')}
                    </SaveButton>
                    { 
                      canUpdateSetting() &&
                      <DiscardButton variant='quarternary-subtle' 
                        onClick={onClickDiscard}>
                          {t('core.personal_setting.discard_changes')}
                      </DiscardButton>
                    }
                </Action>
            </LocalSection>
        </Container>
    );
};

export default PersonalSettings;