import { FunctionComponent, useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import ActionBar from '../../core/components/ActionBar';
import ActionButton from '../../core/components/ActionButton';
import Button from '../../core/components/Button';
import Gallery from '../../core/components/Gallery';
import Label from '../../core/components/Label';
import Page from '../../core/components/Page';
import PageHeading from '../../core/components/PageHeading';
import Row from '../../core/components/Row';
import Section from '../../core/components/Section';
import SectionHeading from '../../core/components/SectionHeading';
import Text from '../../core/components/Text';
import ACTIONS from '../../core/constants/actions';
import { useReducerContext } from '../../core/contexts/ReducerContext';
import { deleteJob, getJob } from '../apis/job';
import { Job, JobTranslation } from '../types/api';
import { Month } from '../../core/types/api';
import { JobQandASection} from '../components/JobQASection';
import { decodeHTMLEntities, getJobLocation } from '../../core/utils';
import Menu from '../../core/components/Menu';
import MenuItem from '../../core/components/MenuItem';
import { isProfileComplete } from '../utils';
import ProfileMissingModal from '../components/ProfileMissingModal';
import Column from '../../core/components/Column';
import { MOBILE_DEVICE } from '../../core/constants/styles';
import { useJobPostCategory, useNationalities } from '../../core/hooks';

interface JobDetailsPageProps {
}

const LocalSectionHeading = styled(SectionHeading)`
  margin-top: 50px;
`;

const LocalRow = styled(Row)`
  margin-top: 10px !important;
  
  > *:first-child {
    min-width: 234px;
    max-width: 234px;
    margin-right: 16px;
  }
  
  @media (max-width: 768px) {
    flex-direction: column;

    > *:first-child {
      width: unset;
      margin-right: unset;
      color: #999999;
    }
    
    > *:not(:first-child) {
      margin-top: 4px;
    }
  }
`;

const AnonymousActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 20px;
  margin-top: 50px;
  border: 1px solid #D5D5D5;
  border-radius: 5px;
  
  @media ${MOBILE_DEVICE} {
    flex-direction: column;
    margin-top: 30px;
    
    > * {
      width: 100%;
    }
  }
`;

const JobDetailsPage: FunctionComponent<JobDetailsPageProps> = () => {
    const context = useReducerContext();
    const { jobId } = useParams();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const jobPostCategory = useJobPostCategory();
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const [companyId] = useState(0);
    const { nationalityList } = useNationalities();

    const [jobOfferTitle, setJobOfferTitle] = useState('');
    const [location, setLocation] = useState('');
    const [jobTypeId, setJobTypeId] = useState(0);
    const [grossSalary, setGrossSalary] = useState<number|undefined>();
    const [grossSalaryUpperLimit, setGrossSalaryUpperLimit] = useState<number|undefined>();
    const [images, setImages] = useState<string[]>([]);

    const [numberOfPositions, setNumberOfPositions] = useState(0);
    const [applicationDeadline, setApplicationDeadline] = useState('');
    const [plannedEmploymentDate, setPlannedEmploymentDate] = useState('');

    const [contractPeriod, setContractPeriod] = useState('');
    const [contractPeriodYears, setContractPeriodYears] = useState<number|undefined>();
    const [contractRenewal, setContractRenewal] = useState('');
    const [acceptOverseasResidents, setAcceptOverseasResidents] = useState(false);
    const [description, setDescription] = useState('');

    const [salaryType, setSalaryType] = useState('');
    const [annualizedWorkingHours, setAnnualizedWorkingHours] = useState<boolean|undefined>();
    const [hourlyDailySalary, setHourlyDailySalary] = useState<number|undefined>();
    const [hourlyDailySalaryUpperLimit, setHourlyDailySalaryUpperLimit] = useState<number|undefined>();

    const [estimatedTotalDeductions, setEstimatedTotalDeductions] = useState<number|undefined>();
    const [expectedSocial, setExpectedSocial] = useState<number|undefined>();
    const [dormFee, setDormFee] = useState<number|undefined>();
    const [expectedTax, setExpectedTax] = useState<number|undefined>();
    const [salaryAfterTax, setSalaryAfterTax] = useState<number|undefined>();
    const [nightWorkAllowance, setNightWorkAllowance] = useState(0);
    const [salaryRaise, setSalaryRaise] = useState<boolean|undefined>();
    const [bonus, setBonus] = useState<boolean|undefined>();
    const [bonusPayments, setBonusPayments] = useState<number|undefined>();
    const [bonusMonths, setBonusMonths] = useState<Month[]>([]);
    const [hoursPerDayMin, setHoursPerDayMin] = useState(0);
    const [minutesPerDay, setMinutesPerDay] = useState(0);
    const [shiftBased, setShiftBased] = useState(false);
    const [annualHours] = useState(0);
    const [monthlyDays, setMonthlyDays] = useState(0);
    const [averageOvertime, setAverageOvertime] = useState(0);
    const [holidaysOff, setHolidaysOff] = useState(0);
    const [dormitoryAvailable, setDormitoryAvailable] = useState<boolean|undefined>();
    const [dormitoryType, setDormitoryType] = useState('');
    const [bedroomCount, setBedroomCount] = useState(0);
    const [commuteMethod, setCommuteMethod] = useState('');
    const [commuteTime, setCommuteTime] = useState<number|undefined>();
    const [furnitureProvided, setFurnitureProvided] = useState<boolean|undefined>();
    const [movingTransExpenses, setMovingTransExpenses] = useState<boolean|undefined>();
    const [movingTransExpensesAllowance, setMovingTransExpensesAllowance] = useState<number|undefined>();
    const [movingBagsExpenses, setMovingBagsExpenses] = useState<boolean|undefined>();
    const [movingBagsExpensesAllowance] = useState<number|undefined>();
    const [naturalJapaneseLevel, setNaturalJapaneseLevel] = useState('');
    const [japaneseLevel, setJapaneseLevel] = useState('');
    const [preferredGender, setPreferredGender] = useState('');
    const [preferredCountries, setPreferredCountries] = useState<number[]>([]);
    const [preferredAgeMin, setPreferredAgeMin] = useState(0);
    const [preferredAgeMax, setPreferredAgeMax] = useState(0);
    const [required, setRequired] = useState('');
    const [preferred, setPreferred] = useState('');
    const [currentNationalities, setCurrentNationalities] = useState<number[]>([]);
    const [specialAllowance, setSpecialAllowance] = useState(0);
    const [interviewMethod, setInterviewMethod] = useState('');
    const [coverInterviewExpense, setCoverInterviewExpense] = useState<boolean|undefined>();
    const [interviewAvailability, setInterviewAvailability] = useState('');
    const [additionalInfo, setAdditionalInfo] = useState('');
    const [translations, setTranslations] = useState<JobTranslation[]>([]);
    const [jobState, setJobState] = useState<Job['state']>();
    const [profileMissingModal, setProfileMissingModal] = useState(false);

    const handleDelete = async () => {
        context.dispatch({
            type: ACTIONS.START_CONFIRMATION,
            payload: {
                /* Note:
                   As of June 8, 2023, Current implementation is not correct.
                   It actually makes a job post archived but the text says "Delete"
                   so, we temporarily changed the text until the implementation is fixed later
                */
                message: `「${jobOfferTitle}」の求人をアーカイブしますか？`,
                callback: async () => {
                    context.dispatch({
                        type: ACTIONS.START_LOADING,
                        payload: {
                            message: t('job.deleting_job'),
                        },
                    })
                    try {
                        await deleteJob(jobId!);
                    } catch (e) {
                    }
                    navigate('/jobs');
                    context.dispatch({
                        type: ACTIONS.STOP_LOADING,
                    })
                },
            }
        })
    };

    const handlePropose = () => {
        if (context.state.isCandidate && !isProfileComplete(context.state.candidate!)) {
            setProfileMissingModal(true);
        } else {
            navigate(`/jobs/${jobId}/propose`);
        }
    };

    useEffect(() => {
        (async () => {
            if (jobId) {
                context.dispatch({
                    type: ACTIONS.START_LOADING,
                    payload: {
                        message: t('job.fetching_job_details'),
                    },
                })
                try {
                    const job = await getJob(jobId);
                    setJobOfferTitle(job.name);
                    setLocation(getJobLocation(job, i18n.language));
                    setJobTypeId(job.jobTypeId);
                    setHourlyDailySalary(job.hourlyDailySalary);
                    setHourlyDailySalaryUpperLimit(job.hourlyDailySalaryUpperLimit);
                    setGrossSalary(job.grossSalary);
                    setGrossSalaryUpperLimit(job.grossSalaryUpperLimit);
                    setImages(job.images.map((image) => image.url));
                    setNumberOfPositions(job.numberOfPositions);
                    setApplicationDeadline(job.applicationDeadline ?? '');
                    setPlannedEmploymentDate(job.plannedDateOfEmployment ?? '');
                    setContractPeriod(job.contractPeriod ?? '');
                    setContractPeriodYears(job.contractPeriodYears);
                    setContractRenewal(job.contractRenewal ?? '');
                    setAcceptOverseasResidents(job.acceptOverseasResidents);
                    setDescription(job.jobDescription);
                    setSalaryType(job.salaryType ?? '');
                    setAnnualizedWorkingHours(job.annualizedVariableWorkingHourSystem ?? undefined);
                    setHourlyDailySalary(job.hourlyDailySalary);
                    setEstimatedTotalDeductions(job.estimatedTotalDeductions);
                    setExpectedSocial(job.estimatedSocialInsurance);
                    setDormFee(job.dormitoryFee);
                    setExpectedTax(job.estimatedIncomeTax);
                    setSalaryAfterTax(job.takeHomeSalary);
                    setNightWorkAllowance(job.averageTotalNightShiftPremiumWagesPerMonth);
                    setSalaryRaise(job.salaryRaise);
                    setBonus(job.bonus);
                    setBonusPayments(job.numberOfBonusPaymentsPerYear);
                    setBonusMonths(job.bonusMonths);
                    setHoursPerDayMin(job.basicWorkHoursPerDay);
                    setMinutesPerDay(job.basicWorkMinutesPerDay);
                    setShiftBased(job.workStyle === 'shift_system');
                    //setAnnualHours(job.annualhours);
                    setMonthlyDays(job.averageMonthlyWorkDays);
                    setAverageOvertime(job.averageMonthlyOvertimeHours);
                    setHolidaysOff(job.averageAnnualDaysOff);
                    setDormitoryAvailable(job.dormitory);
                    setDormitoryType(job.dormitoryType ?? '');
                    setBedroomCount(job.bedroomPersons);
                    setCommuteMethod(job.commuteMethod ?? '');
                    setCommuteTime(job.commuteMinutes);
                    setFurnitureProvided(job.furnitureAppliances ?? undefined);
                    setMovingTransExpenses(job.transportationMovingExpensesCovered ?? undefined);
                    setMovingTransExpensesAllowance(job.baggageDeliveryMovingExpensesMaximumSupportAllowance ?? undefined);
                    setMovingBagsExpenses(job.baggageDeliveryMovingExpensesCovered ?? undefined);
                    setMovingTransExpensesAllowance(job.baggageDeliveryMovingExpensesMaximumSupportAllowance ?? undefined);
                    setNaturalJapaneseLevel(job.japaneseConversationSkills ?? '');
                    setJapaneseLevel(job.jlptLevel ?? '');
                    setPreferredGender(job.preferredGender ?? '');
                    setPreferredCountries(job.preferredNationalities.map((nationality) => nationality.id));
                    setPreferredAgeMin(job.preferredAgeFrom);
                    setPreferredAgeMax(job.preferredAgeTo);
                    setRequired(job.requiredConditions);
                    setPreferred(job.preferredConditions);
                    setCurrentNationalities(job.currentNationalities.map((nationality) => nationality.id));
                    setSpecialAllowance(job.specialAllowanceUponHire);
                    setInterviewMethod(job.interviewMethod ?? '');
                    setCoverInterviewExpense(job.provideFullTransportationExpenses);
                    setInterviewAvailability(job.availableDaysAndTimesForInterviews);
                    setAdditionalInfo(job.otherSupplementaryInformationAboutThisJob);
                    setTranslations(job.translations);
                    setJobState(job.state);
                } catch (e) {
                    // TODO: error handling
                }
                context.dispatch({
                    type: ACTIONS.STOP_LOADING,
                })
            }
        })();
    }, [jobId]);

    return (
        <Page>
            {!isMobile && (
                <Row center style={{ width: '100%', justifyContent: 'space-between'}}>
                    <PageHeading>{t('job.job_details')}</PageHeading>
                    {context.state.isAdmin && (
                        <Button
                            variant="secondary"
                            onClick={() => navigate(`/admin/jobs/${jobId}/edit`)}
                        >
                            {t('core.edit')}
                        </Button>
                    )}
                    {context.state.isEmployer && context.state.companyId === companyId && (
                        <Row center>
                            <ActionButton
                                onClick={() => navigate(`/jobs/${jobId}/edit`)}
                            >
                                {t('core.edit')}
                            </ActionButton>
                            <Menu
                                style={{ marginLeft: 10 }}
                                title={t('core.other_actions')}
                            >
                                <MenuItem onClick={() => navigate(`/jobs/new?from=${jobId}`)}>
                                    {t('job.copy_and_create_new')}
                                </MenuItem>

                                {/* Text says "archive" but the handler says "Delete" 
                                    This is intentional. Because curfrent implementation actually doesn't delete but archive a job post,
                                    we temporairly change the text until the implementation is fixed later */}
                                <MenuItem onClick={handleDelete}>
                                    {t('core.archive')}
                                </MenuItem>
                            </Menu>
                        </Row>
                    )}
                </Row>
            )}
            <Section>
                <PageHeading>
                    {
                        jobState === 'published'
                        ? decodeHTMLEntities(translations.find(t => t.language.code === i18n.language)?.name) ?? jobOfferTitle
                        : jobOfferTitle
                    }
                </PageHeading>
                <Text style={{ marginTop: '10px' }}>{location}</Text>
                <Text style={{ marginTop: '5px' }}>
                    { jobPostCategory.getIndustryNameByJobTypeId(jobTypeId) }
                    {' - '}
                    { jobPostCategory.getJobTypeName(jobTypeId) }
                </Text>
                <Label style={{ marginTop: '7px'}}>
                    {t('job.monthly_salary')}
                    {' '}
                    {
                        grossSalaryUpperLimit
                        ? t('job.dollars', { number: `${grossSalary?.toLocaleString()} ~ ${grossSalaryUpperLimit.toLocaleString()}` })
                        : t('job.dollars', { number: grossSalary?.toLocaleString() ?? '-' })
                    }
                </Label>

                <Gallery style={{ width: '100%', marginTop: '27px' }} images={images} />

                {!context.state.isLoggedIn && (
                    <AnonymousActions>
                        <Column style={{ flex:1, gap: 5 }} center>
                            <Text style={{ color: '#999999' }}>
                                {t('job.job_seekers')}
                            </Text>
                            <Button
                                style={{ width: '100%' }}
                                onClick={() => navigate('/register')}
                            >
                                {t('job.register_and_apply')}
                            </Button>
                        </Column>
                        <Column style={{ flex: 1, gap: 5 }} center>
                            <Text style={{ color: '#999999' }}>
                                {t('job.recruitment_agencies')}
                            </Text>
                            <Button
                                style={{ width: '100%' }}
                                variant="tertiary"
                                onClick={() => navigate('/contact')}
                            >
                                {t('job.contact')}
                            </Button>
                        </Column>
                    </AnonymousActions>
                )}

                <LocalSectionHeading>{t('job.general')}</LocalSectionHeading>
                <LocalRow>
                    <Text>{t('job.number_of_positions')}</Text>
                    <Text>{numberOfPositions}</Text>
                </LocalRow>
                <LocalRow>
                    <Text>{t('job.application_deadline')}</Text>
                    <Text>
                        {applicationDeadline
                            ? moment(applicationDeadline).format('MMMM Do, YYYY')
                            : '-'
                        }
                    </Text>
                </LocalRow>
                <LocalRow>
                    <Text>{t('job.planned_date_of_employment')}</Text>
                    <Text>
                        {plannedEmploymentDate
                            ? moment(plannedEmploymentDate).format('MMMM YYYY')
                            : '-'
                        }
                    </Text>
                </LocalRow>

                <LocalSectionHeading>{t('job.contract')}</LocalSectionHeading>
                <LocalRow>
                    <Text>{t('job.contract_period')}</Text>
                    <Text>{t(`job.${contractPeriod}`)}</Text>
                </LocalRow>
                {contractPeriod === 'fixed_term' && (
                    <LocalRow>
                        <Text>{t('job.period_length')}</Text>
                        <Text>{contractPeriodYears ?? '-'} {contractPeriodYears ? t('job.years', { count: contractPeriodYears }) : ''}</Text>
                    </LocalRow>
                )}
                <LocalRow>
                    <Text>{t('job.contract_renewal')}</Text>
                    <Text>
                        {contractRenewal
                            ? t(`job.${contractRenewal}_renewal`)
                            : '-'
                        }
                    </Text>
                </LocalRow>
                <LocalRow>
                    <Text>{t('job.acceptance_of_overseas_residents')}</Text>
                    <Text>{acceptOverseasResidents ? t('job.acceptance_of_overseas_residents_acceptable') : '-'}</Text>
                </LocalRow>
                <LocalRow>
                    <Text>{t('job.job_description')}</Text>
                    <Text>
                        {   
                            jobState === 'published'
                            ? decodeHTMLEntities(translations.find(t => t.language.code === i18n.language)?.jobDescription) ?? (description || '-')
                            : (description || '-')
                        }
                    </Text>
                </LocalRow>

                <LocalSectionHeading>{t('job.salary')}</LocalSectionHeading>
                <LocalRow>
                    <Text>{t('job.salary_type')}</Text>
                    <Text>{t(`job.salary_${salaryType}`)}</Text>
                </LocalRow>
                <LocalRow>
                    <Text>{t('job.annual_variable_working_hour')}</Text>
                    <Text>{t(`core.${annualizedWorkingHours ? 'yes' : 'no'}`)}</Text>
                </LocalRow>
                {(salaryType === 'daily' || salaryType === 'hourly') && (
                    <LocalRow>
                        <Text>{t('job.hourly_daily_salary')}</Text>
                        <Text>
                            {hourlyDailySalary?.toLocaleString() ?? '-'}
                            {hourlyDailySalaryUpperLimit ? ` ~ ${hourlyDailySalaryUpperLimit.toLocaleString()}` : null}
                        </Text>
                    </LocalRow>
                )}
                <LocalRow>
                    <Text>{t('job.gross_salary')}</Text>
                    <Text>
                        {grossSalary?.toLocaleString() ?? '-'}
                        {grossSalaryUpperLimit ? ` ~ ${grossSalaryUpperLimit.toLocaleString()}` : null}
                    </Text>
                </LocalRow>

                <LocalSectionHeading>{t('job.deductions')}</LocalSectionHeading>
                <LocalRow>
                    <Text>{t('job.estimated_total_deductions')}</Text>
                    <Text>{estimatedTotalDeductions?.toLocaleString() ?? '-'}</Text>
                </LocalRow>
                <LocalRow>
                    <Text>{t('job.social_insurance')}</Text>
                    <Text>{expectedSocial?.toLocaleString() ?? '-'}</Text>
                </LocalRow>
                <LocalRow>
                    <Text>{t('job.dorm_fee')}</Text>
                    <Text>{dormFee?.toLocaleString() ?? '-'}</Text>
                </LocalRow>
                <LocalRow>
                    <Text>{t('job.expected_taxes')}</Text>
                    <Text>{expectedTax?.toLocaleString() ?? '-'}</Text>
                </LocalRow>
                <LocalRow>
                    <Text>{t('job.take_home_salary')}</Text>
                    <Text>{salaryAfterTax?.toLocaleString() ?? '-'}</Text>
                </LocalRow>
                <LocalRow>
                    <Text>{t('job.salary_raise')}</Text>
                    <Text>{t(`job.${salaryRaise ? '' : 'not_'}applicable`)}</Text>
                </LocalRow>
                <LocalRow>
                    <Text>{t('job.night_work_allowance')}</Text>
                    <Text>{nightWorkAllowance?.toLocaleString() ?? '-'}</Text>
                </LocalRow>
                <LocalRow>
                    <Text>{t('job.average_number_of_payments')}</Text>
                    <Text>-</Text>
                </LocalRow>
                <LocalRow>
                    <Text>{t('job.bonus')}</Text>
                    <Text>{t(`job.${!bonus ? 'not_' : ''}provided`)}</Text>
                </LocalRow>
                {!!bonus && (
                    <>
                        <LocalRow>
                            <Text>{t('job.bonus')} - {t('job.number_of_payments_per_year')}</Text>
                            <Text>{bonusPayments}</Text>
                        </LocalRow>
                        <LocalRow>
                            <Text>{t('job.bonus')} - {t('job.months_of_payment')}</Text>
                            <Text>
                                {bonusMonths
                                    .map((bonusMonth) => t(`core.months.${bonusMonth.tkey}`))
                                    .join(', ')
                                }
                            </Text>
                        </LocalRow>
                    </>
                )}

                <LocalSectionHeading>{t('job.job_working_hours')}</LocalSectionHeading>
                <LocalRow>
                    <Text>{t('job.basic_work_hours')}</Text>
                    <Text>
                        {hoursPerDayMin}
                        {` ${t('job.hours')} `}
                        {minutesPerDay}
                        {` ${t('job.minutes')}`}
                    </Text>
                </LocalRow>
                <LocalRow>
                    <Text>{t('job.work_style')}</Text>
                    <Text>{t(`job.${shiftBased ? 'shift_system' : 'fixed_hours'}`)}</Text>
                </LocalRow>
                <LocalRow>
                    <Text>{t('job.annual_work_hours')}</Text>
                    <Text>{annualHours ?? '-'}</Text>
                </LocalRow>
                <LocalRow>
                    <Text>{t('job.monthly_work_days')}</Text>
                    <Text>{monthlyDays ?? '-'}</Text>
                </LocalRow>
                <LocalRow>
                    <Text>{t('job.monthly_overtime')}</Text>
                    <Text>{averageOvertime ?? '-'}</Text>
                </LocalRow>
                <LocalRow>
                    <Text>{t('job.annual_days_off')}</Text>
                    <Text>{holidaysOff ?? '-'}</Text>
                </LocalRow>

                <LocalSectionHeading>{t('job.housing')}</LocalSectionHeading>
                <LocalRow>
                    <Text>{t('job.dormitory')}</Text>
                    <Text>{t(`core.option_${dormitoryAvailable ? '' : 'not_'}available`)}</Text>
                </LocalRow>
                <LocalRow>
                    <Text>{t('job.dormitory_type')}</Text>
                    <Text>{dormitoryType ? t(`job.${dormitoryType}`) : '-'}</Text>
                </LocalRow>
                <LocalRow>
                    <Text>{t('job.bedroom')} ({t('job.persons_room')})</Text>
                    <Text>{bedroomCount ? bedroomCount : '-'}</Text>
                </LocalRow>
                <LocalRow>
                    <Text>{t('job.commute_method_and_time')}</Text>
                    <Text>
                        {commuteMethod && commuteTime
                            ? `${t(`job.${commuteMethod}`)} ${commuteTime} ${t('job.minutes')}`
                            : '-'
                        }
                    </Text>
                </LocalRow>
                <LocalRow>
                    <Text>{t('job.furniture_appliances')}</Text>
                    <Text>
                        {furnitureProvided === undefined
                            ? '-'
                            : t(`job.${furnitureProvided ? '' : 'not_'}provided`)
                        }
                    </Text>
                </LocalRow>
                <LocalRow>
                    <Text>{t('job.moving_expenses_transportation')}</Text>
                    <Text>
                        {movingTransExpenses === undefined
                            ? '-'
                            : t(`job.${movingTransExpenses ? 'covered_by_employer' : 'paid_by_employee'}`)
                        }
                    </Text>
                </LocalRow>
                <LocalRow>
                    <Text>{t('job.support_allowance')}</Text>
                    <Text>
                        {movingTransExpensesAllowance?.toLocaleString() ?? '-'}
                    </Text>
                </LocalRow>
                <LocalRow>
                    <Text>{t('job.moving_expenses_baggage')}</Text>
                    <Text>
                        {movingBagsExpenses === undefined
                            ? '-'
                            : t(`job.${movingBagsExpenses ? 'covered_by_employer' : 'paid_by_employee'}`)
                        }
                    </Text>
                </LocalRow>
                <LocalRow>
                    <Text>{t('job.support_allowance')}</Text>
                    <Text>
                        {movingBagsExpensesAllowance?.toLocaleString() ?? '-'}
                    </Text>
                </LocalRow>

                {(context.state.isPartner || context.state.isEmployer) && (
                    <>
                        <LocalSectionHeading>{t('job.candidate_requirements')}</LocalSectionHeading>
                        <LocalRow>
                            <Text>{t('job.japanese_conversation_skills')}</Text>
                            <Text>
                                {naturalJapaneseLevel
                                    ? t(`candidate.${naturalJapaneseLevel}`)
                                    : '-'
                                }
                            </Text>
                        </LocalRow>
                        <LocalRow>
                            <Text>{t('job.jlpt_level')}</Text>
                            <Text>{japaneseLevel ?? '-'}</Text>
                        </LocalRow>
                        <LocalRow>
                            <Text>{t('job.preferred_gender')}</Text>
                            <Text>
                                {t(preferredGender
                                    ? `core.${preferredGender}`
                                    : 'job.preferred_none'
                                )}
                            </Text>
                        </LocalRow>
                        <LocalRow>
                            <Text>{t('job.preferred_nationality')}</Text>
                            <Text>
                                {preferredCountries
                                    .map((preferredNationality) => (
                                        nationalityList.find((nationality) => nationality.id === preferredNationality)?.name ?? ''
                                    ))
                                    .join(', ')
                                }
                            </Text>
                        </LocalRow>
                        <LocalRow>
                            <Text>{t('job.preferred_age_range')}</Text>
                            <Text>
                                {!!preferredAgeMin && preferredAgeMin} ~ {!!preferredAgeMax && preferredAgeMax}
                            </Text>
                        </LocalRow>
                        <LocalRow>
                            <Text>{t('job.required_conditions')}</Text>
                            <Text>
                                {
                                    jobState === 'published'
                                    ? decodeHTMLEntities(translations.find(t => t.language.code === i18n.language)?.requiredConditions) ?? (required || '-')
                                    : (required || '-')
                                }
                            </Text>
                        </LocalRow>
                        <LocalRow>
                            <Text>{t('job.preferred_conditions')}</Text>
                            <Text>
                                {
                                    jobState === 'published'
                                    ? decodeHTMLEntities(translations.find(t => t.language.code === i18n.language)?.preferredConditions) ?? (preferred || '-')
                                    : (preferred || '-')
                                }
                            </Text>
                        </LocalRow>
                    </>
                )}

                <LocalSectionHeading>{t('job.other')}</LocalSectionHeading>
                <LocalRow>
                    <Text>{t('job.nationalities_of_currently_working_foreign_employees')}</Text>
                    <Text>
                        {currentNationalities
                            .map((currentNationality) => (
                                nationalityList.find((nationality) => nationality.id === currentNationality)?.name ?? ''
                            ))
                            .join(', ')
                        }
                    </Text>
                </LocalRow>
                <LocalRow>
                    <Text>{t('job.special_allowance_upon_hire')}</Text>
                    <Text>{specialAllowance}</Text>
                </LocalRow>
                <LocalRow>
                    <Text>{t('job.interview_method')}</Text>
                    <Text>
                        {interviewMethod ? t(`job.${interviewMethod}_interview`) : '-'}
                    </Text>
                </LocalRow>
                {interviewMethod === 'offline' && (
                    <LocalRow>
                        <Text>{t('job.provide_full_transportation_expenses')}</Text>
                        <Text>
                            {coverInterviewExpense === undefined
                                ? '-'
                                : t(`core.${coverInterviewExpense ? 'yes' : 'no'}`)
                            }
                        </Text>
                    </LocalRow>
                )}
                <LocalRow>
                    <Text>{t('job.available_days_and_times_for_interview')}</Text>
                    <Text>
                        {
                            jobState === 'published'
                            ? decodeHTMLEntities(translations.find(t => t.language.code === i18n.language)?.availableDaysAndTimesForInterviews) ?? (interviewAvailability || '-')
                            : (interviewAvailability || '-')
                        }
                    </Text>
                </LocalRow>
                <LocalRow>
                    <Text>{t('job.other_supplementary_information_about_this_job')}</Text>
                    <Text>
                        {
                            jobState === 'published'
                            ? decodeHTMLEntities(translations.find(t => t.language.code === i18n.language)?.otherSupplementaryInformationAboutThisJob) ?? (additionalInfo || '-')
                            : (additionalInfo || '-')
                        }
                    </Text>
                </LocalRow>

                <LocalSectionHeading>{t('job.q_and_a')}</LocalSectionHeading>
                { jobId && <JobQandASection jobPostId={jobId}/> }

            </Section>
            {(context.state.isPartner || context.state.isCandidate) && !context.state.isAdmin &&
                <ActionBar>
                    { jobState === 'published' &&
                        <Button onClick={handlePropose}>
                            { context.state.isCandidate? t('proposal.apply') : t('proposal.propose')}
                        </Button>
                    }

                    { jobState === 'archived' &&
                        <Button disabled={true}>
                            {t('proposal.job_closed')}
                       </Button>
                    }
                </ActionBar>
            }

            <ProfileMissingModal open={profileMissingModal} />
        </Page>
    );
};

export default JobDetailsPage;