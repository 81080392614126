import { filesize } from 'filesize';
import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { DOCUMENT_KINDS } from '../../constants';
import { Document, Proposal } from '../../types';
import { Candidate } from '../../../candidate/types/api';
import Button from '../../../core/components/Button';
import Column from '../../../core/components/Column';
import UploadButton from '../../../core/components/UploadButton';
import Row from '../../../core/components/Row';
import Text from '../../../core/components/Text';
import SendDocumentsModal from '../../components/SendDocumentsModal';
import ContactInformation from '../../components/ContactInformation';

import { ReactComponent as HistoryIcon } from '../../../assets/icon-history.svg';
import { ReactComponent as OpenIcon } from '../../../assets/icon-open-in-new.svg';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

interface InformationSharingSectionProps {
    proposal?: Proposal;
    documents?: Document[];
    candidate?: Candidate;
    employmentOfferSentDate?: string;
    onDocumentUpload?: (kind: string, file: File) => void;
    onDocumentDelete?: (id: number) => void;
    onDocumentsSend?: () => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 730px;
  margin-top: 10px;
  padding: 20px 20px 60px 20px;
  border: 1px solid #E5E5E5;
  border-radius: 5px;
  background: #FFFFFF;
`;

const Status = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  color: #444444;
  background: #FFDFD1;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

const Blocks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 30px;
  
  > *:not(:first-child) {
    margin-top: 40px;
  }
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  
  > *:not(:first-child) {
    margin-top: 10px;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  color: #444444;
  background: #FFDFD1;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
`;

const Step = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border: 2px solid #FF9E57;
  border-radius: 5px;
  color: #FF9E57;
  background: #FFFFFF;
  box-sizing: border-box;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  
  > *:not(:first-child) {
    margin-top: 10px;
  }
`;

const Action = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

const ActionButton = styled(Button)`
  height: 30px;
  padding: 0 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

/* NOTE: these styles are adjusted to the old design.
   When the page design is updated, these need to be updated */
const EmployeeOfferStatusBanner = styled.div`
  display: grid;
  grid-template-columns: 18px 1fr;
  padding: 10px;
  border-radius: 5px;
  gap: 14px;
  background-color: #F2F2F2;
  align-items: center;
  width: 100%;
`;

const EmployeeOfferStatus = styled(Column)`
  gap: 5px;
`;

const Link = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #017698;
  cursor: pointer;
  margin-left: 7px;
  margin-right: 7px;
`;

const OfferSentDate = styled(Text)`
  font-size: 13px;
  line-height: 19px;
  color: #999999;
`;

const Uploader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  min-height: 36px;
  padding: 0 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
  
  &.odd {
    background: #F6F6F6;
  }
  
  &.even {
    background: #FFFFFF;
  }
  
  > *:first-child {
    margin-top: 6px;
    
    > span {
      max-width: 70px;
      min-width: 70px;
      margin-right: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const Uploads = styled(Column)`
  flex: 1;
  margin: 8px 0 8px 20px;
  overflow: hidden;

  > *:not(:first-child) {
    margin-top: 10px;
  }
`

const Upload = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  white-space: nowrap;

  > a {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #017698;
    text-decoration-line: underline;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  > span {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #999999;
  }
  
  > img {
    width: 16px;
    height: 16px;
    object-fit: cover;
    cursor: pointer;
  }
  
  > *:not(:first-child) {
    margin-left: 10px;
  }
`;

const Warning = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border-radius: 5px;
  color: #E93232;
  background: #FFF0F0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  
  > img {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    object-fit: cover;
  }
`;

const Prompt = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border-radius: 5px;
  color: #444444;
  background: #FFF3C9;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  
  > img {
    width: 18px;
    height: 18px;
    margin-right: 14px;
    object-fit: cover;
  }
`;

const InformationSharingSection: FunctionComponent<InformationSharingSectionProps> = ({
    proposal,
    documents,
    candidate,
    employmentOfferSentDate,
    onDocumentUpload,
    onDocumentDelete,
    onDocumentsSend,
}) => {
    const [mapping, setMapping] = useState<{ [key: string]: Document[] }>({});
    const [size, setSize] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleDocumentUpload = (kind: string, file: File) => {
        onDocumentUpload && onDocumentUpload(kind, file);
    };

    useEffect(() => {
        const mapping: { [key: string]: Document[] } = {};
        let size = 0;
        if (documents) {
            for (const [key, value] of Object.entries(DOCUMENT_KINDS)) {
                mapping[value] = documents.filter((document) => document.kind === value);
            }
            for (const document of documents) {
                size += document.size;
            }
        }
        setMapping(mapping);
        setSize(size);
    }, [documents])

    return (
        <Container>
            <Status>{t('proposal.candidate_hired')}</Status>
            <Blocks>
                <Block>
                    <Title>
                        <Step>1</Step>
                        <span>{t('proposal.share_documents')}</span>
                    </Title>
                    <Content>
                        <Action>
                            <span>{t('proposal.send_documents_description')}</span>
                            <ActionButton
                                disabled={!documents?.length || !candidate?.email || size > 10000000}
                                onClick={() => setModalOpen(true)}
                            >
                                {t('proposal.send_documents')}
                            </ActionButton>
                        </Action>

                        {(candidate && !candidate.email) && (
                            <Warning>
                                <img src="/images/icon-error.svg" alt="error-icon"/>
                                <span>{t('proposal.email_unavailable_warning')}</span>
                            </Warning>
                        )}
                        {size > 10000000 && (
                            <Warning>
                                <img src="/images/icon-error.svg" alt="error-icon"/>
                                <span>{t('proposal.size_exceeded_warning')}</span>
                            </Warning>
                        )}

                        <Column style={{ width: '100%' }}>
                            {Object.entries(DOCUMENT_KINDS)
                                .map(([key, value], index) => (
                                    <Uploader key={value} className={index % 2 ? 'odd' : 'even'}>
                                        <Row center>
                                            <span>
                                                {t(`proposal.document_kinds.${value}`)}
                                            </span>
                                            <UploadButton
                                                onFileChange={(file) => handleDocumentUpload(value, file)}
                                            />
                                        </Row>
                                        <Uploads>
                                            {mapping[value]?.map((document) => (
                                                <Upload key={document.id}>
                                                    <a href={document.url}>
                                                        {document.url.split('/').slice(-1)}
                                                    </a>
                                                    <span>{String(filesize(document.size))}</span>
                                                    <img
                                                        src="/images/icon-close.svg"
                                                        onClick={() => onDocumentDelete?.(document.id)}
                                                        alt="close-icon"
                                                    />
                                                </Upload>
                                            ))}
                                        </Uploads>
                                    </Uploader>
                                ))
                            }
                        </Column>

                        <Prompt>
                            <img src="/images/icon-info.svg" alt="info-icon"/>
                            <span>{t('proposal.share_documents_prompt')}</span>
                        </Prompt>
         
                        { employmentOfferSentDate &&
                          <EmployeeOfferStatusBanner>
                              <HistoryIcon/>
                              <EmployeeOfferStatus>
                                <Text>自動生成した
                                  <Link onClick={() => navigate(`/applications/${proposal!.id}/documents/employment_offer`)}>
                                    内定証明書<OpenIcon/>
                                  </Link>
                                  を候補者のメールアドレス宛に送付しました
                                </Text>
                                <OfferSentDate>
                                  {moment(employmentOfferSentDate).format('YYYY-MM-DD HH:mm')}
                                </OfferSentDate>
                              </EmployeeOfferStatus>
                          </EmployeeOfferStatusBanner>
                        }

                    </Content>
                </Block>

                <Block>
                    <Title>
                        <Step>2</Step>
                        <span>{t('proposal.get_in_touch')}</span>
                    </Title>
                    <Content>
                        <Text style={{ fontWeight: 500 }}>
                            {t('candidate.contact_information')}
                        </Text>
                        <ContactInformation
                            email={candidate?.email}
                            lineId={candidate?.lineId}
                            facebookProfileLink={candidate?.facebookProfileLink}
                        />
                    </Content>
                </Block>
            </Blocks>

            <SendDocumentsModal
                open={modalOpen}
                email={candidate?.email}
                documents={documents}
                onClose={() => setModalOpen(false)}
                onConfirm={() => {
                    onDocumentsSend?.();
                    setModalOpen(false);
                }}
            />
        </Container>
    );
};

export default InformationSharingSection;