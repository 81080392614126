import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonGroup from '../../../core/components/ButtonGroup';
import Checkbox from '../../../core/components/Checkbox';
import Field from '../../../core/components/Field';
import HelperText from '../../../core/components/HelperText';
import ImageUploader from '../../../core/components/ImageUploader';
import Label from '../../../core/components/Label';
import MultiSelect from '../../../core/components/MultiSelect';
import MultiTextInput from '../../../core/components/MultiTextInput';
import Radio from '../../../core/components/Radio';
import Row from '../../../core/components/Row';
import Section from '../../../core/components/Section';
import SectionHeading from '../../../core/components/SectionHeading';
import TextInput from '../../../core/components/TextInput';
import { Nationality } from '../../../core/types/api';
import { SelectOption } from '../../../core/components/Select';

type OtherSectionProps = {
    nationalities?: Nationality[];
    currentNationalities?: string[];
    specialAllowance?: string;
    interviewMethod?: string;
    interviewExpensesProvided?: boolean;
    interviewAvailability?: string;
    additionalInfo?: string;
    images?: Array<File|string>;
    onCurrentNationalitiesChange?: (currentNationalities: string[]) => void;
    onSpecialAllowanceChange?: (specialAllowance: string) => void;
    onInterviewMethodChange?: (interviewMethod: string) => void;
    onInterviewExpensesProvidedChange?: (interviewExpensesProvided: boolean) => void;
    onInterviewAvailabilityChange?: (interviewAvailability: string) => void;
    onAdditionalInfoChange?: (additionalInfo: string) => void;
    onImagesChange?: (images: Array<File|string>) => void;
};

const OtherSection: FunctionComponent<OtherSectionProps> = ({
    nationalities,
    currentNationalities,
    specialAllowance,
    interviewMethod,
    interviewExpensesProvided,
    interviewAvailability,
    additionalInfo,
    images,
    onCurrentNationalitiesChange,
    onSpecialAllowanceChange,
    onInterviewMethodChange,
    onInterviewExpensesProvidedChange,
    onInterviewAvailabilityChange,
    onAdditionalInfoChange,
    onImagesChange,
}) => {
    const [nationalityOptions, setNationalityOptions] = useState<SelectOption[]>([]);
    const { t } = useTranslation();

    useEffect(() => {
        if (nationalities) {
            const nationalityOptions = nationalities.map((nationality) => ({
                label: nationality.name,
                value: String(nationality.id),
            }));
            setNationalityOptions(nationalityOptions);
        } else {
            setNationalityOptions([]);
        }
    }, [nationalities]);

    return (
        <Section>
            <SectionHeading>{t('job.other')}</SectionHeading>

            <Field>
                <Label>{t('job.nationalities_of_currently_working_foreign_employees')}</Label>
                <MultiSelect
                    placeholder="core.select"
                    options={nationalityOptions}
                    value={currentNationalities}
                    onValueChange={onCurrentNationalitiesChange}
                />
                <HelperText>{t('job.may_select_multiple')}</HelperText>
            </Field>

            <Field>
                <Label>{t('job.special_allowance_upon_hire')}</Label>
                <TextInput
                    type="number"
                    placeholder="0"
                    value={specialAllowance}
                    onTextChange={onSpecialAllowanceChange}
                />
                <HelperText>{t('job.special_allowance_upon_hire_description')}</HelperText>
            </Field>

            <Field>
                <Label>{t('job.interview_method')}</Label>
                <Row style={{ alignItems: 'flex-end' }}>
                    <ButtonGroup style={{ marginTop: '8px' }}>
                        <Radio
                            value="online"
                            checked={interviewMethod === 'online'}
                            onValueChange={onInterviewMethodChange}
                        >
                            {t('job.online_interview')}
                        </Radio>
                        <Radio
                            value="offline"
                            checked={interviewMethod === 'offline'}
                            onValueChange={onInterviewMethodChange}
                        >
                            {t('job.offline_interview')}
                        </Radio>
                    </ButtonGroup>
                    <HelperText>{t('job.offline_interview_description')}</HelperText>
                </Row>
            </Field>

            {interviewMethod === 'offline' && (
                <Checkbox
                    style={{ marginTop: '20px' }}
                    checked={interviewExpensesProvided}
                    onValueChange={onInterviewExpensesProvidedChange}
                >
                    {t('job.provide_full_transportation_expenses')}
                </Checkbox>
            )}

            <Field style={{ width: '100%' }}>
                <Label>{t('job.available_days_and_times_for_interview')}</Label>
                <MultiTextInput
                    placeholder={t('job.available_days_and_times_for_interview_description')}
                    style={{
                        width: '100%',
                        height: '60px',
                    }}
                    value={interviewAvailability}
                    onTextChange={onInterviewAvailabilityChange}
                />
            </Field>

            <Field style={{ width: '100%' }}>
                <Label>{t('job.other_supplementary_information_about_this_job')}</Label>
                <MultiTextInput
                    placeholder={t('job.other_supplementary_information_about_this_job_example')}
                    style={{
                        width: '100%',
                        height: '150px',
                    }}
                    value={additionalInfo}
                    onTextChange={onAdditionalInfoChange}
                />
                <HelperText>
                    {t('job.other_supplementary_information_about_this_job_description')}
                </HelperText>
            </Field>

            <Field>
                <Label>{t('job.images_to_attach_to_job_post')}</Label>
                <HelperText>
                    {t('job.images_to_attach_to_job_post_description')}
                </HelperText>
                <ImageUploader images={images} onImagesChange={onImagesChange} />
            </Field>
        </Section>
    );
};

export default OtherSection;