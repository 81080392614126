import { FunctionComponent } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';

import { Candidate } from '../../types/api';
import Radio from '../../../core/components/Radio';
import { DESKTOP_DEVICE, MOBILE_DEVICE } from '../../../core/constants/styles';

type CandidateListTableProps = {
    selectable?: boolean;
    candidates: Candidate[];
    selectedIndex?: number;
    onItemClick?: (index: number) => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  background: #E5E5E5;
  border: 1px solid #D5D5D5;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  border-top: none;
  cursor: pointer;
  
  &:hover {
    background: #FFDFD1;
    border-color: #FF730D;
    border-top: 1px solid #FF730D;
  }
  
  @media ${MOBILE_DEVICE} {
    height: 60px;
  }
`;

const Item = styled.div`
  flex: 1;
  padding-left: 20px;
  font-size: 14px;
  color: #444444;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Selection = styled(Item)`
  max-width: 50px;
`;

const Avatar = styled(Item)`
  max-width: 50px;
  
  div {
    width: 30px;
    height: 30px;
    background: #D5D5D5;
    border-radius: 5px;
  }
  
  img {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    object-fit: cover;
  }
`;

const Name = styled(Item)`
  flex: 4;
`;

const Age = styled(Item)`
  flex: 1;
`;

const Gender = styled(Item)`
  flex: 1;
`;

const VisaStatus = styled(Item)`
  flex: 4;
`;

const ConversationSkills = styled(Item)`
  flex: 4;
`;

const JlptLevel = styled(Item)`
  flex: 1;
`;

const LastUpdated = styled(Item)`
  flex: 4;
`;

const CandidateListTable: FunctionComponent<CandidateListTableProps> = ({
    selectable,
    candidates,
    selectedIndex,
    onItemClick,
}) => {
    const { t } = useTranslation();
    const isDesktopDevice = useMediaQuery({
        query: DESKTOP_DEVICE,
    });

    return (
        <Container>
            <Header>
                {isDesktopDevice
                    ? (
                        <>
                            {selectable && (
                                <Selection />
                            )}
                            <Avatar></Avatar>
                            <Name>{t('core.name')}</Name>
                            <Age>{t('candidate.age')}</Age>
                            <Gender>{t('candidate.gender')}</Gender>
                            <VisaStatus>{t('candidate.candidate_visa_status')}</VisaStatus>
                            <ConversationSkills>{t('candidate.japanese_conversation_skills')}</ConversationSkills>
                            <JlptLevel>{t('candidate.jlpt_level')}</JlptLevel>
                            <LastUpdated>{t('core.last_updated')}</LastUpdated>
                        </>
                    )
                    : (
                        <>
                            <Avatar></Avatar>
                            <Name>{t('core.name')}</Name>
                        </>
                    )
                }
            </Header>
            {candidates.map((candidate, index) => (
                <Row
                    key={candidate.id}
                    onClick={() => onItemClick && onItemClick(index)}
                >
                    {isDesktopDevice
                        ? (
                            <>
                                {selectable && (
                                    <Selection>
                                        <Radio checked={index === selectedIndex} />
                                    </Selection>
                                )}
                                <Avatar>
                                    <div>
                                        {candidate.image && (
                                            <img alt="avatar" src={candidate.image.url} />
                                        )}
                                    </div>
                                </Avatar>
                                <Name>
                                    {candidate.enFirstName}
                                    {!!candidate.enMiddleName && ` ${candidate.enMiddleName}`}
                                    {` ${candidate.enLastName}`}
                                </Name>
                                <Age>{candidate.age}</Age>
                                <Gender>{t(`core.${candidate.gender}`)}</Gender>
                                <VisaStatus>{t(`candidate.${candidate.visaStatus}`)}</VisaStatus>
                                <ConversationSkills>{t(`candidate.${candidate.japaneseConversationSkills}`)}</ConversationSkills>
                                <JlptLevel>{candidate.jlptLevel.toUpperCase()}</JlptLevel>
                                <LastUpdated>
                                    {moment(candidate.updatedAt).format('YYYY/MM/DD')}
                                </LastUpdated>
                            </>
                        )
                        : (
                            <>
                                <Avatar>
                                    <div>
                                        {candidate.image && (
                                            <img alt="avatar" src={candidate.image.url} />
                                        )}
                                    </div>
                                </Avatar>
                                <Name>
                                    {candidate.enFirstName}
                                    {!!candidate.enMiddleName && ` ${candidate.enMiddleName}`}
                                    {` ${candidate.enLastName}`}
                                </Name>
                            </>
                        )
                    }
                </Row>
            ))}
        </Container>
    );
};

export default CandidateListTable;