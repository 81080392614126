import {
    createContext,
    FunctionComponent,
    useReducer,
    useContext,
    ComponentProps
} from 'react';

import ACTIONS from '../constants/actions';
import { ReducerAction, ReducerContextValue, ReducerState } from '../types/reducer';

const saveState = (state: {[key: string]: any}) => {
    localStorage.setItem('reducerState', JSON.stringify(state))
};

const loadState = () => JSON.parse(localStorage.getItem('reducerState') || '{}');

const clearState = () => {
    localStorage.removeItem('reducerState');
};

//The number represents seconds, not milliseconds
const PROMPT_TIMEOUT_DEFAULT = 5;

const INITIAL_STATE = {
    isLoading: false,
    loadingMessage: '',
    isConfirmation: false,
    confirmationMessage: '',
    confirmationCallback: () => {},
    isPrompting: false,
    promptingType: 'neutral',
    promptingMessage: '',
    promptingTimeout: PROMPT_TIMEOUT_DEFAULT,
    isLoggedIn: false,
    companyId: 0,
    company: null,
    isAdmin: false,
    isEmployer: false,
    isPartner: false,
    nationalities: {},
    industries: {},
    jobTypes: {},
    userSetting: {},
    additionalInformationTypes: {},
    isNavigable: true,
    isUploading: false,
    ...loadState(),
};

export const ReducerContext = createContext<ReducerContextValue>({
    state: INITIAL_STATE,
    dispatch: () => {},
});

const reducer = (state: ReducerState, action: ReducerAction) => {
    const { type, payload } = action;

    switch (type) {
        case ACTIONS.START_LOADING:
            return {
                ...state,
                isLoading: true,
                loadingMessage: payload?.message ?? '',
            };
        case ACTIONS.STOP_LOADING:
            return {
                ...state,
                isLoading: false,
                loadingMessage: '',
            }
        case ACTIONS.START_CONFIRMATION:
            return {
                ...state,
                isConfirmation: true,
                confirmationMessage: payload.message,
                confirmationCallback: payload.callback,
            };
        case ACTIONS.STOP_CONFIRMATION:
            return {
                ...state,
                isConfirmation: false,
                confirmationMessage: '',
                confirmationCallback: () => {},
            };
        case ACTIONS.SET_PROMPT:
            return {
                ...state,
                isPrompting: true,
                promptingType: payload.type,
                promptingMessage: payload.message,
                promptingTimeout: payload.timeout ?? PROMPT_TIMEOUT_DEFAULT,
            };
        case ACTIONS.CLEAR_PROMPT:
            return {
                ...state,
                isPrompting: false,
                promptingType: 'neutral',
                promptingMessage: '',
                promptingTimeout: PROMPT_TIMEOUT_DEFAULT,
            };
        case ACTIONS.LOGIN:
            saveState({
                isLoggedIn: true,
                company: payload.company,
                candidate: payload.candidate,
                isPartner: payload.isPartner,
                isEmployer: payload.isEmployer,
                isCandidate: payload.isCandidate,
                userSetting: payload.userSetting
            });
            return {
                ...state,
                isLoggedIn: true,
                company: payload.company,
                candidate: payload.candidate,
                isPartner: payload.isPartner,
                isEmployer: payload.isEmployer,
                isCandidate: payload.isCandidate,
                userSetting: payload.userSetting
            };
        case ACTIONS.LOGOUT:
            clearState()
            return {
                ...state,
                isLoggedIn: false,
                company: null,
                candidate: null,
                isPartner: false,
                isEmployer: false,
                isCandidate: false,
            };
        case ACTIONS.UPDATE_COMPANY:
            saveState({
                isLoggedIn: state.isLoggedIn,
                company: payload.company,
                candidate: state.candidate,
                isPartner: state.isPartner,
                isEmployer: state.isEmployer,
                isCandidate: state.isCandidate,
                userSetting: state.userSetting
            });
            return {
                ...state,
                company: payload.company,
            };
        case ACTIONS.UPDATE_CANDIDATE:
            saveState({
                isLoggedIn: state.isLoggedIn,
                company: state.company,
                candidate: payload.candidate,
                isPartner: state.isPartner,
                isEmployer: state.isEmployer,
                isCandidate: state.isCandidate,
                userSetting: state.userSetting
            });
            return {
                ...state,
                candidate: payload.candidate,
            };
        case ACTIONS.UPDATE_USER_SETTING:
            saveState({
                isLoggedIn: state.isLoggedIn,
                company: state.company,
                candidate: state.candidate,
                isPartner: state.isPartner,
                isEmployer: state.isEmployer,
                isCandidate: state.isCandidate,
                userSetting: payload
            });
            return {
                ...state,
                userSetting: payload
            };
        case ACTIONS.SET_NATIONALITIES:
            return {
                ...state,
                nationalities: payload.nationalities,
            };
        case ACTIONS.SET_INDUSTRIES:
            return {
                ...state,
                industries: payload.industries,
            };
        case ACTIONS.SET_JOB_TYPES:
            return {
                ...state,
                jobTypes: payload.jobTypes,
            };
        case ACTIONS.SET_ADDITIONAL_INFORMATION_TYPES:
            return {
                ...state,
                additionalInformationTypes: payload.additionalInformationTypes,
            };
        case ACTIONS.SET_NAVIGABLE:
            return {
                ...state,
                isNavigable: payload.isNavigable,
            };
        case ACTIONS.START_UPLOADING:
            return {
                ...state,
                isUploading: true,
            };
        case ACTIONS.STOP_UPLOADING:
            return {
                ...state,
                isUploading: false,
            };
        default:
            return state
    }
};

export const ReducerContextProvider: FunctionComponent<ComponentProps<any>> = (props) => {
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
    return (
        <ReducerContext.Provider value={{ state, dispatch }} {...props} />
    );
};

export const useReducerContext = () => {
    return useContext(ReducerContext);
};