import { FunctionComponent, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { ReactComponent as CrossIcon } from '../../assets/icon-cross.svg';

const BackgroundColors = {
    default: '#227AFF',
    success: '#40BF6E',
    warning: '#E93232',
    neutral: '#227AFF',
};

interface SnackbarProps extends HTMLAttributes<HTMLDivElement> {
    type?: 'success' | 'warning' | 'neutral';
    onClose?: () => void;
}

interface ContainerProps {
    type?: 'success' | 'warning' | 'neutral';
}

const Container = styled.div<ContainerProps>`
  position: fixed;
  top: 10px;
  left: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  min-height: 40px;
  height: auto;
  padding: 10px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #FFFFFF;
  background: ${(props: ContainerProps) => (
      BackgroundColors[props.type ?? 'default'] ?? BackgroundColors.default
  )};
  transition: all 0.4s;
  transform: translateX(-50%);
  z-index: 10;

  animation-name: slideinout;
  animation-duration: 5s;
  animation-fill-mode: forwards;

  @keyframes slideinout {
    0% {
      top: -40px;
      opacity: 0;
    }
    10% {
      top: 5px;
      opacity: 1;
    }
    85% {
      top: 5px;
      opacity: 1;
    }
    100% {
      top: -40px;
      opacity: 0;
    }
  }
`;

const SuccessIcon = styled.img`
  width: 18px;
  height: 18px;
  object-fit: cover;
`;

const CloseIcon = styled(CrossIcon)`
  width: 20px;
  height: 20px;
  object-fit: cover;
  cursor: pointer;
  > path {
    fill: #5F6368;
  }
`;

const Snackbar: FunctionComponent<SnackbarProps> = ({
    type,
    onClose,
    children,
}) => {
    return (
        <Container type={type}>
            {(type === 'success') && (
                <SuccessIcon alt="success" src="/images/icon-success-white.svg" />
            )}
            {(type === 'warning') && (
                <SuccessIcon alt="warning" src="/images/icon-warning-white.svg" />
            )}
            {children}
            {onClose && (
                <CloseIcon onClick={onClose} />
            )}
        </Container>
    );
};

export default Snackbar;