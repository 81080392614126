import { Candidate } from '../candidate/types/api';

export const isProfileComplete = (profile: Candidate) => {
    return !!profile.image
        && !!profile.enFirstName
        && !!profile.enLastName
        && !!profile.age
        && !!profile.gender
        && !!profile.nationalityId
        && !!profile.visaStatus
        && !!profile.currentAddress
        && !!profile.japaneseConversationSkills
        && !!profile.jlptLevel
        && !!profile.currentWorkHours
        && !!profile.nearestPossibleDate;
};