import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import SearchPanel from './SearchPanel';
import CandidateOverview from '../../components/CandidateOverview';
import JobOverview from '../../components/JobOverview';
import ScoutListTable from '../../components/ScoutListTable';
import { Scout } from '../../types';
import { getCandidate } from '../../../candidate/apis/candidate';
import { Candidate } from '../../../candidate/types/api';
import Button from '../../../core/components/Button';
import Page from '../../../core/components/Page';
import PageHeading from '../../../core/components/PageHeading';
import ACTIONS from '../../../core/constants/actions';
import { useReducerContext } from '../../../core/contexts/ReducerContext';
import { Mapping } from '../../../core/types/common';
import { getJobs } from '../../../job/apis/job';
import { Job } from '../../../job/types/api';
import Row from '../../../core/components/Row';
import { getScouts, markScoutAsRead } from '../../apis';
import { MOBILE_DEVICE } from '../../../core/constants/styles';
import { useMediaQuery } from 'react-responsive';

const COUNT_PER_PAGE = 20;

interface CandidateListPageProps {
}

const LocalPage = styled(Page)`
  padding: 0;
  
  > * {
    width: 100%;
    margin: 0;
    gap: 10px;
  }
  
  @media ${MOBILE_DEVICE} {
    padding: 10px 0;
  }
`;

const LocalPageHeading = styled(PageHeading)`
  font-size: 20px;
  line-height: 29px;
  word-break: keep-all;
`;

const Container = styled.div`
  flex: 1;
  padding: 10px 20px 0 20px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  gap: 20px;
`;

const TopContainer = styled.div`
  padding: 20px 20px 0 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;

  @media ${MOBILE_DEVICE} {
    padding: 0 20px;
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  
  @media ${MOBILE_DEVICE} {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 35px 20px 20px 20px;
    background: #FFFFFF;
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding: 20px;
  
  > * {
    flex: 1;
  }
`;

const CloseButton = styled.img`
  position: absolute;
  top: 14px;
  right: 14px;
  width: 16px;
  height: 16px;
  object-fit: cover;
  cursor: pointer;
`;

const ScoutListPage: FunctionComponent<CandidateListPageProps> = () => {
    const { state, dispatch } = useReducerContext();
    const [scouts, setScouts] = useState<Scout[]>([]);
    const [jobs, setJobs] = useState<Mapping<Job>>({});
    const [candidates, setCandidates] = useState<Mapping<Candidate>>({});
    const [activeIndex, setActiveIndex] = useState(-1);
    const [currentFilters, setCurrentFilters] = useState<{ [key: string]: any }>({});
    const [currentPage, setCurrentPage] = useState(1);
    const [allFetched, setAllFetched] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isMobileDevice = useMediaQuery({
        query: MOBILE_DEVICE,
    });

    const fetchJobs = async () => {
        dispatch({
            type: ACTIONS.START_LOADING,
            payload: {
                message: t('Fetching scouts...'),
            },
        });
        try {
            const jobs: Mapping<Job> = {};
            for (const job of await getJobs({
                employerId: state.company?.employer?.id,
                state: 'published',
            })) {
                jobs[job.id] = job;
            }
            setJobs(jobs);
        } catch (e) {
            // TODO: error handling
        }
        dispatch({
            type: ACTIONS.STOP_LOADING,
        });
    };

    const handleSearch = async (filters = {}, reset = false) => {
        dispatch({
            type: ACTIONS.START_LOADING,
            payload: {
                message: t('Fetching scouts...'),
            },
        });
        let newScouts: Scout[] = [];
        try {
            const params: { [key: string]: any } = {
                page: reset ? 1 : currentPage + 1,
                per: COUNT_PER_PAGE,
                ...filters,
            };
            if (state.isCandidate) {
                params.candidateIds = state.candidate?.id;
            }
            if (state.isEmployer) {
                params.employerId = state.company?.employer?.id;
            }
            if (state.isPartner) {
                params.partnerId = state.company?.partner?.id;
            }
            newScouts = await getScouts(params);
            if (reset) {
                setScouts(newScouts);
            } else {
                setScouts([
                    ...scouts,
                    ...newScouts,
                ]);
            }
            setCurrentFilters(filters);
            setCurrentPage(reset ? 1 : currentPage + 1)
            setAllFetched(newScouts.length < COUNT_PER_PAGE);
        } catch (e) {
        }

        dispatch({
            type: ACTIONS.START_LOADING,
            payload: {
                message: t('candidate.fetching_candidates'),
            },
        });
        try {
            const newCandidates = {
                ...candidates
            };
            for (const scout of newScouts) {
                if (!newCandidates[scout.candidateId]) {
                    newCandidates[scout.candidateId] = await getCandidate(scout.candidateId);
                }
            }
            setCandidates(newCandidates);
        } catch (e) {
            // TODO: error handling
        }
        dispatch({
            type: ACTIONS.STOP_LOADING,
        });
    };

    const handleSelect = async (index: number) => {
        if (!state.isEmployer) {
            setActiveIndex(index);
            const scout = await markScoutAsRead(scouts[index].id);
            const newScouts = [...scouts];
            newScouts.splice(index, 1, scout);
            setScouts(newScouts);
        }
    }

    const handleScrollEnd = () => {
        if (!allFetched) {
            handleSearch(currentFilters, false);
        }
    };

    useEffect( () => {
        (async () => {
            await fetchJobs();
            await handleSearch({}, true);
        })();
    }, [])

    return (
        <LocalPage>
            <TopContainer>
                <Row style={{ width: '100%', justifyContent: 'space-between' }} center>
                    {state.isEmployer && (
                        <>
                            <LocalPageHeading>{t('scout.sent_scouts')}</LocalPageHeading>
                            <Button onClick={() => navigate('/scouts/new')}>
                                {t('scout.send_new_scout')}
                            </Button>
                        </>
                    )}
                    {(state.isCandidate && isMobileDevice) && (
                        <>
                            <LocalPageHeading>{t('core.scout')}</LocalPageHeading>
                            <SearchPanel 
                                onSubmit={(filters) => handleSearch(filters, true)} 
                                onClear={() => handleSearch({}, true)}
                            />
                        </>
                    )}
                </Row>
                {!(state.isCandidate && isMobileDevice) && (
                    <SearchPanel
                        jobs={jobs}
                        onSubmit={(filters) => handleSearch(filters, true)}
                        onClear={() => handleSearch({}, true)}
                    />
                )}
            </TopContainer>
            <Container>
                <ScoutListTable
                    style={{ flex: 1 }}
                    scouts={scouts}
                    jobs={jobs}
                    candidates={candidates}
                    onItemClick={handleSelect}
                    onScrollEnd={handleScrollEnd}
                />
                {scouts[activeIndex] && (
                    <RightContainer>
                        {isMobileDevice && (
                            <CloseButton
                                alt="close"
                                src="/images/icon-close.svg"
                                onClick={() => setActiveIndex(-1)}
                            />
                        )}
                        {state.isPartner && (
                            <Row style={{ justifyContent: 'flex-end' }}>
                                <Button
                                    onClick={() => navigate(`/jobs/${scouts[activeIndex].jobPostId}/propose/${scouts[activeIndex].candidateId}`)}
                                >
                                    {t('proposal.propose')}
                                </Button>
                            </Row>
                        )}
                        <JobOverview job={jobs[scouts[activeIndex].jobPostId]} />
                        {!state.isCandidate && (
                            <CandidateOverview candidate={candidates[scouts[activeIndex].candidateId]} />
                        )}
                        {state.isCandidate && (
                            <ButtonContainer>
                                <Button
                                    variant="secondary"
                                    onClick={() => window.open(`/jobs/${scouts[activeIndex].jobPostId}`, '_blank')}
                                >
                                    {t('scout.view_job')}
                                </Button>
                                <Button
                                    onClick={() => navigate(`/jobs/${scouts[activeIndex].jobPostId}/propose`)}
                                >
                                    {t('scout.apply')}
                                </Button>
                            </ButtonContainer>
                        )}
                    </RightContainer>
                )}
            </Container>
        </LocalPage>
    );
};

export default ScoutListPage;