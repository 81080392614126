import { EmploymentOfferLetter } from "../../../core/types/api";
import { apiUtil } from "../../../core/utils";


export const getEmploymentOfferLetter = async (proposalId: string | number, token: string) => {
  const response = await apiUtil.get(
      `/proposals/${proposalId}/employment_offer?token=${token}`,
  );
  if (response.status >= 200 && response.status < 300) {
      return response.data as EmploymentOfferLetter;
  }
  throw new Error('API Error');
};
