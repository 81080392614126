import { FunctionComponent, useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import BasicInformationSection from './BasicInformationSection';
import CandidateRequirementsSection from './CandidateRequirementsSection';
import HousingSection from './HousingSection';
import OtherSection from './OtherSection';
import SalarySection from './SalarySection';
import WorkHoursSection from './WorkHoursSection';
import {
    createJob,
    createJobImage,
    deleteJobImage,
    getJob,
    updateJob
} from '../../apis/job';
import { getMonths} from '../../../core/apis/option';
import ActionBar from '../../../core/components/ActionBar';
import Button from '../../../core/components/Button';
import Page from '../../../core/components/Page';
import PageHeading from '../../../core/components/PageHeading';
import ACTIONS from '../../../core/constants/actions';
import { useReducerContext } from '../../../core/contexts/ReducerContext';
import { Month, Nationality } from '../../../core/types/api';
import { useJobPostCategory, useNationalities } from '../../../core/hooks';

interface JobFormPageProps {
}

const JobFormPage: FunctionComponent<JobFormPageProps> = () => {
    const { state, dispatch } = useReducerContext();
    const { jobId } = useParams();
    const [searchParams] = useSearchParams();
    const [jobState, setJobState] = useState<string|undefined>('draft');
    const [months, setMonths] = useState<Month[]>([]);
    const [jobOfferTitle, setJobOfferTitle] = useState('');
    const [numberOfPositions, setNumberOfPositions] = useState('');
    const [applicationDeadline, setApplicationDeadline] = useState<Date|undefined>(undefined);
    const [plannedEmploymentDate, setPlannedEmploymentDate] = useState('');
    const [industryId, setIndustryId] = useState('');
    const [jobTypeId, setJobTypeId] = useState('');
    const [contractType, setContractType] = useState('contract');
    const [contractPeriod, setContractPeriod] = useState<string|undefined>(undefined);
    const [contractPeriodYears, setContractPeriodYears] = useState('');
    const [contractRenewal, setContractRenewal] = useState('');
    const [acceptOverseasResidents, setAcceptOverseasResidents] = useState(false);
    const [postalCode, setPostalCode] = useState('');
    const [postalCodeAddressId, setPostalCodeAddressId] = useState('');
    const [prefecture, setPrefecture] = useState('');
    const [city, setCity] = useState('');
    const [town, setTown] = useState('');
    const [locality, setLocality] = useState('');
    const [buildingEtc, setBuildingEtc] = useState('');
    const [description, setDescription] = useState('');
    const [salaryType, setSalaryType] = useState('');
    const [annualizedWorkingHours, setAnnualizedWorkingHours] = useState<boolean|undefined>(undefined);
    const [hourlyDailySalary, setHourlyDailySalary] = useState('');
    const [hourlyDailySalaryUpperLimit, SetHourlyDailySalaryUpperLimit] = useState('');
    const [grossSalary, setGrossSalary] = useState('');
    const [grossSalaryUpperLimit, setGrossSalaryUpperLimit] = useState('');
    const [estimatedTotalDeductions, setEstimatedTotalDeductions] = useState('');
    const [expectedSocial, setExpectedSocial] = useState('');
    const [dormFee, setDormFee] = useState('');
    const [expectedTax, setExpectedTax] = useState('');
    const [salaryAfterTax, setSalaryAfterTax] = useState('');
    const [nightShiftHours, setNightShiftHours] = useState('');
    const [nightShiftWage, setNightShiftWage] = useState('');
    const [nightWorkAllowance, setNightWorkAllowance] = useState<string|undefined>(undefined);
    const [salaryRaise, setSalaryRaise] = useState<boolean|undefined>(undefined);
    const [bonus, setBonus] = useState<boolean|undefined>(undefined);
    const [bonusPayments, setBonusPayments] = useState('');
    const [bonusMonths, setBonusMonths] = useState<string[]>([]);
    const [hoursPerDay, setHoursPerDay] = useState('');
    const [minutesPerDay, setMinutesPerDay] = useState('');
    const [shiftBased, setShiftBased] = useState<boolean|undefined>(undefined);
    const [annualHours, setAnnualHours] = useState('');
    const [monthlyDays, setMonthlyDays] = useState('');
    const [averageOvertime, setAverageOvertime] = useState('');
    const [holidaysOff, setHolidaysOff] = useState('');
    const [dormitoryAvailable, setDormitoryAvailable] = useState<boolean|undefined>(undefined);
    const [dormitoryType, setDormitoryType] = useState('');
    const [bedroomCount, setBedroomCount] = useState('');
    const [employerHouseAssist, setEmployerHouseAssist] = useState<boolean|undefined>(undefined);
    const [residenceLeaseOwner, setResidenceLeaseOwner] = useState('');
    const [commuteMethod, setCommuteMethod] = useState('');
    const [commuteTime, setCommuteTime] = useState('');
    const [furnitureProvided, setFurnitureProvided] = useState<boolean|undefined>(undefined);
    const [movingTransExpenses, setMovingTransExpenses] = useState<boolean|undefined>(undefined);
    const [movingTransExpensesAllowance, setMovingTransExpensesAllowance] = useState('');
    const [movingBagsExpenses, setMovingBagsExpenses] = useState<boolean|undefined>(undefined);
    const [movingBagsExpensesAllowance, setMovingBagsExpensesAllowance] = useState('');
    const [naturalJapaneseLevel, setNaturalJapaneseLevel] = useState('');
    const [japaneseLevel, setJapaneseLevel] = useState('');
    const [preferredGender, setPreferredGender] = useState('');
    const [preferredCountries, setPreferredCountries] = useState<string[]>([]);
    const [preferredAgeMin, setPreferredAgeMin] = useState('');
    const [preferredAgeMax, setPreferredAgeMax] = useState('');
    const [required, setRequired] = useState('');
    const [preferred, setPreferred] = useState('');
    const [currentNationalities, setCurrentNationalities] = useState<string[]>([]);
    const [specialAllowance, setSpecialAllowance] = useState('');
    const [interviewMethod, setInterviewMethod] = useState('');
    const [coverInterviewExpense, setCoverInterviewExpense] = useState<boolean|undefined>(undefined);
    const [interviewAvailability, setInterviewAvailability] = useState('');
    const [additionalInfo, setAdditionalInfo] = useState('');
    const [images, setImages] = useState<Array<File|string>>([]);
    const [imageIdMapping, setImageIdMapping] = useState<Record<string, number>>({});
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const jobPostCategory = useJobPostCategory();
    const { nationalityList } = useNationalities();
    
    const AreThereMissingRequiredFields = () => {

        const requiredFields = {
            //Basic Inforamtion
            jobOfferTitle,
            numberOfPositions: numberOfPositions,
            jobTypeId,

            //Work Location
            postalCodeAddressId,

            //Salary section
            salaryType,
            grossSalary: grossSalary,
            hourlyDailySalary: salaryType === 'monthly' || hourlyDailySalary,
            estimatedTotalDeductions: estimatedTotalDeductions,
            estimatedSocialInsurance: expectedSocial,
            dormitoryFee: dormFee,
            estimatedIncomeTax: expectedTax,
            takeHomeSalary: salaryAfterTax,

            //Work Hours section
            basicWorkHoursPerDay: hoursPerDay,
            basicWorkMinutesPerDay: minutesPerDay,

            //Candidate section
            naturalJapaneseLevel
        };

        for (const [key, value] of Object.entries(requiredFields)) {
            if (!value) 
                return true;            
        }
        
        return false;
    }

    const registerJob = async (isDraft: boolean) => {
        dispatch({
            type: ACTIONS.START_LOADING,
            payload: {
                message: t(`job.${jobId ? 'updat' : 'creat'}ing_job`),
            },
        })
        try {
            const data = {
                state: isDraft ? 'draft' : 'published',
                name: jobOfferTitle,
                numberOfPositions: Number(numberOfPositions),
                applicationDeadline: applicationDeadline
                    ? moment(applicationDeadline).format('YYYY-MM-DD')
                    : undefined,
                plannedDateOfEmployment: plannedEmploymentDate ?? undefined,
                jobTypeId: jobTypeId ?? 0,
                contractPeriod,
                contractPeriodYears: contractPeriod ? Number(contractPeriodYears) : undefined,
                contractRenewal,
                acceptOverseasResidents: acceptOverseasResidents,
                postalCodeAddressId,
                addressNumber: locality,
                buildingName: buildingEtc,
                jobDescription: description,
                salaryType,
                annualizedVariableWorkingHourSystem: annualizedWorkingHours,
                hourlyDailySalary: Number(hourlyDailySalary),
                hourlyDailySalaryUpperLimit: hourlyDailySalaryUpperLimit? Number(hourlyDailySalaryUpperLimit) : null,
                grossSalary: Number(grossSalary),
                grossSalaryUpperLimit: grossSalaryUpperLimit? Number(grossSalaryUpperLimit) : null,
                estimatedTotalDeductions: Number(estimatedTotalDeductions),
                estimatedSocialInsurance: Number(expectedSocial),
                dormitoryFee: Number(dormFee),
                estimatedIncomeTax: Number(expectedTax),
                takeHomeSalary: Number(salaryAfterTax),
                averageNightWorkHoursPerMonth: Number(nightShiftHours),
                averageTotalNightShiftPremiumWagesPerMonth: Number(nightShiftWage),
                //nightWorkAllowance: nightWorkAllowance ? Number(nightWorkAllowance) : undefined,
                salaryRaise,
                bonus,
                numberOfBonusPaymentsPerYear: bonusPayments ? Number(bonusPayments) : undefined,
                bonusMonthIds: bonusMonths.map((bonusMonth) => Number(bonusMonth)),
                basicWorkHoursPerDay: Number(hoursPerDay),
                basicWorkMinutesPerDay: Number(minutesPerDay),
                workStyle: shiftBased
                    ? 'shift_system'
                    : 'fixed_hours',
                //annualHours: Number(annualHours),
                averageMonthlyWorkDays: Number(monthlyDays),
                averageMonthlyOvertimeHours: Number(averageOvertime),
                averageAnnualDaysOff: Number(holidaysOff),
                dormitory: dormitoryAvailable,
                dormitoryType,
                bedroomPersons: Number(bedroomCount),
                employerWillAssistInFindingResidence: employerHouseAssist,
                personWhoContractsLease: residenceLeaseOwner,
                commuteMethod,
                commuteMinutes: commuteTime,
                furnitureAppliances: furnitureProvided,
                transportationMovingExpensesCovered: movingTransExpenses,
                transportationMovingExpensesMaximumSupportAllowance: movingTransExpensesAllowance
                    ? Number(movingTransExpensesAllowance)
                    : undefined,
                baggageDeliveryMovingExpensesCovered: movingBagsExpenses,
                baggageDeliveryMovingExpensesMaximumSupportAllowance: movingBagsExpensesAllowance
                    ? Number(movingBagsExpensesAllowance)
                    : undefined,
                japaneseConversationSkills: naturalJapaneseLevel,
                jlptLevel: japaneseLevel ?? undefined,
                preferredGender,
                preferredNationalityIds:
                    preferredCountries.map((preferredCountry) => Number(preferredCountry)),
                preferredAgeFrom: Number(preferredAgeMin),
                preferredAgeTo: Number(preferredAgeMax),
                requiredConditions: required,
                preferredConditions: preferred,
                currentNationalityIds:
                    currentNationalities.map((currentNationality) => Number(currentNationality)),
                specialAllowanceUponHire: Number(specialAllowance),
                interviewMethod,
                provideFullTransportationExpenses: coverInterviewExpense,
                availableDaysAndTimesForInterviews: interviewAvailability,
                otherSupplementaryInformationAboutThisJob: additionalInfo,
                //closed: isDraft,
            };
            if (jobId) {
                await updateJob(jobId, data);
                const imageUrls = [];
                for (const image of images) {
                    if (typeof image === 'string') {
                        imageUrls.push(image);
                    } else {
                        await createJobImage(jobId, image);
                    }
                }
                for (const key of Object.keys(imageIdMapping)) {
                    if (!imageUrls.includes(key)) {
                        await deleteJobImage(imageIdMapping[key]);
                    }
                }
            } else {
                const job = await createJob(data);
                for (const image of images) {
                    if (typeof image !== 'string') {
                        await createJobImage(job.id, image);
                    }
                }
            }
            dispatch({
                type: ACTIONS.SET_PROMPT,
                payload: {
                    type: 'success',
                    message: t(`job.job_${jobId ? 'update' : 'registration'}_success`),
                },
            });
            navigate('/jobs');
        } catch (e) {
            dispatch({
                type: ACTIONS.SET_PROMPT,
                payload: {
                    type: 'warning',
                    message: t(`job.job_${jobId ? 'update' : 'registration'}_failed`),
                },
            });
        }
        dispatch({
            type: ACTIONS.STOP_LOADING,
        })
    }

    useEffect(() => {
        (async () => {
            const months = await getMonths();
            months && setMonths(months);    
        })();
    }, []);

    useEffect(() => {
        (async () => {
            //When a job post is copied based on another existing job post,
            //the reference job ID is passed through 'from' parameter
            const referenceJobId = searchParams.get('from');
            const fromJobId = jobId || referenceJobId;
            if (fromJobId) {
                dispatch({
                    type: ACTIONS.START_LOADING,
                    payload: {
                        message: t('job.fetching_job_details'),
                    },
                })
                try {
                    const job = await getJob(fromJobId);
                    setJobState(referenceJobId? 'draft' : job.state);
                    setJobOfferTitle(job.name);
                    setNumberOfPositions(String(job.numberOfPositions));
                    setApplicationDeadline(job.applicationDeadline
                        ? new Date(job.applicationDeadline)
                        : undefined
                    );
                    setPlannedEmploymentDate(job.plannedDateOfEmployment ?? '');
                    setJobTypeId(String(job.jobTypeId));
                    setContractPeriod(job.contractPeriod);
                    setContractPeriodYears(String(job.contractPeriodYears ?? ''));
                    setContractRenewal(job.contractRenewal ?? '');
                    setAcceptOverseasResidents(job.acceptOverseasResidents);
                    setPostalCode(job.postalCode ?? '');
                    setPostalCodeAddressId(job.postalCodeAddressId ?? '');

                    if (i18n.language === 'ja') {
                        setPrefecture(job.jaPrefecture ?? '');
                        setCity(job.jaCity ?? '');
                        setTown(job.jaTown ?? '');
                    } else {
                        setPrefecture(job.enPrefecture ?? '');
                        setCity(job.enCity ?? '');
                        setTown(job.enTown ?? '');
                    }

                    setLocality(job.addressNumber ?? '');
                    setBuildingEtc(job.buildingName ?? '');
                    setDescription(job.jobDescription);
                    setSalaryType(job.salaryType ?? '');
                    setAnnualizedWorkingHours(job.annualizedVariableWorkingHourSystem ?? undefined);
                    setHourlyDailySalary(String(job.hourlyDailySalary ?? ''));
                    SetHourlyDailySalaryUpperLimit(String(job.hourlyDailySalaryUpperLimit ?? ''));
                    setGrossSalary(String(job.grossSalary ?? ''));
                    setGrossSalaryUpperLimit(String(job.grossSalaryUpperLimit ?? ''));
                    setEstimatedTotalDeductions(String(job.estimatedTotalDeductions ?? ''));
                    setExpectedSocial(String(job.estimatedSocialInsurance ?? ''));
                    setDormFee(String(job.dormitoryFee ?? ''));
                    setExpectedTax(String(job.estimatedIncomeTax ?? ''));
                    setSalaryAfterTax(String(job.takeHomeSalary ?? ''));
                    setNightShiftHours(String(job.averageNightWorkHoursPerMonth));
                    setNightShiftWage(String(job.averageTotalNightShiftPremiumWagesPerMonth));
                    setSalaryRaise(job.salaryRaise ?? undefined);
                    setBonus(job.bonus ?? undefined);
                    setBonusPayments(String(job.numberOfBonusPaymentsPerYear ?? ''));
                    setBonusMonths(job.bonusMonths
                        ? job.bonusMonths.map((bonusMonth: Month) => String(bonusMonth.id))
                        : []
                    );
                    setHoursPerDay(String(job.basicWorkHoursPerDay ?? ''));
                    setMinutesPerDay(String(job.basicWorkMinutesPerDay ?? ''));
                    setShiftBased(job.workStyle === 'shift_system');
                    setMonthlyDays(String(job.averageMonthlyWorkDays));
                    setAverageOvertime(String(job.averageMonthlyOvertimeHours));
                    setHolidaysOff(String(job.averageAnnualDaysOff));
                    setDormitoryAvailable(job.dormitory ?? undefined);
                    setDormitoryType(String(job.dormitoryType || ''));
                    setBedroomCount(String(job.bedroomPersons));
                    setEmployerHouseAssist(job.employerWillAssistInFindingResidence ?? undefined);
                    setResidenceLeaseOwner(String(job.personWhoContractsLease ?? ''));
                    setCommuteMethod(String(job.commuteMethod ?? ''));
                    setCommuteTime(String(job.commuteMinutes ?? ''));
                    setFurnitureProvided(job.furnitureAppliances ?? undefined);
                    setMovingTransExpenses(job.transportationMovingExpensesCovered ?? undefined);
                    setMovingTransExpensesAllowance(String(job.transportationMovingExpensesMaximumSupportAllowance ?? ''));
                    setMovingBagsExpenses(job.baggageDeliveryMovingExpensesCovered ?? undefined);
                    setMovingBagsExpensesAllowance(String(String(job.baggageDeliveryMovingExpensesMaximumSupportAllowance ?? '')));
                    setNaturalJapaneseLevel(String(job.japaneseConversationSkills ?? ''));
                    setJapaneseLevel(job.jlptLevel ?? '');
                    setPreferredGender(job.preferredGender ?? '');
                    setPreferredAgeMin(String(job.preferredAgeFrom));
                    setPreferredAgeMax(String(job.preferredAgeTo));
                    setPreferredCountries(job.preferredNationalities
                        ? job.preferredNationalities.map((preferredNationality: Nationality) => String(preferredNationality.id))
                        : []
                    );
                    setRequired(job.requiredConditions);
                    setPreferred(job.preferredConditions);
                    setCurrentNationalities(job.currentNationalities
                        ? job.currentNationalities.map((currentNationality: Nationality) => String(currentNationality.id))
                        : []
                    );
                    setSpecialAllowance(String(job.specialAllowanceUponHire));
                    setInterviewMethod(job.interviewMethod ?? '');
                    setCoverInterviewExpense(job.provideFullTransportationExpenses ?? undefined);
                    setInterviewAvailability(job.availableDaysAndTimesForInterviews);
                    setAdditionalInfo(job.otherSupplementaryInformationAboutThisJob);
                    setImages(job.images.map((image) => image.url));
                    const imageIdMapping: { [key: string]: number } = {};
                    for (const image of job.images) {
                        imageIdMapping[image.url] = image.id;
                    }
                    setImageIdMapping(imageIdMapping);
                } catch (e) {
                }
                dispatch({
                    type: ACTIONS.STOP_LOADING,
                });
            }
        })();
    }, [jobId]);

    useEffect(() => {
        setIndustryId(jobPostCategory.getIndustryIdByJobTypeId(jobTypeId));
    }, [jobTypeId, state.industries, state.jobTypes])

    return (
        <Page>
            <PageHeading>{t('job.create_job_post')}</PageHeading>
            <BasicInformationSection
                jobOfferTitle={jobOfferTitle}
                numberOfPositions={numberOfPositions}
                applicationDeadline={applicationDeadline}
                plannedEmploymentDate={plannedEmploymentDate}
                industry={industryId}
                jobType={jobTypeId}
                contractType={contractType}
                contractPeriod={contractPeriod}
                contractPeriodYears={contractPeriodYears}
                contractRenewal={contractRenewal}
                overseasResidentsAccepted={acceptOverseasResidents}
                postalCode={postalCode}
                prefecture={prefecture}
                city={city}
                town={town}
                address={locality}
                building={buildingEtc}
                description={description}
                onJobOfferTitleChange={setJobOfferTitle}
                onNumberOfPositionsChange={setNumberOfPositions}
                onApplicationDeadlineChange={setApplicationDeadline}
                onPlannedEmploymentDateChange={setPlannedEmploymentDate}
                onIndustryChange={setIndustryId}
                onJobTypeChange={setJobTypeId}
                onContractTypeChange={setContractType}
                onContractPeriodChange={setContractPeriod}
                onContractPeriodYearsChange={setContractPeriodYears}
                onContractRenewalChange={setContractRenewal}
                onOverseasResidentsAccepted={setAcceptOverseasResidents}
                onPostalCodeAddressIdChange={setPostalCodeAddressId}
                onPostalCodeChange={setPostalCode}
                onPrefectureChange={setPrefecture}
                onCityChange={setCity}
                onTownChange={setTown}
                onAddressChange={setLocality}
                onBuildingChange={setBuildingEtc}
                onDescriptionChange={setDescription}
            />
            <SalarySection
                months={months}
                salaryType={salaryType}
                annualizedWorkingHours={annualizedWorkingHours}
                hourlyDailySalary={hourlyDailySalary}
                hourlyDailySalaryUpperLimit={hourlyDailySalaryUpperLimit}
                grossSalary={grossSalary}
                grossSalaryUpperLimit={grossSalaryUpperLimit}
                estimatedTotalDeductions={estimatedTotalDeductions}
                expectedSocialInsurance={expectedSocial}
                dormitoryFee={dormFee}
                expectedTax={expectedTax}
                takeHomeSalary={salaryAfterTax}
                industry={industryId}
                nightShiftHours={nightShiftHours}
                nightShiftWage={nightShiftWage}
                nightWorkAllowance={nightWorkAllowance}
                salaryRaise={salaryRaise}
                bonus={bonus}
                bonusPayments={bonusPayments}
                bonusMonths={bonusMonths}
                onSalaryTypeChange={setSalaryType}
                onAnnualizedWorkingHoursChange={setAnnualizedWorkingHours}
                onHourlyDailySalaryChange={setHourlyDailySalary}
                onHourlyDailySalaryUpperLimitChange={SetHourlyDailySalaryUpperLimit}
                onGrossSalaryChange={setGrossSalary}
                onGrossSalaryUpperLimitChange={setGrossSalaryUpperLimit}
                onEstimatedTotalDeductionsChange={setEstimatedTotalDeductions}
                onExpectedSocialInsuranceChange={setExpectedSocial}
                onDormitoryFeeChange={setDormFee}
                onExpectedTaxChange={setExpectedTax}
                onTakeHomeSalaryChange={setSalaryAfterTax}
                onNightShiftHoursChange={setNightShiftHours}
                onNightShiftWageChange={setNightShiftWage}
                onNightWorkAllowanceChange={setNightWorkAllowance}
                onSalaryRaiseChange={setSalaryRaise}
                onBonusChange={setBonus}
                onBonusPaymentsChange={setBonusPayments}
                onBonusMonthsChange={setBonusMonths}
            />
            <WorkHoursSection
                annualizedWorkingHours={annualizedWorkingHours}
                hoursPerDay={hoursPerDay}
                minutesPerDay={minutesPerDay}
                shiftSystem={shiftBased}
                annualWorkHours={annualHours}
                monthlyWorkDays={monthlyDays}
                averageOvertime={averageOvertime}
                annualDaysOff={holidaysOff}
                onHoursPerDayChange={setHoursPerDay}
                onMinutesPerDayChange={setMinutesPerDay}
                onShiftSystemChange={setShiftBased}
                onAnnualWorkHoursChange={setAnnualHours}
                onMonthlyWorkDaysChange={setMonthlyDays}
                onAverageOvertimeChange={setAverageOvertime}
                onAnnualDaysOff={setHolidaysOff}
            />
            <HousingSection
                dormitoryAvailable={dormitoryAvailable}
                dormitoryType={dormitoryType}
                bedroomCount={bedroomCount}
                housingAssisted={employerHouseAssist}
                leaseOwner={residenceLeaseOwner}
                commuteMethod={commuteMethod}
                commuteTime={commuteTime}
                furnitureProvided={furnitureProvided}
                movingTransportationExpenses={movingTransExpenses}
                movingTransportationExpensesAllowance={movingTransExpensesAllowance}
                movingBaggageExpenses={movingBagsExpenses}
                movingBaggageExpensesAllowance={movingBagsExpensesAllowance}
                onDormitoryAvailableChange={setDormitoryAvailable}
                onDormitoryTypeChange={setDormitoryType}
                onBedroomCountChange={setBedroomCount}
                onHousingAssistedChange={setEmployerHouseAssist}
                onLeaseOwnerChange={setResidenceLeaseOwner}
                onCommuteMethodChange={setCommuteMethod}
                onCommuteTimeChange={setCommuteTime}
                onFurnitureProvidedChange={setFurnitureProvided}
                onMovingTransportationExpensesChange={setMovingTransExpenses}
                onMovingTransportationExpensesAllowanceChange={setMovingTransExpensesAllowance}
                onMovingBaggageExpensesChange={setMovingBagsExpenses}
                onMovingBaggageExpensesAllowanceChange={setMovingBagsExpensesAllowance}
            />
            <CandidateRequirementsSection
                nationalities={nationalityList}
                conversationSkill={naturalJapaneseLevel}
                jlptLevel={japaneseLevel}
                preferredGender={preferredGender}
                preferredNationalities={preferredCountries}
                preferredAgeMinimum={preferredAgeMin}
                preferredAgeMaximum={preferredAgeMax}
                requiredConditions={required}
                preferredConditions={preferred}
                onConversationSkillChange={setNaturalJapaneseLevel}
                onJLPTLevelChange={setJapaneseLevel}
                onPreferredGenderChange={setPreferredGender}
                onPreferredNationalitiesChange={setPreferredCountries}
                onPreferredAgeMinimumChange={setPreferredAgeMin}
                onPreferredAgeMaximumChange={setPreferredAgeMax}
                onRequiredConditionsChange={setRequired}
                onPreferredConditionsChange={setPreferred}
            />
            <OtherSection
                nationalities={nationalityList}
                currentNationalities={currentNationalities}
                specialAllowance={specialAllowance}
                interviewMethod={interviewMethod}
                interviewExpensesProvided={coverInterviewExpense}
                interviewAvailability={interviewAvailability}
                additionalInfo={additionalInfo}
                images={images}
                onCurrentNationalitiesChange={setCurrentNationalities}
                onSpecialAllowanceChange={setSpecialAllowance}
                onInterviewMethodChange={setInterviewMethod}
                onInterviewExpensesProvidedChange={setCoverInterviewExpense}
                onInterviewAvailabilityChange={setInterviewAvailability}
                onAdditionalInfoChange={setAdditionalInfo}
                onImagesChange={setImages}
            />
            {jobState !== 'archived' && (
                <ActionBar>
                    <Button
                        onClick={() => registerJob(false)}
                        disabled={AreThereMissingRequiredFields()}
                    >
                        {t(jobState === 'published' ? 'core.save' : 'job.publish')}
                    </Button>
                    {jobState === 'draft' && (
                        <Button variant="secondary" onClick={() => registerJob(true)}>
                            {t('job.save_as_draft')}
                        </Button>
                    )}
                </ActionBar>
            )}
        </Page>
    );
};

export default JobFormPage;