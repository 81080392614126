export const COMMUTE_METHOD_ITEMS = [
    {
        label: 'job.walk',
        value: 'walk',
    },
    {
        label: 'job.bicycle',
        value: 'bicycle',
    },
    {
        label: 'job.public_transport',
        value: 'public_transport',
    },
    {
        label: 'job.automobile',
        value: 'automobile',
    },
];


export const CONVERSATION_SKILLS_OPTIONS = [
    {
        label: 'job.basic_conversation_skills',
        value: '0',
    },
    {
        label: 'job.daily_conversation_skills',
        value: '1',
    },
    {
        label: 'job.fluent_conversation_skills',
        value: '2',
    },
];

export const GENDER_ITEMS = [
    {
        label: 'core.male',
        value: 'male',
    },
    {
        label: 'core.female',
        value: 'female',
    }
];

export const LANGUAGE_LEVEL_ITEMS = [
    {
        label: 'core.jlpt_levels.n1',
        value: '1',
    },
    {
        label: 'core.jlpt_levels.n2',
        value: '2',
    },
    {
        label: 'core.jlpt_levels.n3',
        value: '3',
    },
    {
        label: 'core.jlpt_levels.n4',
        value: '4',
    },
    {
        label: 'core.jlpt_levels.n5',
        value: '5',
    },
]

export const MONTH_ITEMS = [
    {
        label: 'calendar.month.january',
        value: 'january',
    },
    {
        label: 'calendar.month.february',
        value: 'february',
    },
    {
        label: 'calendar.month.march',
        value: 'march',
    },
    {
        label: 'calendar.month.april',
        value: 'april',
    },
    {
        label: 'calendar.month.may',
        value: 'may',
    },
    {
        label: 'calendar.month.june',
        value: 'june',
    },
    {
        label: 'calendar.month.july',
        value: 'july',
    },
    {
        label: 'calendar.month.august',
        value: 'august',
    },
    {
        label: 'calendar.month.september',
        value: 'september',
    },
    {
        label: 'calendar.month.october',
        value: 'october',
    },
    {
        label: 'calendar.month.november',
        value: 'november',
    },
    {
        label: 'calendar.month.december',
        value: 'december',
    },
];

export const NATIONALITY_ITEMS = [
        {
            label: 'country.VN',
            value: 'VN',
        },
        {
            label: 'country.NP',
            value: 'NP',
        },
        {
            label: 'country.ID',
            value: 'ID',
        },
        {
            label: 'country.PH',
            value: 'PH',
        },
        {
            label: 'country.KH',
            value: 'KH',
        },
        {
            label: 'country.MM',
            value: 'MM',
        },
        {
            label: 'country.TH',
            value: 'TH',
        },
        {
            label: 'country.MN',
            value: 'MN',
        },
        {
            label: 'country.BD',
            value: 'BD',
        },
        {
            label: 'country.PK',
            value: 'PK',
        },
        {
            label: 'country.LK',
            value: 'LK',
        },
        {
            label: 'country.UZ',
            value: 'UZ',
        },
        {
            label: 'country.other',
            value: 'other',
        },
    ]
;