import { FunctionComponent } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.min.css';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface DatePickerProps {
    selected?: Date;
    customInput?: React.ReactNode;
    error?: boolean;
    onChange?: (date: Date) => void;
}

interface LocalReactDatePickerProps {
    error?: boolean;
}

const LocalReactDatePicker = styled(ReactDatePicker)<LocalReactDatePickerProps>`
    background-color: ${props => props.error ? '#FFF0F0' : ''};
    border: ${props => props.error ? '1px solid #F93232' : ''};
`;


const DatePicker: FunctionComponent<DatePickerProps> = ({
    selected,
    customInput,
    error,
    onChange
}) => {
    const { t } = useTranslation('translation', { keyPrefix: 'core' });
    const months = [
      t('months.january'),
      t('months.february'),
      t('months.march'),
      t('months.april'),
      t('months.may'),
      t('months.june'),
      t('months.july'),
      t('months.august'),
      t('months.september'),
      t('months.october'),
      t('months.november'),
      t('months.december'),
  ];
  const days = [
      t('days.sunday'),
      t('days.monday'),
      t('days.tuesday'),
      t('days.wednesday'),
      t('days.thursday'),
      t('days.friday'),
      t('days.saturday'),
  ];
  const locale = {
      localize: {
          day: (n: number) => days[n],
          month: (n: number) => months[n],
          ordinalNumber: () => {},
          era: () => {},
          quarter: () => {},
          dayPeriod: () => {},
      },
      formatLong: {
          date: () => 'yyyy/mm/dd',
          time: () => {},
          dateTime: () => {},
      },
  };

    return (
        <LocalReactDatePicker 
            locale={locale}
            selected={selected}
            error={error}
            onChange={date => { 
                date instanceof Date && onChange?.(date);
            }}
            customInput={customInput}
        />
    );
};

export default DatePicker;