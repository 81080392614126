import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components'

import CandidateListTable from './CandidateListTable';
import SearchPanel from './SearchPanel';
import { getCandidates } from '../../apis/candidate';
import Page from '../../../core/components/Page';
import ActionBar from '../../../core/components/ActionBar';
import Button from '../../../core/components/Button';
import ACTIONS from '../../../core/constants/actions';
import { useReducerContext } from '../../../core/contexts/ReducerContext';
import { Candidate } from '../../types/api';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { DESKTOP_DEVICE, MOBILE_DEVICE } from '../../../core/constants/styles';
import { useMediaQuery } from 'react-responsive';
import Snackbar from '../../../core/components/Snackbar';
import { ReactComponent as PlusIcon } from '../../../assets/icon-plus.svg';

interface CandidateListPageProps {
}

const LocalPage = styled(Page)`
  padding-top: 0;
  
  > * {
    width: 100%;
  }
`;

const TopContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;
  background-color: #FFFFFF;
  border-bottom: 1px solid #E5E5E5;

  @media ${MOBILE_DEVICE} {
    flex-direction: column;
    gap: 10px;
  }
`;

const ActionButton = styled(Button)`
  position: absolute;
  right: 20px;

  @media ${MOBILE_DEVICE} {
    position: static;
    max-width: 400px;
  }
`;

const Content = styled.div`
  flex: 1;
  width: 100%;
  padding: 10px 17px 100px 17px;
  overflow: auto;
  
  @media ${MOBILE_DEVICE} {
    padding: 0;
  }
`;

const LocalSearchPanel = styled(SearchPanel)`
  padding: 0;
  max-width: 400px;
  border: 0;
`;

const CandidateListPage: FunctionComponent<CandidateListPageProps> = ({
}) => {
    const context = useReducerContext();
    const [candidates, setCandidates] = useState<Candidate[]>([]);
    const [keyword, setKeyword] = useState('');
    const [gender, setGender] = useState('');
    const [visa, setVisa] = useState('');
    const [naturalJapaneseLevel, setNaturalJapaneseLevel] = useState('');
    const [japaneseLevel, setJapaneseLevel] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const { jobId } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const isDesktopDevice = useMediaQuery({
       query: DESKTOP_DEVICE,
    });

    const handleSearch = async () => {
        context.dispatch({
            type: ACTIONS.START_LOADING,
            payload: {
                message: t('candidate.fetching_candidates'),
            },
        });
        try {
            const response = await getCandidates({ partnerId: context.state.company?.partner?.id });
            const candidates = response
                .filter((item) => !keyword
                    || `${item.enFirstName}${item.enMiddleName ? ` ${item.enMiddleName}` : ''} ${item.enLastName}`.includes(keyword)
                );
            setCandidates(candidates);
        } catch (e) {
            // TODO: error handling
        }
        context.dispatch({
            type: ACTIONS.STOP_LOADING,
        });
    };

    const handleItemClick = (index: number) => {
        if (jobId) {
            if (isDesktopDevice) {
                setSelectedIndex(index);
            } else {
                navigate(`/jobs/${jobId}/propose/${candidates[index].id}`);
            }
        } else {
            navigate(`/candidates/${candidates[index].id}`);
        }
    };

    const handlePropose = () => {
        navigate(`/jobs/${jobId}/propose/${candidates[selectedIndex].id}`);
    };

    const handleCreate = () => {
        navigate('/candidates/new');
    };

    useEffect( () => {
        handleSearch();
    }, [])

    return (
        <LocalPage>
            <TopContainer>
                <LocalSearchPanel
                    keyword={keyword}
                    gender={gender}
                    visaStatus={visa}
                    conversationSkills={naturalJapaneseLevel}
                    jlptLevel={japaneseLevel}
                    onKeywordChange={setKeyword}
                    onGenderChange={setGender}
                    onVisaStatusChange={setVisa}
                    onConversationSkillsChange={setNaturalJapaneseLevel}
                    onJlptLevelChange={setJapaneseLevel}
                    onSearch={handleSearch}
                    placeholder={t('candidate.candidate_name')}
                />
                {!jobId && (
                    isDesktopDevice
                    ?   <ActionButton
                            variant="quart-add"
                            onClick={handleCreate}
                        >
                            {t('candidate.add_candidate')}
                        </ActionButton>
                    :   <ActionButton
                            variant="secondary"
                            onClick={handleCreate}
                        >
                            <PlusIcon style={{ marginRight: 10 }}/>
                            {t('candidate.add_candidate')}
                        </ActionButton>
                )}
            </TopContainer>
            <Content>
                <CandidateListTable
                    selectable={!!jobId}
                    candidates={candidates}
                    selectedIndex={selectedIndex}
                    onItemClick={handleItemClick}
                />
            </Content>
            {(jobId && isDesktopDevice) && (
                <ActionBar>
                    <Button disabled={selectedIndex < 0} onClick={handlePropose}>
                        {t('proposal.propose_selected_candidate')}
                    </Button>
                    <Button variant="secondary" onClick={handleCreate}>
                        {t('candidate.create_new_candidate')}
                    </Button>
                </ActionBar>
            )}
            {!!jobId && (
              <Snackbar>
                  Select your candidate to propose
              </Snackbar>
            )}
        </LocalPage>
    );
};

export default CandidateListPage;
