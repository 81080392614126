import {
    ChangeEvent,
    FunctionComponent,
    InputHTMLAttributes,
    useEffect,
    useState
} from 'react';
import { useTranslation } from 'react-i18next';

import Column from './Column';
import Field from './Field';
import Label from './Label';
import Row from './Row';
import Text from './Text';
import TextInput from './TextInput';

import { isValidPostalCode } from '../utils';
import { getAddressInformation } from '../apis/address';
import styled from 'styled-components';
import { AxiosError } from 'axios';

interface PostalCodeInputProps extends InputHTMLAttributes<HTMLInputElement> {
    editable?: boolean;
    prefecture?: string;
    city?: string;
    town?: string;
    error?: boolean;
    onTextChange?: (value: string) => void;
    onPrefectureChange?: (prefecture: string) => void;
    onCityChange?: (city: string) => void;
    onTownChange?: (town: string) => void;
    onPostalCodeAddressIdChange?: (id: string) => void;
}

enum AutoFillState {
    Inactive,
    Success,
    Invalid,
    SystemError
}

const LocalRow = styled(Row)`
    align-items: center;
`

const SuccessText = styled(Text)`
    color: #40BF6E
`;

const ErrorText = styled(Text)`
    color: #E93232
`;

const PostalCodeInput: FunctionComponent<PostalCodeInputProps> = ({
    editable,
    prefecture,
    city,
    town,
    error,
    onTextChange,
    onPrefectureChange,
    onCityChange,
    onTownChange,
    onPostalCodeAddressIdChange,
    ...props
}) => {
    const [autoFillStatus, setAutoFillStatus] = useState<AutoFillState>(AutoFillState.Inactive);
    const [didErrorOccur, setDidErrorOccur] = useState<boolean>(false);
    const { t, i18n } = useTranslation();
    const POSTAL_CODE_MAX = 7;
    const onBlur = () => {
        setAutoFillStatus(AutoFillState.Inactive);
        setDidErrorOccur(false);
    }
    const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const postalCode = event.target.value;
        onTextChange && onTextChange(postalCode);
        
        if (postalCode.length < POSTAL_CODE_MAX) {
            setAutoFillStatus(AutoFillState.Inactive);
            setDidErrorOccur(false);
            onPrefectureChange && onPrefectureChange('');
            onCityChange && onCityChange('');
            onTownChange && onTownChange('');
            onPostalCodeAddressIdChange && onPostalCodeAddressIdChange('');
        }
    };

    useEffect(() => {
        (async () => {
            
            // This checks if an update is made by the initial data loading or the user
            if (prefecture)
                return;

            if (isValidPostalCode(props.value as string)) {
                try {
                    const response = await getAddressInformation(
                        props.value as string,
                    );

                    const isLangJapanese = i18n.language === 'ja';
                    const prefecture = isLangJapanese? response.jaPrefecture : response.enPrefecture;
                    const city       = isLangJapanese? response.jaCity : response.enCity;
                    const town       = isLangJapanese? response.jaTown : response.enTown;    
                    const id = response.id;

                    onPrefectureChange && onPrefectureChange(prefecture);
                    onCityChange && onCityChange(city);
                    onTownChange && onTownChange(town);
                    onPostalCodeAddressIdChange && onPostalCodeAddressIdChange(id);

                    setAutoFillStatus(AutoFillState.Success);

                } catch (e) {
                    if (e instanceof AxiosError) {
                        if (e.response && e.response.status === 404) 
                            setAutoFillStatus(AutoFillState.Invalid);
                        else 
                            setAutoFillStatus(AutoFillState.SystemError);
                        
                        setDidErrorOccur(true);
                    }
                }
            }
        })();
    }, [props.value])

    const AutoFillMessage = ({ status } : { status: AutoFillState }) => {
        if (status === AutoFillState.Success)
            return (
                <LocalRow>
                    <img alt="check" src="/images/icon-check.svg" />
                    <SuccessText>{t('job.get_address_from_postal_code_sucess')}</SuccessText>
                </LocalRow>
            )

        if (status === AutoFillState.Invalid)
            return (
                <LocalRow>
                    <img alt="cross" src="/images/icon-cross.svg" />
                    <ErrorText>{t('job.get_address_from_postal_code_invalid')}</ErrorText>
                </LocalRow>
            )

        if (status === AutoFillState.SystemError)
            return (
                <LocalRow>
                    <img alt="cross" src="/images/icon-cross.svg" />
                    <ErrorText>{t('job.get_address_from_postal_code_system_error')}</ErrorText>
                </LocalRow>
            )

        return null
    }


    return (
        <>
            <Row>
                <Field>
                    <Label required>{t('core.postal_code')}</Label>
                    <TextInput
                        style={ didErrorOccur? { width: '170px', borderColor : "#E93232" } : { width: '170px'} }
                        placeholder="1080075"
                        onChange={handleChange}
                        onBlur={onBlur}
                        error={error}
                        maxLength={POSTAL_CODE_MAX}
                        {...props}
                    />
                    <AutoFillMessage status={autoFillStatus}/>
                    {   error &&
                        <Text error={true} style={{ fontSize: 12 }}>{t('core.input_prompt.text_field')}</Text>
                    }
                </Field>
            </Row>

            <Row>
                <Field>
                    <Label>{t('core.prefecture')}</Label>
                    <TextInput
                        style={{ width: '170px' }}
                        disabled={!editable}
                        value={prefecture}
                        onTextChange={onPrefectureChange}
                    />
                </Field>
                <Field style={{ marginLeft: '30px' }}>
                    <Label>{t('core.city')}</Label>
                    <TextInput
                        style={{ width: '170px' }}
                        disabled={!editable}
                        value={city}
                        onTextChange={onCityChange}
                    />
                </Field>
                <Field style={{ marginLeft: '30px' }}>
                    <Label>{t('core.town_name')}</Label>
                    <TextInput
                        style={{ width: '170px' }}
                        disabled={!editable}
                        value={town}
                        onTextChange={onTownChange}
                    />
                </Field>
            </Row>
        </>
    );
};

export default PostalCodeInput;