import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import AdditionalInformationSection from './AdditionalInformationSection';
import BasicInformationSection from './BasicInformationSection';
import ContactInformationSection from './ContactInformationSection';
import {
    createCandidate,
    createCandidateAdditionalInformation,
    createCandidateImage, deleteCandidateAdditionalInformation,
    deleteCandidateImage,
    getAdditionalInformationTypes,
    getCandidate,
    updateCandidate
} from '../../apis/candidate';
import { AdditionalInformationType } from '../../types/api';
import ActionBar from '../../../core/components/ActionBar';
import Button from '../../../core/components/Button';
import Page from '../../../core/components/Page';
import PageHeading from '../../../core/components/PageHeading';
import ACTIONS from '../../../core/constants/actions';
import { useReducerContext } from '../../../core/contexts/ReducerContext';
import { MOBILE_DEVICE } from '../../../core/constants/styles';
import SkillsSection from './SkillsSection';
import moment from 'moment';
import { isValidEmailAddress } from '../../../core/utils';
import { useNationalities } from '../../../core/hooks';

interface CandidateFormPageProps {
}

const LocalPage = styled(Page)`
  @media ${MOBILE_DEVICE} {
    padding-top: 20px;
  }
`;

const LocalPageHeading = styled(PageHeading)`
  @media ${MOBILE_DEVICE} {
    padding: 0 20px;
  }
`;

const CandidateFormPage: FunctionComponent<CandidateFormPageProps> = () => {
    const { state, dispatch } = useReducerContext();
    const { nationalityList } = useNationalities();
    const [additionalInformationTypes, setAdditionalInformationTypes] = useState<AdditionalInformationType[]>([]);
    const [profilePhotoId, setProfilePhotoId] = useState<number|undefined>();
    const [profilePhoto, setProfilePhoto] = useState<File|string>('');
    const [name, setName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState('');
    const [nameJP, setNameJP] = useState('');
    const [middleNameJP, setMiddleNameJP] = useState('');
    const [lastNameJP, setLastNameJP] = useState('');
    const [age, setAge] = useState('');
    const [gender, setGender] = useState('');
    const [nationality, setNationality] = useState('');
    const [visa, setVisa] = useState('');
    const [location, setLocation] = useState('');
    const [naturalJapaneseLevel, setNatureJapaneseLevel] = useState('');
    const [japaneseLevel, setJapaneseLevel] = useState('');
    const [availabilityTimes, setAvailabilityTimes] = useState('');
    const [nearestPossibleDate, setNearestPossibleDate] = useState<Date>();
    const [jobTypeId, setJobTypeId] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [lineId, setLineId] = useState('');
    const [facebook, setFacebook] = useState('');
    const [uploads, setUploads] = useState(new Array(3).fill({
        type: '',
        file: undefined,
    }));
    const { candidateId, jobId } = useParams();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const invalid = !profilePhoto
        || !name
        || !lastName
        || !age
        || !gender
        || !nationality
        || !visa
        || !location
        || !naturalJapaneseLevel
        || !japaneseLevel
        || !availabilityTimes
        || !nearestPossibleDate
        || !jobTypeId
        || !isValidEmailAddress(contactEmail);

    const createOrUpdateCandidate = async () => {
        dispatch({
            type: ACTIONS.START_LOADING,
            payload: {
                message: t(`candidate.${candidateId ? 'updat' : 'creat'}ing_candidate`),
            },
        });
        const data = {
            enFirstName: name,
            enMiddleName: middleName,
            enLastName: lastName,
            jaFirstName: nameJP,
            jaMiddleName: middleNameJP,
            jaLastName: lastNameJP,
            age: Number(age),
            gender,
            nationalityId: Number(nationality),
            visaStatus: visa,
            currentAddress: location,
            japaneseConversationSkills: naturalJapaneseLevel,
            jlptLevel: japaneseLevel,
            currentWorkHours: availabilityTimes,
            nearestPossibleDate: nearestPossibleDate? 
                moment(nearestPossibleDate).format('YYYY-MM-DD') : undefined,
            jobTypeId,
            email: contactEmail,
            lineId,
            facebookProfileLink: facebook,
            additionalInformationAttributes: [],
        };
        try {
            const targetCandidateId = state.isCandidate ? state.candidate!.id : candidateId;
            if (targetCandidateId) {
                await updateCandidate(targetCandidateId, data);
                if (typeof(profilePhoto) !== 'string') {
                    if (profilePhotoId) {
                        await deleteCandidateImage(profilePhotoId);
                    }
                    await createCandidateImage(targetCandidateId, profilePhoto);
                }
                for (const upload of uploads) {
                    if (upload.id) {
                        if (upload.removed || (upload.file && typeof(upload.file) !== 'string')) {
                            await deleteCandidateAdditionalInformation(upload.id);
                        }
                    }
                    if (upload.type && upload.file) {
                        if (typeof(upload.file) !== 'string') {
                            await createCandidateAdditionalInformation(
                                targetCandidateId,
                                upload.type,
                                upload.file,
                            );
                        }
                    }
                }
                if (state.isCandidate) {
                    const candidate = await getCandidate(targetCandidateId);
                    dispatch({
                        type: ACTIONS.UPDATE_CANDIDATE,
                        payload: {
                            candidate,
                        },
                    });
                }
            } else {
                const candidate = await createCandidate(data);
                if (typeof(profilePhoto) !== 'string') {
                    await createCandidateImage(candidate.id, profilePhoto);
                }
                for (const upload of uploads) {
                    if (upload.type && upload.file && !upload.removed) {
                        if (typeof(upload.file) !== 'string') {
                            await createCandidateAdditionalInformation(
                                candidate.id,
                                upload.type,
                                upload.file,
                            );
                        }
                    }
                }
            }
            dispatch({
                type: ACTIONS.SET_PROMPT,
                payload: {
                    type: 'success',
                    message: t(`candidate.candidate_${candidateId ? 'update' : 'registration'}_success`),
                },
            });
            navigate(state.isCandidate ? '/profile' : '/candidates');
        } catch (e) {
            dispatch({
                type: ACTIONS.SET_PROMPT,
                payload: {
                    type: 'warning',
                    message: t(`candidate.candidate_${candidateId ? 'update' : 'registration'}_failed`),
                },
            });
        }
        dispatch({
            type: ACTIONS.STOP_LOADING,
        })
    };

    const handleSubmitClick = () => {
        createOrUpdateCandidate();
    };

    const handleCancelClick = () => {
        navigate(-1);
    };

    useEffect(() => {
        (async () => {
            try {
                const additionalInformationTypes = await getAdditionalInformationTypes();
                setAdditionalInformationTypes(additionalInformationTypes);
            } catch (e) {
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            dispatch({
                type: ACTIONS.START_LOADING,
                payload: {
                    message: t('candidate.fetching_candidate_details'),
                },
            });
            try {
                const response = state.isCandidate
                    ? state.candidate!
                    : await getCandidate(candidateId!);
                if (response.image) {
                    setProfilePhotoId(response.image.id);
                    setProfilePhoto(response.image.url);
                }
                setName(response.enFirstName);
                setMiddleName(response.enMiddleName);
                setLastName(response.enLastName);
                setNameJP(response.jaFirstName);
                setMiddleNameJP(response.jaMiddleName);
                setLastNameJP(response.jaLastName);
                setAge(response.age? String(response.age) : '');
                setGender(response.gender);
                setNationality(response.nationalityId? String(response.nationalityId) : '');
                setVisa(response.visaStatus);
                setLocation(response.currentAddress);
                setNatureJapaneseLevel(response.japaneseConversationSkills);
                setJapaneseLevel(response.jlptLevel? String(response.jlptLevel) : '');
                setAvailabilityTimes(response.currentWorkHours);
                setNearestPossibleDate(response.nearestPossibleDate 
                    ? new Date(response.nearestPossibleDate)
                    : undefined
                );
                setJobTypeId(response.jobTypeId? String(response.jobTypeId) : '');
                setContactEmail(response.email);
                setLineId(response.lineId);
                setFacebook(response.facebookProfileLink);
                if (response.additionalInformations.length) {
                    setUploads(response.additionalInformations.map((information) => ({
                        id: information.id,
                        type: String(information.typeId),
                        file: information.url,
                    })));
                }
            } catch (e) {
                // TODO: error handling
            }
            dispatch({
                type: ACTIONS.STOP_LOADING,
            });
        })()
    }, [candidateId])

    return (
        <LocalPage>
            <LocalPageHeading>
                {(state.isLoggedIn && !state.isEmployer && !state.isPartner)
                    ? t('core.profile')
                    : t(`candidate.${candidateId ? 'edit' : 'create'}_candidate`)
                }
            </LocalPageHeading>
            <BasicInformationSection
                nationalities={nationalityList}
                avatar={profilePhoto}
                firstNameEn={name}
                middleNameEn={middleName}
                lastNameEn={lastName}
                firstNameJp={nameJP}
                middleNameJp={middleNameJP}
                lastNameJp={lastNameJP}
                age={age}
                gender={gender}
                nationality={nationality}
                visaStatus={visa}
                address={location}
                conversationSkills={naturalJapaneseLevel}
                jlptLevel={japaneseLevel}
                workHours={availabilityTimes}
                nearestPossibleDate={nearestPossibleDate}
                onAvatarChange={setProfilePhoto}
                onFirstNameEnChange={setName}
                onMiddleNameEnChange={setMiddleName}
                onLastNameEnChange={setLastName}
                onFirstNameJpChange={setNameJP}
                onMiddleNameJpChange={setMiddleNameJP}
                onLastNameJpChange={setLastNameJP}
                onAgeChange={setAge}
                onGenderChange={setGender}
                onNationalityChange={setNationality}
                onVisaStatusChange={setVisa}
                onAddressChange={setLocation}
                onConversationSkillsChange={setNatureJapaneseLevel}
                onJlptLevelChange={setJapaneseLevel}
                onWorkHoursChange={setAvailabilityTimes}
                onNearestPossibleDateChange={setNearestPossibleDate}
            />
            <SkillsSection
                jobType={jobTypeId}
                onJobTypeChange={setJobTypeId}
            />
            <ContactInformationSection
                email={contactEmail}
                lineId={lineId}
                facebookLink={facebook}
                onEmailChange={setContactEmail}
                onLineIdChange={setLineId}
                onFacebookLinkChange={setFacebook}
            />
            <AdditionalInformationSection
                types={additionalInformationTypes}
                uploads={uploads}
                onUploadsChange={setUploads}
            />
            <ActionBar>
                <Button
                    disabled={invalid}
                    onClick={handleSubmitClick}
                >
                    {t('core.save')}
                </Button>
                <Button variant="secondary" onClick={handleCancelClick}>
                    {t('core.cancel')}
                </Button>
            </ActionBar>
        </LocalPage>
    );
};

export default CandidateFormPage;