import { FunctionComponent, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import sanitizeHtml from 'sanitize-html';
import styled from 'styled-components';

import Header from './core/components/Header';
import LoadingModal from './core/components/LoadingModal';
import Snackbar from './core/components/Snackbar';
import { useReducerContext } from './core/contexts/ReducerContext';
import CoreRoutes from './core/routes';
import { wait } from './core/utils';
import ACTIONS from './core/constants/actions';
import ConfirmationModal from './core/components/ConfirmationModal';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Content = styled.div`
  flex: 1;
  overflow: auto;
`;

const App: FunctionComponent = () => {
    const { state, dispatch } = useReducerContext();
    const { i18n } = useTranslation();

    const stopConfirmation = () => {
        dispatch({
            type: ACTIONS.STOP_CONFIRMATION,
        });
    };

    const closePrompt = () => {
        dispatch({
            type: ACTIONS.CLEAR_PROMPT,
        });
    };

    useEffect(() => {
        (async () => {
            if (state.isPrompting) {
                await wait(state.promptingTimeout);
                dispatch({
                    type: ACTIONS.CLEAR_PROMPT,
                });
            }
        })();
    }, [state.isPrompting]);

    useEffect(() => {
        (async () => {
            moment.locale(i18n.language);
            try {
                await import(`moment/locale/${i18n.language}`);
            } catch (e) {
            }
        })();
    }, [i18n.language])

    return (
        <Container>
            <Header />
            <Content>
                <CoreRoutes />
            </Content>
            <LoadingModal />
            <ConfirmationModal
                open={state.isConfirmation}
                message={state.confirmationMessage}
                onCancel={stopConfirmation}
                onConfirm={() => {
                    stopConfirmation();
                    state.confirmationCallback();
                }}
            />
            {state.isPrompting && (
                <Snackbar
                    type={state.promptingType}
                    onClose={closePrompt}
                >
                    <div
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(state.promptingMessage)
                        }}
                    />
                </Snackbar>
            )}
        </Container>
    );
};

export default App;