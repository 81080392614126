import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Modal from '../../core/components/Modal';
import CategoryHeading from '../../core/components/CategoryHeading';
import PageHeading from '../../core/components/PageHeading';
import Text from '../../core/components/Text';

interface ProfileMissingModalProps {
    open?: boolean;
}

const LocalPageHeading = styled(PageHeading)`
  font-size: 20px;
  line-height: 29px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 280px;
  border-radius: 5px;
  background: #FFFFFF;
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  padding: 20px;
`;

const Button = styled(CategoryHeading)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-height: 50px;
  min-height: 50px;
  color: #FF730D;
  border-top: 1px solid #E5E5E5;
  cursor: pointer;
`;

const ProfileMissingModal: FunctionComponent<ProfileMissingModalProps> = ({
    open,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Modal open={open}>
            <Container>
                <TopContainer>
                    <LocalPageHeading>
                        {t('job.profile_missing_title')}
                    </LocalPageHeading>
                    <Text>
                        {t('job.profile_missing_description')}
                    </Text>
                </TopContainer>
                <Button onClick={() => navigate('/profile/edit')}>
                    {t('job.enter_my_profile')}
                </Button>
            </Container>
        </Modal>
    );
};

export default ProfileMissingModal;