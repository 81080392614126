import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import SearchPanel from './SearchPanel';
import { createScouts, getScouts } from '../../apis';
import CandidateListTable from '../../components/CandidateListTable';
import CandidateOverview from '../../components/CandidateOverview';
import SelectedCandidateListTable from '../../components/SelectedCandidateListTable';
import { getCandidates } from '../../../candidate/apis/candidate';
import { Candidate } from '../../../candidate/types/api';
import Button from '../../../core/components/Button';
import CategoryHeading from '../../../core/components/CategoryHeading';
import Column from '../../../core/components/Column';
import Page from '../../../core/components/Page';
import PageHeading from '../../../core/components/PageHeading';
import Row from '../../../core/components/Row';
import Text from '../../../core/components/Text';
import TextLink from '../../../core/components/TextLink';
import ACTIONS from '../../../core/constants/actions';
import { useReducerContext } from '../../../core/contexts/ReducerContext';
import { getJob } from '../../../job/apis/job';
import { Job } from '../../../job/types/api';
import { decodeHTMLEntities } from '../../../core/utils';

interface CandidateListPageProps {
}

const LocalPage = styled(Page)`
  padding: 0;
  max-height: 100%;
  
  > * {
    width: 100%;
    height: 100%;
    gap: 10px;
    overflow: hidden;
  }
`;

const LocalPageHeading = styled(PageHeading)`
  font-size: 20px;
  line-height: 29px;
`;

const Container = styled.div`
  padding: 20px 20px 0 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  margin-bottom: 5px;
`;

const BottomContainer = styled.div`
  flex: 1 1 0;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  gap: 20px;
  overflow: hidden;
`;

const RightContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const CandidateListPage: FunctionComponent<CandidateListPageProps> = () => {
    const { state, dispatch } = useReducerContext();
    const [job, setJob] = useState<Job|undefined>();
    const [scoutedCandidateIDs, setScoutedCandidateIDs] = useState<number[]>([]);
    const [candidates, setCandidates] = useState<Candidate[]>([]);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [selectedIndexes, setSelectedIndexes] = useState<number[]>([]);
    const [confirmation, setConfirmation] = useState(false);
    const [targetIndustryIDs, setTargetIndustryIDs] = useState<number[]>([]);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { jobId } = useParams();
    const specialIndustryIds = [3, 4, 5]; //For those industries, we have to filter candidates on a special rule
    const selectedCandidates = candidates.filter((candidate, index) => selectedIndexes.includes(index));
    const remainingScouts = Math.min(
        job?.remainingScoutsNum ?? 0,
        state.company?.employer?.remainingScoutsNum ?? 0,
    );

    const handleSearch = async (filters = {}, candidateIDsToExclude = scoutedCandidateIDs) => {
        dispatch({
            type: ACTIONS.START_LOADING,
            payload: {
                message: t('candidate.fetching_candidates'),
            },
        });
        try {
            const response = await getCandidates(filters);
            const candidates = response.filter((c) => {
                return c.receiveScout && !candidateIDsToExclude.includes(c.id);
            });
            setCandidates(candidates);
            setSelectedIndex(-1);
            setSelectedIndexes([]);
        } catch (e) {
            // TODO: error handling
        }
        dispatch({
            type: ACTIONS.STOP_LOADING,
        });
    };

    const handleSubmit = async () => {
        dispatch({
            type: ACTIONS.START_LOADING,
            payload: {
                message: t('Sending scouts'),
            },
        });
        try {
            await createScouts(jobId!, selectedCandidates.map(candidate => candidate.id));
            dispatch({
                type: ACTIONS.SET_PROMPT,
                payload: {
                    type: 'success',
                    message: t('scout.scouts_sent'),
                },
            });
            navigate('/scouts');
        } catch (e) {
        }
        dispatch({
            type: ACTIONS.STOP_LOADING,
        });
    };

    useEffect( () => {
        (async () => {
            dispatch({
                type: ACTIONS.START_LOADING,
            });
            try {
                const job = await getJob(jobId!);
                setJob(job);

                const scouts = await getScouts({ employerId: state.company?.employer?.id });
                const scoutedIDs = scouts
                    .filter(scout => scout.jobPostId.toString() === jobId)
                    .map(scout => scout.candidateId)
                const industryIds = specialIndustryIds.includes(job.jobType.industryId)? 
                    specialIndustryIds : [job.jobType.industryId];
                
                setScoutedCandidateIDs(scoutedIDs);
                setTargetIndustryIDs(industryIds);

                await handleSearch({ industryIds }, scoutedIDs);
            } catch (e) {
                // TODO: error handling
            }
            dispatch({
                type: ACTIONS.STOP_LOADING,
            });
        })();
    }, [])

    return (
        <LocalPage>
            <Container>
                <TopContainer>
                    <Column style={{ gap: 5, marginTop: 10 }}>
                        <LocalPageHeading>{t(confirmation? 'scout.send_scout_header' : 'scout.select_candidates_to_scout')}</LocalPageHeading>
                        <Row style={{ gap: 10 }} center>
                            <Row center>
                                <Text><pre>{t('scout.selected_job')}</pre></Text>
                                <TextLink href={`/jobs/${jobId}`} target="_blank">
                                    {
                                        decodeHTMLEntities(job?.translations.find(t => t.language.code === i18n.language)?.name)
                                        ?? job?.name
                                    }
                                </TextLink>
                            </Row>
                            <Text variant="tiny">{t('scout.only_qualified_candidates')}</Text>
                        </Row>
                    </Column>
                    {!confirmation && (
                        <Row style={{ gap: 20 }} center>
                            <Text>
                                {t('scout.selecting_count', {
                                    number: selectedIndexes?.length ?? 0,
                                    total: remainingScouts,
                                })}
                            </Text>
                            <Button
                                variant="secondary"
                                onClick={() => navigate('/scouts/new')}
                            >
                                {t('scout.back')}
                            </Button>
                            <Button
                                style={{ minWidth: 150 }}
                                disabled={!selectedIndexes.length || selectedIndexes.length > remainingScouts}
                                onClick={() => setConfirmation(true)}
                            >
                                {t('scout.proceed')}
                            </Button>
                        </Row>
                    )}
                </TopContainer>
                {confirmation
                    ? (
                        <>
                            <CategoryHeading>
                                {t('scout.selected_count', {
                                    number: selectedIndexes.length,
                                    total: remainingScouts,
                                })}
                            </CategoryHeading>
                            <Text variant="tiny">{t('scout.scout_not_sent_yet')}</Text>
                            <SelectedCandidateListTable
                                style={{ width: 500 }}
                                candidates={selectedCandidates}
                            />
                            <Row style={{ gap: 20, marginTop: 20 }} center>
                                <Button
                                    variant="secondary"
                                    onClick={() => setConfirmation(false)}
                                >
                                    {t('scout.back')}
                                </Button>
                                <Button style={{ minWidth: 150 }}onClick={handleSubmit}>
                                    {t('core.send')}
                                </Button>
                            </Row>
                        </>
                    )
                    : (
                        <>
                            <SearchPanel 
                                onSubmit={handleSearch} 
                                onClear={() => handleSearch({ industryIds: targetIndustryIDs })} 
                                targetIndustryIds={targetIndustryIDs}/>
                            <BottomContainer>
                                <CandidateListTable
                                    style={{ flex: 1 }}
                                    minimized={selectedIndex > -1}
                                    candidates={candidates}
                                    selectedIndexes={selectedIndexes}
                                    onItemClick={setSelectedIndex}
                                    onItemSelect={(index) => {
                                        const position = selectedIndexes.indexOf(index);
                                        if (position === -1) {
                                            if (selectedIndexes.length < remainingScouts) {
                                                setSelectedIndexes([
                                                    ...selectedIndexes,
                                                    index,
                                                ]);
                                            }
                                        } else {
                                            const newSelectedIndexes = [...selectedIndexes];
                                            newSelectedIndexes.splice(position, 1);
                                            setSelectedIndexes(newSelectedIndexes);
                                        }
                                    }}
                                    onAllToggle={() => {
                                        if (candidates.length === selectedIndexes.length) {
                                            setSelectedIndexes([]);
                                        } else {
                                            setSelectedIndexes(Array.from(Array(candidates.length).keys()));
                                        }
                                    }}
                                />
                                {selectedIndex > -1 && (
                                    <RightContainer>
                                        <CandidateOverview candidate={candidates[selectedIndex]} />
                                    </RightContainer>
                                )}
                            </BottomContainer>
                        </>
                    )
                }
            </Container>
        </LocalPage>
    );
};

export default CandidateListPage;