import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonGroup from '../../../core/components/ButtonGroup';
import CategoryHeading from '../../../core/components/CategoryHeading';
import Checkbox from '../../../core/components/Checkbox';
import Field from '../../../core/components/Field';
import HelperText from '../../../core/components/HelperText';
import Label from '../../../core/components/Label';
import Message from '../../../core/components/Message';
import MultiSelect from '../../../core/components/MultiSelect';
import Radio from '../../../core/components/Radio';
import Row from '../../../core/components/Row';
import Section from '../../../core/components/Section';
import SectionHeading from '../../../core/components/SectionHeading';
import Text from '../../../core/components/Text';
import TextInput from '../../../core/components/TextInput';
import { Month } from '../../../core/types/api';
import { SelectOption } from '../../../core/components/Select';
import styled from 'styled-components';

type SalarySectionProps = {
    months?: Month[];
    salaryType?: string;
    annualizedWorkingHours?: boolean;
    hourlyDailySalary?: string;
    hourlyDailySalaryUpperLimit?: string;
    grossSalary?: string;
    grossSalaryUpperLimit?: string;
    estimatedTotalDeductions?: string;
    expectedSocialInsurance?: string;
    dormitoryFee?: string;
    expectedTax?: string;
    takeHomeSalary?: string;
    industry?: string;
    nightShiftHours?: string;
    nightShiftWage?: string;
    nightWorkAllowance?: string;
    salaryRaise?: boolean;
    bonus?: boolean;
    bonusPayments?: string;
    bonusMonths?: string[];
    onSalaryTypeChange?: (salaryType: string) => void;
    onAnnualizedWorkingHoursChange?: (annualizedWorkingHours: boolean) => void;
    onHourlyDailySalaryChange?: (hourlyDailySalary: string) => void;
    onHourlyDailySalaryUpperLimitChange?: (hourlyDailySalaryUpperLimit: string) => void;
    onGrossSalaryChange?: (salary: string) => void;
    onGrossSalaryUpperLimitChange?: (salaryUpperLimit: string) => void;
    onEstimatedTotalDeductionsChange?: (estimatedTotalDeductions: string) => void;
    onExpectedSocialInsuranceChange?: (expectedSocialInsurance: string) => void;
    onDormitoryFeeChange?: (dormitoryFee: string) => void;
    onExpectedTaxChange?: (expectedTax: string) => void;
    onTakeHomeSalaryChange?: (takeHomeSalary: string) => void;
    onNightShiftHoursChange?: (nightShiftHours: string) => void;
    onNightShiftWageChange?: (nightShiftWage: string) => void;
    onNightWorkAllowanceChange?: (nightWorkAllowance: string) => void;
    onSalaryRaiseChange?: (salaryRaise: boolean) => void;
    onBonusChange?: (bonus: boolean) => void;
    onBonusPaymentsChange?: (bonusPayments: string) => void;
    onBonusMonthsChange?: (bonusMonths: string[]) => void;
};

const LocalRow = styled(Row)`
    align-items: center;
    gap: 8px;
`;

const LocalTextInput = styled(TextInput)`
      &::placeholder {
        text-align: left;
      }
`;

const SalarySection: FunctionComponent<SalarySectionProps> = ({
    months,
    salaryType,
    annualizedWorkingHours,
    hourlyDailySalary,
    hourlyDailySalaryUpperLimit,
    grossSalary,
    grossSalaryUpperLimit,
    estimatedTotalDeductions,
    expectedSocialInsurance,
    dormitoryFee,
    expectedTax,
    takeHomeSalary,
    industry,
    nightShiftHours,
    nightShiftWage,
    nightWorkAllowance,
    salaryRaise,
    bonus,
    bonusPayments,
    bonusMonths,
    onSalaryTypeChange,
    onAnnualizedWorkingHoursChange,
    onHourlyDailySalaryChange,
    onHourlyDailySalaryUpperLimitChange,
    onGrossSalaryChange,
    onGrossSalaryUpperLimitChange,
    onEstimatedTotalDeductionsChange,
    onExpectedSocialInsuranceChange,
    onDormitoryFeeChange,
    onExpectedTaxChange,
    onTakeHomeSalaryChange,
    onNightShiftHoursChange,
    onNightShiftWageChange,
    onNightWorkAllowanceChange,
    onSalaryRaiseChange,
    onBonusChange,
    onBonusPaymentsChange,
    onBonusMonthsChange,
}) => {
    const [monthOptions, setMonthOptions] = useState<SelectOption[]>([]);
    const isEmpty = (value: any) => !(!!value);
    const { t } = useTranslation();

    useEffect(() => {
        if (months) {
            const monthOptions = months.map((month) => ({
                label: month.name,
                value: String(month.id),
            }));
            setMonthOptions(monthOptions);
        } else {
            setMonthOptions([]);
        }
    }, [months]);

    return (
        <Section>
            <SectionHeading>{t('job.salary')}</SectionHeading>
            <Field>
                <Label required>{t('job.salary_type')}</Label>
                <ButtonGroup>
                    <Radio
                        value="monthly"
                        error={isEmpty(salaryType)}
                        checked={salaryType === 'monthly'}
                        onValueChange={onSalaryTypeChange}
                    >
                        {t('job.salary_monthly')}
                    </Radio>
                    <Radio
                        value="hourly"
                        error={isEmpty(salaryType)}
                        checked={salaryType === 'hourly'}
                        onValueChange={onSalaryTypeChange}
                    >
                        {t('job.salary_hourly')}
                    </Radio>
                    <Radio
                        value="daily"
                        error={isEmpty(salaryType)}
                        checked={salaryType === 'daily'}
                        onValueChange={onSalaryTypeChange}
                    >
                        {t('job.salary_daily')}
                    </Radio>
                </ButtonGroup>
                {   isEmpty(salaryType) &&
                    <Text error={true} style={{ fontSize: 12 }}>{t('core.input_prompt.radio_button')}</Text>
                }
            </Field>
            <Field style={{ width: '100%' }}>
                <Checkbox
                    checked={annualizedWorkingHours}
                    onValueChange={onAnnualizedWorkingHoursChange}
                >
                    {t('job.annual_variable_working_hour')}
                </Checkbox>
                {annualizedWorkingHours && (
                    <Message style={{ marginTop: '8px' }}>
                        {t('job.annual_variable_working_hour_description')}
                    </Message>
                )}
            </Field>
            {(salaryType === 'hourly' || salaryType === 'daily') && (
            <Field>
                <Label required>{t('job.hourly_daily_salary')}</Label>
                <LocalRow>
                        <TextInput
                            type="number"
                            placeholder="1200"
                            style={{ width: '165px' }}
                            value={hourlyDailySalary}
                            error={isEmpty(hourlyDailySalary)}
                            onTextChange={onHourlyDailySalaryChange}
                        />
                        <Text>~</Text>
                        <LocalTextInput
                            type="number"
                            placeholder={t('job.hourly_daily_salary_upper_limit_placeholder')}
                            style={{ width: '165px' }}
                            value={hourlyDailySalaryUpperLimit}
                            onTextChange={onHourlyDailySalaryUpperLimitChange}
                        />
                </LocalRow>
                {   isEmpty(hourlyDailySalary) &&
                    <Text error={true} style={{ fontSize: 12 }}>{t('core.input_prompt.text_field')}</Text>
                }
            </Field>
            )}
            <Field>
                <Label required>{t('job.gross_salary')}</Label>
                <LocalRow>
                    <TextInput
                        type="number"
                        placeholder="198000"
                        style={{ width: '165px' }}
                        value={grossSalary}
                        error={isEmpty(grossSalary)}
                        onTextChange={onGrossSalaryChange}
                    />
                    <Text>~</Text>
                    <LocalTextInput
                        type="number"
                        placeholder={t('job.gross_salary_upper_limit_placeholder')}
                        style={{ width: '165px' }}
                        value={grossSalaryUpperLimit}
                        onTextChange={onGrossSalaryUpperLimitChange}
                    />
                </LocalRow>
                <HelperText>{t('job.gross_salary_description')}</HelperText>
                {   isEmpty(grossSalary) &&
                    <Text error={true} style={{ fontSize: 12 }}>{t('core.input_prompt.text_field')}</Text>
                }
            </Field>

            <CategoryHeading>{t('job.deductions')}</CategoryHeading>
            <Field>
                <Label required>{t('job.estimated_total_deductions')}</Label>
                <TextInput
                    type="number"
                    placeholder="0"
                    style={{ width: '165px' }}
                    value={estimatedTotalDeductions}
                    error={isEmpty(estimatedTotalDeductions)}
                    onTextChange={onEstimatedTotalDeductionsChange}
                />
                {   isEmpty(estimatedTotalDeductions) &&
                    <Text error={true} style={{ fontSize: 12 }}>{t('core.input_prompt.text_field')}</Text>
                }
            </Field>
            <Row>
                <Field>
                    <Label required>{t('job.social_insurance')}</Label>
                    <TextInput
                        type="number"
                        placeholder="0"
                        style={{ width: '165px' }}
                        value={expectedSocialInsurance}
                        error={isEmpty(expectedSocialInsurance)}
                        onTextChange={onExpectedSocialInsuranceChange}
                    />
                    {   isEmpty(expectedSocialInsurance) &&
                        <Text error={true} style={{ fontSize: 12 }}>{t('core.input_prompt.text_field')}</Text>
                    }
                </Field>
                <Field style={{ marginLeft: '40px' }}>
                    <Label required>{t('job.dorm_fee')}</Label>
                    <TextInput
                        type="number"
                        placeholder="0"
                        style={{ width: '165px' }}
                        value={dormitoryFee}
                        error={isEmpty(dormitoryFee)}
                        onTextChange={onDormitoryFeeChange}
                    />
                    {   isEmpty(dormitoryFee) &&
                        <Text error={true} style={{ fontSize: 12 }}>{t('core.input_prompt.text_field')}</Text>
                    }
                </Field>
                <Field style={{ marginLeft: '40px' }}>
                    <Label required>{t('job.income_tax')}</Label>
                    <TextInput
                        type="number"
                        placeholder="0"
                        style={{ width: '165px' }}
                        value={expectedTax}
                        error={isEmpty(expectedTax)}
                        onTextChange={onExpectedTaxChange}
                    />
                    {   isEmpty(expectedTax) &&
                        <Text error={true} style={{ fontSize: 12 }}>{t('core.input_prompt.text_field')}</Text>
                    }
                </Field>
            </Row>
            <Field>
                <Label required>{t('job.take_home_salary')}</Label>
                <TextInput
                    type="number"
                    placeholder="0"
                    style={{ width: '165px' }}
                    value={takeHomeSalary}
                    error={isEmpty(takeHomeSalary)}
                    onTextChange={onTakeHomeSalaryChange}
                />
                <HelperText>{t('job.take_home_salary_description')}</HelperText>
                {   isEmpty(takeHomeSalary) &&
                    <Text error={true} style={{ fontSize: 12 }}>{t('core.input_prompt.text_field')}</Text>
                }
            </Field>

            {industry === 'caregiving_industry'
                ? (
                    <Row>
                        <Field>
                            <Label>{t('job.night_work_allowance')}</Label>
                            <ButtonGroup style={{ marginTop: '8px' }}>
                                <Radio
                                    checked={!!nightWorkAllowance && nightWorkAllowance !== ''}
                                    onValueChange={() => onNightWorkAllowanceChange && onNightWorkAllowanceChange('0')}
                                >
                                    {t('job.provided')}
                                </Radio>
                                <Radio
                                    checked={nightWorkAllowance === ''}
                                    onValueChange={() => onNightWorkAllowanceChange && onNightWorkAllowanceChange('')}
                                >
                                    {t('job.not_provided')}
                                </Radio>
                            </ButtonGroup>
                        </Field>
                        <Field style={{ marginLeft: '40px' }}>
                            <Label>{t('job.average_number_of_payments')}</Label>
                            <TextInput
                                type="number"
                                placeholder="0"
                                style={{ width: '165px' }}
                                disabled={!nightWorkAllowance}
                                value={nightWorkAllowance}
                                onTextChange={onNightWorkAllowanceChange}
                            />
                        </Field>
                    </Row>
                )
                : (
                    <Row>
                        <Field>
                            <Label>{t('job.average_night_work_hours')}</Label>
                            <Row center>
                                <TextInput
                                    type="number"
                                    placeholder="0"
                                    style={{ width: '30px' }}
                                    value={nightShiftHours}
                                    onTextChange={onNightShiftHoursChange}
                                />
                                <Text style={{ marginLeft: '4px' }}>{t('job.hours')}</Text>
                            </Row>
                        </Field>
                        <Field style={{ marginLeft: '40px' }}>
                            <Label>{t('job.average_night_shift_premium_wages')}</Label>
                            <TextInput
                                type="number"
                                placeholder="0"
                                style={{ width: '165px' }}
                                value={nightShiftWage}
                                onTextChange={onNightShiftWageChange}
                            />
                        </Field>
                    </Row>
                )
            }

            <Field>
                <Label>{t('job.salary_raise')}</Label>
                <ButtonGroup style={{ marginTop: '8px' }}>
                    <Radio
                        checked={salaryRaise === true}
                        onValueChange={() => onSalaryRaiseChange && onSalaryRaiseChange(true)}
                    >
                        {t('job.applicable')}
                    </Radio>
                    <Radio
                        checked={salaryRaise === false}
                        onValueChange={() => onSalaryRaiseChange && onSalaryRaiseChange(false)}
                    >
                        {t('job.not_applicable')}
                    </Radio>
                </ButtonGroup>
            </Field>

            <Row>
                <Field>
                    <Label>{t('job.bonus')}</Label>
                    <ButtonGroup style={{ marginTop: '8px' }}>
                        <Radio
                            checked={bonus === true}
                            onValueChange={() => onBonusChange && onBonusChange(true)}
                        >
                            {t('job.provided')}
                        </Radio>
                        <Radio
                            checked={bonus === false}
                            onValueChange={() => onBonusChange && onBonusChange(false)}
                        >
                            {t('job.not_provided')}
                        </Radio>
                    </ButtonGroup>
                </Field>
                <Field style={{ marginLeft: '40px' }}>
                    <Label>{t('job.number_of_payments_per_year')}</Label>
                    <TextInput
                        type="number"
                        style={{ width: '40px' }}
                        disabled={!bonus}
                        value={bonusPayments}
                        onTextChange={onBonusPaymentsChange}
                    />
                </Field>
                <Field style={{ marginLeft: '40px' }}>
                    <Label>{t('job.months_of_payment')}</Label>
                    <MultiSelect
                        style={{ width: '116px`' }}
                        disabled={!bonus}
                        options={monthOptions}
                        value={bonusMonths}
                        onValueChange={onBonusMonthsChange}
                    />
                </Field>
            </Row>
        </Section>
    );
};

export default SalarySection;