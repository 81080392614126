import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonGroup from '../../../core/components/ButtonGroup';
import Field from '../../../core/components/Field';
import HelperText from '../../../core/components/HelperText';
import Label from '../../../core/components/Label';
import MultiSelect from '../../../core/components/MultiSelect';
import MultiTextInput from '../../../core/components/MultiTextInput';
import Radio from '../../../core/components/Radio';
import Row from '../../../core/components/Row';
import Section from '../../../core/components/Section';
import SectionHeading from '../../../core/components/SectionHeading';
import Text from '../../../core/components/Text';
import TextInput from '../../../core/components/TextInput';
import { Nationality } from '../../../core/types/api';
import { SelectOption } from '../../../core/components/Select';

type CandidateRequirementsSectionProps = {
    nationalities?: Nationality[];
    conversationSkill?: string;
    jlptLevel?: string;
    preferredGender?: string;
    preferredNationalities?: string[];
    preferredAgeMinimum?: string;
    preferredAgeMaximum?: string;
    requiredConditions?: string;
    preferredConditions?: string;
    onConversationSkillChange?: (conversationSkill: string) => void;
    onJLPTLevelChange?: (jlptLevel: string) => void;
    onPreferredGenderChange?: (preferredGender: string) => void;
    onPreferredNationalitiesChange?: (preferredNationalities: string[]) => void;
    onPreferredAgeMinimumChange?: (preferredAgeMinimum: string) => void;
    onPreferredAgeMaximumChange?: (preferredAgeMaximum: string) => void;
    onRequiredConditionsChange?: (requiredConditions: string) => void;
    onPreferredConditionsChange?: (preferredConditions: string) => void;
};

const CandidateRequirementsSection: FunctionComponent<CandidateRequirementsSectionProps> = ({
    nationalities,
    conversationSkill,
    jlptLevel,
    preferredGender,
    preferredNationalities,
    preferredAgeMinimum,
    preferredAgeMaximum,
    requiredConditions,
    preferredConditions,
    onConversationSkillChange,
    onJLPTLevelChange,
    onPreferredGenderChange,
    onPreferredNationalitiesChange,
    onPreferredAgeMinimumChange,
    onPreferredAgeMaximumChange,
    onRequiredConditionsChange,
    onPreferredConditionsChange,
}) => {
    const [nationalityOptions, setNationalityOptions] = useState<SelectOption[]>([]);
    const isEmpty = (value: any) => !(!!value);
    const { t } = useTranslation();

    useEffect(() => {
        if (nationalities) {
            const nationalityOptions = nationalities.map((nationality) => ({
                label: nationality.name,
                value: String(nationality.id),
            }));
            setNationalityOptions(nationalityOptions);
        } else {
            setNationalityOptions([]);
        }
    }, [nationalities]);

    return (
        <Section>
            <SectionHeading>{t('job.candidate_requirements')}</SectionHeading>

            <Field>
                <Label required>{t('job.japanese_conversation_skills')}</Label>
                <ButtonGroup style={{ marginTop: '8px' }}>
                    <Radio
                        value="can_speak_basic_phrases"
                        error={isEmpty(conversationSkill)}
                        checked={conversationSkill === 'can_speak_basic_phrases'}
                        onValueChange={onConversationSkillChange}
                    >
                        {t('candidate.can_speak_basic_phrases')}
                    </Radio>
                    <Radio
                        value="can_carry_on_everyday_conversations"
                        error={isEmpty(conversationSkill)}
                        checked={conversationSkill === 'can_carry_on_everyday_conversations'}
                        onValueChange={onConversationSkillChange}
                    >
                        {t('candidate.can_carry_on_everyday_conversations')}
                    </Radio>
                    <Radio
                        value="fluent"
                        error={isEmpty(conversationSkill)}
                        checked={conversationSkill === 'fluent'}
                        onValueChange={onConversationSkillChange}
                    >
                        {t('candidate.fluent')}
                    </Radio>
                </ButtonGroup>
                {   isEmpty(conversationSkill) &&
                    <Text error={true} style={{ fontSize: 12 }}>{t('core.input_prompt.radio_button')}</Text>
                }
            </Field>
            <Field>
                <Label>{t('job.jlpt_level')}</Label>
                <Radio
                    style={{ marginTop: '8px' }}
                    value="n1"
                    checked={jlptLevel === 'n1'}
                    onValueChange={onJLPTLevelChange}
                >
                    N1
                </Radio>
                <Radio
                    style={{ marginTop: '8px' }}
                    value="n2"
                    checked={jlptLevel === 'n2'}
                    onValueChange={onJLPTLevelChange}
                >
                    N2
                </Radio>
                <Radio
                    style={{ marginTop: '8px' }}
                    value="n3"
                    checked={jlptLevel === 'n3'}
                    onValueChange={onJLPTLevelChange}
                >
                    N3
                </Radio>
                <Radio
                    style={{ marginTop: '8px' }}
                    value="n4"
                    checked={jlptLevel === 'n4'}
                    onValueChange={onJLPTLevelChange}
                >
                    N4
                </Radio>
                <Radio
                    style={{ marginTop: '8px' }}
                    value="n5"
                    checked={jlptLevel === 'n5'}
                    onValueChange={onJLPTLevelChange}
                >
                    N5
                </Radio>
            </Field>

            <Field>
                <Label>{t('job.preferred_gender')}</Label>
                <ButtonGroup style={{ marginTop: '8px' }}>
                    <Radio
                        value=""
                        checked={preferredGender === ''}
                        onValueChange={onPreferredGenderChange}
                    >
                        {t('job.preferred_none')}
                    </Radio>
                    <Radio
                        value="male"
                        checked={preferredGender === 'male'}
                        onValueChange={onPreferredGenderChange}
                    >
                        {t('core.male')}
                    </Radio>
                    <Radio
                        value="female"
                        checked={preferredGender === 'female'}
                        onValueChange={onPreferredGenderChange}
                    >
                        {t('core.female')}
                    </Radio>
                </ButtonGroup>
            </Field>

            <Field>
                <Label>{t('job.preferred_nationality')}</Label>
                <MultiSelect
                    placeholder="core.select"
                    options={nationalityOptions}
                    value={preferredNationalities}
                    onValueChange={onPreferredNationalitiesChange}
                />
                <HelperText>{t('job.may_select_multiple')}</HelperText>
            </Field>

            <Field>
                <Label>{t('job.preferred_age_range')}</Label>
                <Row center>
                    <TextInput
                        type="number"
                        placeholder="21"
                        style={{ width: '45px' }}
                        value={preferredAgeMinimum}
                        onTextChange={onPreferredAgeMinimumChange}
                    />
                    <Text style={{ marginLeft: '4px' }}>〜</Text>
                    <TextInput
                        type="number"
                        placeholder="40"
                        style={{ width: '45px', marginLeft: '4px' }}
                        value={preferredAgeMaximum}
                        onTextChange={onPreferredAgeMaximumChange}
                    />
                </Row>
            </Field>

            <Field style={{ width: '100%' }}>
                <Label>{t('job.required_conditions')}</Label>
                <MultiTextInput
                    placeholder={t('job.required_conditions_description')}
                    style={{
                        width: '100%',
                        height: '74px',
                    }}
                    value={requiredConditions}
                    onTextChange={onRequiredConditionsChange}
                />
            </Field>

            <Field style={{ width: '100%' }}>
                <Label>{t('job.preferred_conditions')}</Label>
                <MultiTextInput
                    style={{
                        width: '100%',
                        height: '74px',
                    }}
                    value={preferredConditions}
                    onTextChange={onPreferredConditionsChange}
                />
            </Field>
        </Section>
    );
};

export default CandidateRequirementsSection;