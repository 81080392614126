import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectOption } from './components/Select';
import { useReducerContext } from './contexts/ReducerContext';
import { isEmptyObject } from './utils';
import { Nationality } from './types/api';

type IndustryId = number | string;
type jobTypeId = number | string;

export const useComponentVisible = (initialVisible: boolean) => {
    const [visible, setVisible] = useState(initialVisible)
    const ref = useRef<HTMLDivElement>(null)

    const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
            setVisible(false)
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true)
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }
    })

    return {ref, visible, setVisible}
}

export const useJobPostCategory = () => {
    const { state } = useReducerContext();
    const { i18n } = useTranslation();
    const { industries, jobTypes } = state; 
    const getLangKey = (langCode?: string): string => `${langCode || i18n.language}Name`;
 
    const getIndustryName = (industryId?: IndustryId, langCode?: string): string => {
            if (!industries || isEmptyObject(industries) || isNaN(Number(industryId)))
                return '';

            const langKey = getLangKey(langCode);
            return String(industries[Number(industryId)]?.[langKey] || ''); 
        }

    const getJobTypeName = (jobTypeId?: jobTypeId, langCode?: string): string => {
            if (!jobTypes || isEmptyObject(jobTypes) || isNaN(Number(jobTypeId)))
                return '';

            const langKey = getLangKey(langCode);
            return String(jobTypes[Number(jobTypeId)]?.[langKey] || '');
        }

    const getIndustryIdByJobTypeId = (jobTypeId?: jobTypeId): string => {
            if (!jobTypes || isEmptyObject(jobTypes) || isNaN(Number(jobTypeId)))
                return '';

            const industryId = jobTypes[Number(jobTypeId)]?.industryId;
            return industryId ? String(industryId) : '';
        }

    const getIndustryNameByJobTypeId = (jobTypeId?: jobTypeId, langCode?: string): string => {
            if (!industries ||
                !jobTypes ||
                isEmptyObject(industries) ||
                isEmptyObject(jobTypes) || 
                isNaN(Number(jobTypeId))
            )
                return '';

            const langKey = getLangKey(langCode);
            const industryId = jobTypes[Number(jobTypeId)]?.industryId;

            return industryId ? String(industries[industryId]?.[langKey]) : '';
        }

    return {
        getIndustryName,
        getJobTypeName,
        getIndustryIdByJobTypeId,
        getIndustryNameByJobTypeId
    }    
}

export const useJobPostOptions = () => {
    const { i18n } = useTranslation();
    const { state } = useReducerContext();
    const { industries } = state;
    
    const industryOptions = useMemo((): SelectOption [] => {
        if (!industries || isEmptyObject(industries))
            return [];

        const options: SelectOption[] = [];
        for (const [industryId, industry] of Object.entries(industries)) {
            options.push({
                label: String(industry[`${i18n.language}Name`] ?? ""),
                value: industryId,
            });    
        }

        return options;
    }, [industries, i18n.language]);

    const jobTypeOptionsDic = useMemo((): Record<IndustryId, SelectOption[]> => {
        if (!industries || isEmptyObject(industries))
            return {};

        const jobTypeOptionsDic: Record<IndustryId, SelectOption[]> = {};
        for (const [industryId, industry] of Object.entries(industries)) {
            jobTypeOptionsDic[industryId] = industry.jobTypes.map(jobType => ({
                label: String(jobType[`${i18n.language}Name`] ?? ""),
                value: String(jobType.id),
            }));
        }

        return jobTypeOptionsDic;
    }, [industries, i18n.language]);

    return {
        industryOptions,
        jobTypeOptionsDic
    }
}

export const useNationalities = () => {
    const { i18n, t } = useTranslation();
    const { state } = useReducerContext()
    const { nationalities } = state


    const nationalityList = useMemo(
        () => Object.values(nationalities).map(nationality => nationality)
        , [nationalities]
    )

    const getTranslatedNationalityList = (nationalities: Nationality[]): Nationality[] => {
        return nationalities.map(nationality => (
            {
                ...nationality,
                name: i18n.exists(`core.nationalities.${nationality.tkey}`)
                    ? t(`core.nationalities.${nationality.tkey}`)
                    : nationality.name,
            } as Nationality
        ))
    }

    return {
        nationalityList,
        getTranslatedNationalityList
    }
}