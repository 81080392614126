import { apiUtil } from '../../core/utils';
import moment from 'moment';

import { Interview, MeetingInformation, Proposal } from '../types';

export const createProposal = async (
    jobId: number | string,
    candidateId: number | string,
    dates: Date[],
    scoutId: number | string | null
) => {
    const response = await apiUtil.post(
        '/proposals',
        {
            jobPostId: Number(jobId),
            candidateId: Number(candidateId),
            interviewsAttributes: [
                {
                    slotsAttributes: dates.map((date) => ({
                        start: moment(date)
                            .utcOffset('+09:00')
                            .format('YYYY-MM-DD HH:mm'),
                        end: moment(date)
                            .add(1, 'hour')
                            .utcOffset('+09:00')
                            .format('YYYY-MM-DD HH:mm'),
                    })),
                },
            ],
            scoutId: !!scoutId ? Number(scoutId) : null
        },
    );
    if (response.status >= 200 && response.status < 300) {
        return response.data as Proposal;
    }
    throw new Error('API error');
};

export const getProposal = async (proposalId: number | string) => {
    const response = await apiUtil.get( `/proposals/${proposalId}`);
    if (response.status >= 200 && response.status < 300) {
        return response.data as Proposal;
    }
    throw new Error('API error');
};

export const getProposals = async (params: {} = {}) => {
    const response = await apiUtil.get( '/proposals', { params });
    if (response.status >= 200 && response.status < 300) {
        return response.data as Proposal[];
    }
    throw new Error('API error');
};

export const updateProposalState = async (
    proposalId: number | string,
    state: string,
    reason?: string,
    comment?: string,
) => {
    const response = await apiUtil.patch(
        `/proposals/${proposalId}/update_state`,
        {
            state,
            reason,
            comment,
        },
    );
    if (response.status >= 200 && response.status < 300) {
        return response.data as Proposal;
    }
    throw new Error('API error');
};

export const getMeetingInformation = async (proposalId: number | string) => {
    const response = await apiUtil.get(`/companies/meeting?proposal_id=${proposalId}`);
    if (response.status >= 200 && response.status < 300) {
        return response.data as MeetingInformation;
    }
    throw new Error('API error');
};

export const createInterview = async (proposalId: number | string, dates: Date[]) => {
    const response = await apiUtil.post(
        '/interviews',
        {
            proposalId,
            slotsAttributes: dates.map((date) => ({
                start: moment(date)
                    .utcOffset('+09:00')
                    .format('YYYY-MM-DD HH:mm'),
                end: moment(date)
                    .add(1, 'hour')
                    .utcOffset('+09:00')
                    .format('YYYY-MM-DD HH:mm'),
            })),
        },
    );
    if (response.status >= 200 && response.status < 300) {
        return response.data as Interview;
    }
    throw new Error('API error');
};

export const cancelInterview = async (interviewId: number | string, reason: string, comment: string) => {
    const response = await apiUtil.patch(
        `/interviews/${interviewId}/cancel`,
        {
            cancelReason: reason,
            cancelComment: comment,
        },
    );
    if (response.status >= 200 && response.status < 300) {
        return response.data as Interview;
    }
    throw new Error('API error');
};

export const completeInterview = async (interviewId: number | string) => {
    const response = await apiUtil.patch(`/interviews/${interviewId}/manually_complete`);
    if (response.status >= 200 && response.status < 300) {
        return response.data as Interview;
    }
    throw new Error('API error');
};

export const updateInterviewSlots = async (interviewId: number | string, dates: Date[]) => {
    let response = await apiUtil.get(`/interviews/${interviewId}`);
    const slotsAttributes = [];
    if (response.status >= 200 && response.status < 300) {
        const interview = response.data as Interview;
        for (const slot of interview.slots) {
            slotsAttributes.push({
                id: slot.id,
                _destroy: true,
            });
        }
    }

    response = await apiUtil.patch(
        `/interviews/${interviewId}/update_slots`,
        {
            slotsAttributes: [
                ...slotsAttributes,
                ...dates.map((date) => ({
                    start: moment(date)
                        .utcOffset('+09:00')
                        .format('YYYY-MM-DD HH:mm'),
                    end: moment(date)
                        .add(1, 'hour')
                        .utcOffset('+09:00')
                        .format('YYYY-MM-DD HH:mm'),
                })),
            ]
        },
    );
    if (response.status >= 200 && response.status < 300) {
        return response.data as Interview;
    }
    throw new Error('API error');
};

export const selectInterviewSlot = async (interviewId: number | string, slotId: number | string) => {
    const response = await apiUtil.patch(
        `/interviews/${interviewId}/select_slot`,
        {
            slotId: Number(slotId),
        },
    );
    if (response.status >= 200 && response.status < 300) {
        return response.data as Interview;
    }
    throw new Error('API error');
};