import { FunctionComponent } from 'react';
import styled, { css } from 'styled-components'

import Modal from './Modal'
import Spinner from './Spinner';
import { useReducerContext } from '../contexts/ReducerContext';

interface LoadingModalProps {
}

const commonProperty = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 5px;

`;

const Container = styled.div`
  ${commonProperty}
  height: 120px;
  padding-right: 55px;
  padding-left: 19px;
`;

const ContainerForNoText = styled.div`
  ${commonProperty}
  padding: 10px;
`;

const Message = styled.div`
  margin-left: 18px;
  font: normal normal normal 20px/34px Hiragino Kaku Gothic ProN;
  color: #2C2C2C;
  white-space: nowrap;
`;

const LoadingModal: FunctionComponent<LoadingModalProps> = () => {
    const { state } = useReducerContext();

    return (
        <Modal open={state.isLoading}>
          {
            state.loadingMessage === ''?
              <ContainerForNoText>
                <Spinner />
              </ContainerForNoText>
              : 
              <Container>
                <Spinner />
                <Message>{state.loadingMessage}</Message>
              </Container>
          }
        </Modal>
    );
};

export default LoadingModal;