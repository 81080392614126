import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';

import JobCard from '../../components/JobCard';
import JobDetailsCard from '../../components/JobDetailsCard';
import ProposeCandidateCard from '../../components/ProposeCandidateCard';
import { Job } from '../../types/api';
import { useReducerContext } from '../../../core/contexts/ReducerContext';
import { MOBILE_DEVICE } from '../../../core/constants/styles';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { decodeHTMLEntities, getJobLocation } from '../../../core/utils';
import { useJobPostCategory } from '../../../core/hooks';
import { useNationalities } from '../../../core/hooks';

type DetailedViewProps = {
    top: number;
    jobs: Job[];
    questions: { [key: number]: any[] };
    onPropose?: (jobId: number) => void;
    onAsk?: (jobId: number) => void;
    onQuestionsLoad?: (jobId: number) => void;
};

type RightContainerProps = {
    top: number;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 1130px;
  
  @media ${MOBILE_DEVICE} {
    width: 100%;
  }
`;

const LeftContainer = styled.div`
  width: 560px;
  
  > *:not(:first-child) {
    margin-top: 20px;
  }

  @media ${MOBILE_DEVICE} {
    flex: 1;
    width: unset;

    > *:not(:first-child) {
      margin-top: 10px;
    }
  }
`;

const RightContainer = styled.div<RightContainerProps>`
  position: fixed;
  top: ${(props: RightContainerProps) => `${props.top}px`};
  bottom: 10px;
  left: 50%;
  display: flex;
  flex-direction: column;
  width: 560px;
  margin-left: 10px;

  @media ${MOBILE_DEVICE} {
    display: none;
  }
`

const DetailedView: FunctionComponent<DetailedViewProps> = ({
    top,
    jobs,
    questions,
    onPropose,
    onAsk,
    onQuestionsLoad,
}) => {
    const { state } = useReducerContext();
    const { getTranslatedNationalityList } = useNationalities();
    const jobPostCategory = useJobPostCategory();
    const [activeIndex, setActiveIndex] = useState(0);
    const activeJob = jobs[activeIndex];
    const isMobileDevice = useMediaQuery({
        query: MOBILE_DEVICE,
    });

    const getDefaulThumbnailURL = (jobTypeId: number) => {
        return `/images/job-type-thumbnails/${jobTypeId}.webp`;
    }

    const handleJobClick = (index: number) => {
        if (isMobileDevice) {
            window.open(`/jobs/${jobs[index].id}`, '_blank');
        } else {
            setActiveIndex(index);
            onQuestionsLoad && onQuestionsLoad(jobs[index].id);
        }
    };
    const { i18n } = useTranslation();

    return (
        <Container>
            <LeftContainer>
                {jobs.map((job, index) => (
                    <JobCard
                        key={job.id}
                        active={index === activeIndex}
                        thumbnail={job.images.length
                            ? job.images[0].url
                            : getDefaulThumbnailURL(job.jobTypeId)
                        }
                        title={
                            decodeHTMLEntities(job.translations?.find(t => t.language.code === i18n.language)?.name)
                            ?? job.name
                        }
                        location={getJobLocation(job, i18n.language)}
                        industry={jobPostCategory.getIndustryNameByJobTypeId(job.jobTypeId)}
                        jobType={jobPostCategory.getJobTypeName(job.jobTypeId)}
                        grossSalary={job.grossSalary}
                        grossSalaryUpperLimit={job.grossSalaryUpperLimit}
                        description={
                            decodeHTMLEntities(job.translations?.find(t => t.language.code === i18n.language)?.jobDescription)
                            ?? job.jobDescription
                        }
                        onClick={() => handleJobClick(index)}
                    />
                ))}
            </LeftContainer>
            <RightContainer top={top}>
                {activeJob && (
                    <>
                        <ProposeCandidateCard
                            style={{ marginBottom: 10 }}
                            readonly={!state.isPartner && !state.isCandidate}
                            title={
                                decodeHTMLEntities(activeJob.translations?.find(t => t.language.code === i18n.language)?.name)
                                ?? activeJob.name
                            }
                            location={getJobLocation(activeJob, i18n.language)}
                            industry={jobPostCategory.getIndustryNameByJobTypeId(activeJob.jobTypeId)}
                            jobType={jobPostCategory.getJobTypeName(activeJob.jobTypeId)}
                            grossSalary={activeJob.grossSalary}
                            grossSalaryUpperLimit={activeJob.grossSalaryUpperLimit}
                            onPropose={() => onPropose && onPropose(activeJob.id)}
                            onAsk={() => onAsk && onAsk(activeJob.id)}
                        />
                        <JobDetailsCard
                            style={{ flex: 1 }}
                            isAuthorized={state.isPartner || state.isEmployer}
                            images={activeJob.images.length > 0
                                ? activeJob.images.map((image) => image.url)
                                : [getDefaulThumbnailURL(activeJob.jobTypeId)]
                            }
                            numberOfPositions={activeJob.numberOfPositions}
                            applicationDeadline={activeJob.applicationDeadline
                                ? new Date(activeJob.applicationDeadline)
                                : undefined
                            }
                            jobId={activeJob.id}
                            plannedEmploymentDate={activeJob.plannedDateOfEmployment}
                            jobDescription={
                                decodeHTMLEntities(activeJob.translations?.find(t => t.language.code === i18n.language)?.jobDescription)
                                ?? activeJob.jobDescription
                            }
                            contractPeriod={activeJob.contractPeriod}
                            contractPeriodYears={activeJob.contractPeriodYears}
                            contractRenewal={activeJob.contractRenewal}
                            acceptOverseasResidents={activeJob.acceptOverseasResidents}
                            salaryType={activeJob.salaryType}
                            annualizedWorkingHours={activeJob.annualizedVariableWorkingHourSystem}
                            hourlyDailySalary={activeJob.hourlyDailySalary}
                            hourlyDailySalaryUpperLimit={activeJob.hourlyDailySalaryUpperLimit}
                            grossSalary={activeJob.grossSalary}
                            grossSalaryUpperLimit={activeJob.grossSalaryUpperLimit}
                            estimatedTotalDeductions={activeJob.estimatedTotalDeductions}
                            expectedSocial={activeJob.estimatedSocialInsurance}
                            dormFee={activeJob.dormitoryFee}
                            expectedTax={activeJob.estimatedIncomeTax}
                            salaryAfterTax={activeJob.takeHomeSalary}
                            salaryRaise={activeJob.salaryRaise}
                            nightWorkAllowance={activeJob.averageTotalNightShiftPremiumWagesPerMonth}
                            bonus={activeJob.bonus}
                            bonusPayments={activeJob.numberOfBonusPaymentsPerYear}
                            bonusMonths={activeJob.bonusMonths.map((month) => month.tkey)}
                            hoursPerDayMin={activeJob.basicWorkHoursPerDay}
                            shiftBased={activeJob.workStyle === 'shift_system'}
                            monthlyDays={activeJob.averageMonthlyWorkDays}
                            averageOvertime={activeJob.averageMonthlyOvertimeHours}
                            holidaysOff={activeJob.averageAnnualDaysOff}
                            dormitoryAvailable={activeJob.dormitory}
                            dormitoryType={activeJob.dormitoryType}
                            bedroomCount={activeJob.bedroomPersons}
                            commuteMethods={activeJob.commuteMethod}
                            estimatedCommuteTimeMinutes={activeJob.commuteMinutes}
                            furnitureProvided={activeJob.furnitureAppliances}
                            movingTransExpenses={activeJob.transportationMovingExpensesCovered}
                            movingBagsExpensesAllowance={activeJob.transportationMovingExpensesMaximumSupportAllowance}
                            movingBagsExpenses={activeJob.baggageDeliveryMovingExpensesCovered}
                            movingTransExpensesAllowance={activeJob.baggageDeliveryMovingExpensesMaximumSupportAllowance}
                            naturalJapaneseLevel={activeJob.japaneseConversationSkills}
                            japaneseLevel={activeJob.jlptLevel}
                            preferredGender={activeJob.preferredGender}
                            preferredCountries={getTranslatedNationalityList(activeJob.preferredNationalities).map((nationality) => nationality.name)}
                            preferredAgeMin={activeJob.preferredAgeFrom}
                            preferredAgeMax={activeJob.preferredAgeTo}
                            required={
                                decodeHTMLEntities(activeJob.translations?.find(t => t.language.code === i18n.language)?.requiredConditions)
                                ?? activeJob.requiredConditions
                            }
                            preferred={
                                decodeHTMLEntities(activeJob.translations?.find(t => t.language.code === i18n.language)?.preferredConditions)
                                ?? activeJob.preferredConditions
                            }
                            currentNationalities={getTranslatedNationalityList(activeJob.currentNationalities).map((nationality) => nationality.name)}
                            specialAllowance={activeJob.specialAllowanceUponHire}
                            interviewMethod={activeJob.interviewMethod}
                            coverInterviewExpense={activeJob.provideFullTransportationExpenses ?? false}
                            interviewAvailability={
                                decodeHTMLEntities(activeJob.translations?.find(t => t.language.code === i18n.language)?.availableDaysAndTimesForInterviews)
                                ?? activeJob.availableDaysAndTimesForInterviews
                            }
                            additionalInfo={
                                decodeHTMLEntities(activeJob.translations?.find(t => t.language.code === i18n.language)?.otherSupplementaryInformationAboutThisJob)
                                ?? activeJob.otherSupplementaryInformationAboutThisJob
                            }
                            questions={questions[activeJob.id] ? questions[activeJob.id] : []}
                        />
                    </>
                )}
            </RightContainer>
        </Container>
    );
};

export default DetailedView;