import { FunctionComponent, HTMLAttributes } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Column from '../../core/components/Column';
import Gallery from '../../core/components/Gallery';
import Row from '../../core/components/Row';
import SectionHeading from '../../core/components/SectionHeading';
import Text from '../../core/components/Text';

import { JobQandASection} from './JobQASection';

type JobDetailsCardProps = HTMLAttributes<HTMLDivElement> & {
    jobId: number,
    isAuthorized?: boolean;
    images?: string[];
    numberOfPositions?: number;
    applicationDeadline?: Date;
    plannedEmploymentDate?: string;
    jobDescription?: string;
    contractType?: string;
    contractPeriod?: string;
    contractPeriodYears?: number;
    contractRenewal?: string;
    acceptOverseasResidents?: boolean;
    salaryType?: string;
    annualizedWorkingHours?: boolean;
    hourlyDailySalary?: number;
    hourlyDailySalaryUpperLimit?: number;
    grossSalary?: number;
    grossSalaryUpperLimit?: number;
    estimatedTotalDeductions?: number;
    expectedSocial?: number;
    dormFee?: number;
    expectedTax?: number;
    salaryAfterTax?: number;
    nightWorkAllowance?: number;
    salaryRaise?: boolean;
    bonus?: boolean;
    bonusPayments?: number;
    bonusMonths?: string[];
    hoursPerDayMin?: number;
    minutesPerDayMin?: number;
    shiftBased?: boolean;
    annualHours?: number;
    monthlyDays?: number;
    averageOvertime?: number;
    holidaysOff?: number;
    dormitoryAvailable?: boolean;
    dormitoryType?: string;
    bedroomCount?: number;
    commuteMethods?: string;
    estimatedCommuteTimeMinutes?: number;
    furnitureProvided?: boolean;
    movingTransExpenses?: boolean;
    movingTransExpensesAllowance?: number;
    movingBagsExpenses?: boolean;
    movingBagsExpensesAllowance?: number;
    naturalJapaneseLevel?: string;
    japaneseLevel?: string;
    preferredGender?: string;
    preferredCountries?: string[];
    preferredAgeMin?: number;
    preferredAgeMax?: number;
    required?: string;
    preferred?: string;
    currentNationalities?: string[];
    specialAllowance?: number;
    interviewMethod?: string;
    coverInterviewExpense?: boolean;
    interviewAvailability?: string;
    additionalInfo?: string;
    questions?: any[];
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 560px;
  padding: 10px 27px 20px 20px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  overflow-y: auto
`;

const LocalSectionHeading = styled(SectionHeading)`
  margin-top: 50px;
`;

const LocalRow = styled(Row)`
  margin-top: 10px !important;
  
  > *:first-child {
    min-width: 234px;
    max-width: 234px;
    margin-right: 16px;
  }
`;

const LocalColumn = styled(Column)`
  margin-top: 10px !important;

  > *:first-child {
    margin-top: 4px;
  }
`;

const JobDetailsCard: FunctionComponent<JobDetailsCardProps> = ({
    jobId,
    isAuthorized,
    images,
    numberOfPositions,
    applicationDeadline,
    plannedEmploymentDate,
    jobDescription: description,
    contractType,
    contractPeriod,
    contractPeriodYears,
    contractRenewal,
    acceptOverseasResidents,
    salaryType,
    annualizedWorkingHours,
    hourlyDailySalary,
    hourlyDailySalaryUpperLimit,
    grossSalary,
    grossSalaryUpperLimit,
    estimatedTotalDeductions,
    expectedSocial,
    dormFee,
    expectedTax,
    salaryAfterTax,
    nightWorkAllowance,
    salaryRaise,
    bonus,
    bonusPayments,
    bonusMonths,
    hoursPerDayMin,
    minutesPerDayMin,
    shiftBased,
    annualHours,
    monthlyDays,
    averageOvertime,
    holidaysOff,
    dormitoryAvailable,
    dormitoryType,
    bedroomCount,
    commuteMethods,
    estimatedCommuteTimeMinutes,
    furnitureProvided,
    movingTransExpenses,
    movingTransExpensesAllowance,
    movingBagsExpenses,
    movingBagsExpensesAllowance,
    naturalJapaneseLevel,
    japaneseLevel,
    preferredGender,
    preferredCountries,
    preferredAgeMin,
    preferredAgeMax,
    required,
    preferred,
    currentNationalities,
    specialAllowance,
    interviewMethod,
    coverInterviewExpense,
    interviewAvailability,
    additionalInfo,
    questions,
    ...props
}) => {
    const { t } = useTranslation();

    return (
        <Container {...props}>
            <Gallery small style={{ width: '100%', minHeight: 130 }} images={images} />

            <LocalSectionHeading>{t('job.general')}</LocalSectionHeading>
            <LocalRow>
                <Text>{t('job.number_of_positions')}</Text>
                <Text>{numberOfPositions}</Text>
            </LocalRow>
            <LocalRow>
                <Text>{t('job.application_deadline')}</Text>
                <Text>
                    {applicationDeadline
                        ? moment(applicationDeadline).format('MMMM Do, YYYY')
                        : '-'
                    }
                </Text>
            </LocalRow>
            <LocalRow>
                <Text>{t('job.planned_date_of_employment')}</Text>
                <Text>
                    {plannedEmploymentDate
                        ? moment(plannedEmploymentDate).format('MMMM YYYY')
                        : '-'
                    }
                </Text>
            </LocalRow>
            <LocalRow>
                <Text>{t('job.job_description')}</Text>
                <Text>{description || '-'}</Text>
            </LocalRow>

            <LocalSectionHeading>{t('job.contract')}</LocalSectionHeading>
            <LocalRow>
                <Text>{t('job.type_of_contract')}</Text>
                <Text>{ contractType? t(`job.contract_${contractType}`) : '' }</Text>
            </LocalRow>
            <LocalRow>
                <Text>{t('job.contract_period')}</Text>
                <Text>{ contractPeriod? t(`job.${contractPeriod}`) : '' }</Text>
            </LocalRow>
            {!!contractPeriod && (
                <LocalRow>
                    <Text>{t('job.period_length')}</Text>
                    <Text>{contractPeriodYears ?? '-'} {contractPeriodYears ? t('job.years', { count: contractPeriodYears }) : ''}</Text>
                </LocalRow>
            )}
            <LocalRow>
                <Text>{t('job.contract_renewal')}</Text>
                <Text>{ contractRenewal? t(`job.${contractRenewal}_renewal`) : '' }</Text>
            </LocalRow>
            <LocalRow>
                <Text>{t('job.acceptance_of_overseas_residents')}</Text>
                <Text>{acceptOverseasResidents && t('job.acceptance_of_overseas_residents_acceptable')}</Text>
            </LocalRow>
            
            <LocalSectionHeading>{t('job.salary')}</LocalSectionHeading>
            <LocalRow>
                <Text>{t('job.salary_type')}</Text>
                <Text>{t(`job.salary_${salaryType}`)}</Text>
            </LocalRow>
            <LocalRow>
                <Text>{t('job.annual_variable_working_hour')}</Text>
                <Text>{t(`core.${annualizedWorkingHours ? 'yes' : 'no'}`)}</Text>
            </LocalRow>
            <LocalRow>
                <Text>{t('job.hourly_daily_salary')}</Text>
                <Text>
                    { hourlyDailySalary?.toLocaleString() }
                    { hourlyDailySalaryUpperLimit ? ` ~ ${hourlyDailySalaryUpperLimit.toLocaleString()}` : null}
                </Text>
            </LocalRow>
            <LocalRow>
                <Text>{t('job.gross_salary')}</Text>
                <Text>
                    { grossSalary?.toLocaleString() }
                    { grossSalaryUpperLimit ? ` ~ ${grossSalaryUpperLimit.toLocaleString()}` : null}
                </Text>
            </LocalRow>

            <LocalSectionHeading>{t('job.deductions')}</LocalSectionHeading>
            <LocalRow>
                <Text>{t('job.estimated_total_deductions')}</Text>
                <Text>{estimatedTotalDeductions?.toLocaleString()}</Text>
            </LocalRow>
            <LocalRow>
                <Text>{t('job.social_insurance')}</Text>
                <Text>{expectedSocial?.toLocaleString()}</Text>
            </LocalRow>
            <LocalRow>
                <Text>{t('job.dorm_fee')}</Text>
                <Text>{dormFee?.toLocaleString()}</Text>
            </LocalRow>
            <LocalRow>
                <Text>{t('job.expected_taxes')}</Text>
                <Text>{expectedTax?.toLocaleString()}</Text>
            </LocalRow>
            <LocalRow>
                <Text>{t('job.take_home_salary')}</Text>
                <Text>{salaryAfterTax?.toLocaleString()}</Text>
            </LocalRow>
            <LocalRow>
                <Text>{t('job.salary_raise')}</Text>
                <Text>{t(`job.${salaryRaise ? '' : 'not_'}applicable`)}</Text>
            </LocalRow>
            <LocalRow>
                <Text>{t('job.night_work_allowance')}</Text>
                <Text>{nightWorkAllowance?.toLocaleString() ?? '-'}</Text>
            </LocalRow>
            <LocalRow>
                <Text>{t('job.average_number_of_payments')}</Text>
            </LocalRow>
            <LocalRow>
                <Text>{t('job.bonus')}</Text>
                <Text>{t(`job.${!bonus ? 'not_' : ''}provided`)}</Text>
            </LocalRow>
            {!!bonus && (
                <>
                    <LocalRow>
                        <Text>{t('job.bonus')} - {t('job.number_of_payments_per_year')}</Text>
                        <Text>{bonusPayments}</Text>
                    </LocalRow>
                    <LocalRow>
                        <Text>{t('job.bonus')} - {t('job.months_of_payment')}</Text>
                        <Text>{bonusMonths?.map(month => t(`core.months.${month}`)).join(', ')}</Text>
                    </LocalRow>
                </>
            )}

            <LocalSectionHeading>{t('job.job_working_hours')}</LocalSectionHeading>
            <LocalRow>
                <Text>{t('job.basic_work_hours')}</Text>
                <Text>
                    {`${hoursPerDayMin ?? 0} ${t('job.hours')} ${minutesPerDayMin ?? 0} ${t('job.minutes')}`}
                </Text>
            </LocalRow>
            <LocalRow>
                <Text>{t('job.work_style')}</Text>
                <Text>{t(`job.${shiftBased ? 'shift_system' : 'fixed_hours'}`)}</Text>
            </LocalRow>
            <LocalRow>
                <Text>{t('job.annual_work_hours')}</Text>
                <Text>{annualHours}</Text>
            </LocalRow>
            <LocalRow>
                <Text>{t('job.monthly_work_days')}</Text>
                <Text>{monthlyDays}</Text>
            </LocalRow>
            <LocalRow>
                <Text>{t('job.monthly_overtime')}</Text>
                <Text>{averageOvertime}</Text>
            </LocalRow>
            <LocalRow>
                <Text>{t('job.annual_days_off')}</Text>
                <Text>{holidaysOff}</Text>
            </LocalRow>

            <LocalSectionHeading>{t('job.housing')}</LocalSectionHeading>
            <LocalRow>
                <Text>{t('job.dormitory')}</Text>
                <Text>{t(`core.option_${dormitoryAvailable ? '' : 'not_'}available`)}</Text>
            </LocalRow>
            <LocalRow>
                <Text>{t('job.dormitory_type')}</Text>
                <Text>{dormitoryType && t(`job.${dormitoryType}`)}</Text>
            </LocalRow>
            <LocalRow>
                <Text>{t('job.bedroom')} ({t('job.persons_room')})</Text>
                <Text>{bedroomCount}</Text>
            </LocalRow>
            <LocalRow>
                <Text>{t('job.commute_method_and_time')}</Text>
                <Text>
                    {commuteMethods && (
                        <>
                            {`${t(`job.${commuteMethods.split(':')[0]}`)} `}
                            {commuteMethods.split(':')[1]}
                            {` ${t('job.minutes')}`}
                        </>
                    )}
                </Text>
            </LocalRow>
            <LocalRow>
                <Text>{t('job.furniture_appliances')}</Text>
                <Text>{t(`job.${furnitureProvided ? '' : 'not_'}provided`)}</Text>
            </LocalRow>
            <LocalRow>
                <Text>{t('job.moving_expenses_transportation')}</Text>
                <Text>{t(`job.${!!movingTransExpenses ? 'covered_by_employer' : 'paid_by_employee'}`)}</Text>
            </LocalRow>
            <LocalRow>
                <Text>{t('job.support_allowance')}</Text>
                <Text>{movingTransExpensesAllowance?.toLocaleString()}</Text>
            </LocalRow>
            <LocalRow>
                <Text>{t('job.moving_expenses_baggage')}</Text>
                <Text>{t(`job.${!!movingBagsExpenses ? 'covered_by_employer' : 'paid_by_employee'}`)}</Text>
            </LocalRow>
            <LocalRow>
                <Text>{t('job.support_allowance')}</Text>
                <Text>{movingBagsExpensesAllowance?.toLocaleString()}</Text>
            </LocalRow>

            {isAuthorized && (
                <>
                    <LocalSectionHeading>{t('job.candidate_requirements')}</LocalSectionHeading>
                    <LocalRow>
                        <Text>{t('job.japanese_conversation_skills')}</Text>
                        <Text>{naturalJapaneseLevel && t(`candidate.${naturalJapaneseLevel}`)}</Text>
                    </LocalRow>
                    <LocalRow>
                        <Text>{t('job.jlpt_level')}</Text>
                        <Text>{japaneseLevel?.toUpperCase()}</Text>
                    </LocalRow>
                    <LocalRow>
                        <Text>{t('job.preferred_gender')}</Text>
                        <Text>{t(preferredGender ? `core.${preferredGender}` : 'job.preferred_none')}</Text>
                    </LocalRow>
                    <LocalRow>
                        <Text>{t('job.preferred_nationality')}</Text>
                        <Text>{preferredCountries?.join(', ')}</Text>
                    </LocalRow>
                    <LocalRow>
                        <Text>{t('job.preferred_age_range')}</Text>
                        <Text>
                            {!!preferredAgeMin && preferredAgeMin} ~ {!!preferredAgeMax && preferredAgeMax}
                        </Text>
                    </LocalRow>
                    <LocalRow>
                        <Text>{t('job.required_conditions')}</Text>
                        <Text>{required}</Text>
                    </LocalRow>
                    <LocalRow>
                        <Text>{t('job.preferred_conditions')}</Text>
                        <Text>{preferred}</Text>
                    </LocalRow>
                </>
            )}

            <LocalSectionHeading>{t('job.other')}</LocalSectionHeading>
            <LocalRow>
                <Text>{t('job.nationalities_of_currently_working_foreign_employees')}</Text>
                <Text>{currentNationalities?.join(', ')}</Text>
            </LocalRow>
            <LocalRow>
                <Text>{t('job.special_allowance_upon_hire')}</Text>
                <Text>{specialAllowance}</Text>
            </LocalRow>
            <LocalRow>
                <Text>{t('job.interview_method')}</Text>
                <Text>{ interviewMethod? t(`job.${interviewMethod}_interview`) : '' }</Text>
            </LocalRow>
            <LocalRow>
                <Text>{t('job.provide_full_transportation_expenses')}</Text>
                <Text>{t(`core.${coverInterviewExpense ? 'yes' : 'no'}`)}</Text>
            </LocalRow>
            <LocalRow>
                <Text>{t('job.available_days_and_times_for_interview')}</Text>
                <Text>{interviewAvailability}</Text>
            </LocalRow>
            <LocalRow>
                <Text>{t('job.other_supplementary_information_about_this_job')}</Text>
                <Text>{additionalInfo}</Text>
            </LocalRow>

            <LocalSectionHeading>{t('job.q_and_a')}</LocalSectionHeading>
            {questions?.filter(question => question.type === 'job').map((question) => (
                <LocalColumn>
                    <Text style={{ fontWeight: 500 }}>
                        Q. {question.qaItems && question.qaItems.length > 0 && question.qaItems[0].message}
                    </Text>
                    <Text>
                        A. {question.qaItems && question.qaItems.length > 1 && question.qaItems[1].message}
                    </Text>
                </LocalColumn>
            ))}
            
            {jobId && <JobQandASection jobPostId={jobId}/>}

        </Container>
    );
};

export default JobDetailsCard;