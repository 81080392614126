import { ChangeEvent, FunctionComponent, HTMLAttributes, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as Icon } from '../../assets/icon-upload.svg';
import Text from './Text';

type UploadButtonProps = HTMLAttributes<HTMLButtonElement> & {
    file?: File | string;
    buttonText?: string;
    disabled?: boolean;
    accept?: string;
    onFileChange?: (file: File) => void;
};

type ContainerProps = {
    
};

const ButtonText = styled(Text)`
  margin-left: 6px;
`;

const UploadIcon = styled(Icon)``;

const Container = styled.button<ContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
  min-width: 88px;
  padding: 2px 12px;
  background: '#F2F2F2';
  border: 0.5px solid #D5D5D5;
  border-radius: 5px;
  outline: none;
  white-space: nowrap;

  &:hover {
    background-color: #FFF0F0;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #D5D5D5;
  }

  &:hover ${UploadIcon} {
    path {
        fill: #FF730D;
    }
  }

  &:disabled ${UploadIcon} {
    path {
        fill: #999999;
    }
  }

  &:hover ${ButtonText} {
    color: #FF730D;
  }

  &:disabled ${ButtonText} {
    color: #999999;
  }

  &:active ${ButtonText} {
    color: #444444;
  }

  &:active {
    background-color: #D5D5D5;
  }

  &:active ${UploadIcon} {
    path {
      fill: #444444;
    }
  }

`;

const UploadButton: FunctionComponent<UploadButtonProps> = ({
    file,
    buttonText,
    disabled,
    accept,
    onFileChange,
    ...props
}) => {
    const ref = useRef(null);
    const { t } = useTranslation();

    const handleClick = () => {
        if (ref.current) {
            (ref.current as HTMLInputElement).click();
        }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length) {
            onFileChange && onFileChange(e.target.files[0]);
        }
    };

    return (
        <Container 
            disabled={disabled}
            onClick={handleClick} 
            {...props}
        >
            <UploadIcon />
            <ButtonText>
                {buttonText ?? t(`core.${file ? 'uploaded' : 'upload'}`)}
            </ButtonText>
            <input
                ref={ref}
                style={{ display: 'none' }}
                type="file"
                onChange={handleChange}
                accept={accept}
            />
        </Container>
    );
};

export default UploadButton;