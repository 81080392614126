import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import AdditionalInformationSection from './AdditionalInformationSection';
import BasicInformationSection from './BasicInformationSection';
import ContactInformationSection from './ContactInformationSection';
import { getAdditionalInformationTypes, getCandidate, updateCandidate } from '../../apis/candidate';
import { AdditionalInformationType } from '../../types/api';
import Button from '../../../core/components/Button';
import Page from '../../../core/components/Page';
import PageHeading from '../../../core/components/PageHeading';
import Row from '../../../core/components/Row';
import ACTIONS from '../../../core/constants/actions';
import { useReducerContext } from '../../../core/contexts/ReducerContext';
import { Nationality } from '../../../core/types/api';
import { DESKTOP_DEVICE, MOBILE_DEVICE } from '../../../core/constants/styles';
import ActionBar from '../../../core/components/ActionBar';
import SkillsSection from './SkillsSection';
import ScoutableSelect from './ScoutableSelect';
import { getProposals } from '../../../proposal/apis/proposals';
import { Proposal } from '../../../proposal/types';

interface CandidateDetailsPageProps {
}

const LocalPage = styled(Page)`
  @media ${MOBILE_DEVICE} {
    padding-top: 20px;
  }
`;

const LocalPageHeading = styled(PageHeading)`
  @media ${MOBILE_DEVICE} {
    padding: 0 20px;
  }
`;

const ActionContainer = styled(Row)`
    gap: 10px;
    align-self: center;
`;

const CandidateDetailsPage: FunctionComponent<CandidateDetailsPageProps> = () => {
    const { state, dispatch } = useReducerContext();
    const { candidateId } = useParams();
    const [receiveScout, setReceiveScout] = useState<boolean>();
    const [nationalities, setNationalities] = useState<Nationality[]>([]);
    const [additionalInformationTypes, setAdditionalInformationTypes] = useState<AdditionalInformationType[]>([]);
    const [avatar, setAvatar] = useState<string|undefined>(undefined)
    const [name, setName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState('');
    const [nameJP, setNameJP] = useState('');
    const [middleNameJP, setMiddleNameJP] = useState('');
    const [lastNameJP, setLastNameJP] = useState('');
    const [age, setAge] = useState('');
    const [gender, setGender] = useState('');
    const [nationality, setNationality] = useState('');
    const [visaStatus, setVisaStatus] = useState('');
    const [address, setAddress] = useState('');
    const [conversationSkills, setConversationSkills] = useState('');
    const [jlptLevel, setJlptLevel] = useState('');
    const [workHours, setWorkHours] = useState('');
    const [nearestPossibleDate, setNearestPossibleDate] = useState('');
    const [jobTypeId, setJobTypeId] = useState('');
    const [contact, setContact] = useState('');
    const [lineId, setLineId] = useState('');
    const [facebook, setFacebook] = useState('');
    const [uploads, setUploads] = useState<{ type: string, file: string }[]>([]);
    const [isContactInfoVisible, setIsContactInfoVisible] = useState(state.isPartner || state.isCandidate);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isMobileDevice = useMediaQuery({
        query: MOBILE_DEVICE,
    });
    const isDesktopDevice = useMediaQuery({
        query: DESKTOP_DEVICE,
    });
    const onReceiveScoutValueChange = (newValue: boolean) => {
        if (receiveScout === newValue)
            return;

        setReceiveScout(newValue);

        (async () => {
            try {
                dispatch({
                    type: ACTIONS.START_LOADING
                });
                const id = state.isCandidate? state.candidate!.id : candidateId!;
                const updatedCandidate = await updateCandidate(id, { receiveScout: newValue });

                dispatch({
                    type: ACTIONS.UPDATE_CANDIDATE,
                    payload: {
                        candidate: updatedCandidate
                    },
                });

            } catch (e) {
                //TODO: ideally show an error message 
            } finally {
                dispatch({
                    type: ACTIONS.STOP_LOADING,
                });
            }
        })();
    }

    useEffect(() => {
        (async () => {
            const additionalInformationTypes = await getAdditionalInformationTypes();
            additionalInformationTypes && setAdditionalInformationTypes(additionalInformationTypes);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            dispatch({
                type: ACTIONS.START_LOADING,
                payload: {
                    message: t('candidate.fetching_candidate_details'),
                },
            });
            try {
                const candidate = state.isCandidate
                    ? state.candidate!
                    : await getCandidate(candidateId!);

                if (state.isEmployer) {
                    const employerId = state.company!.employer!.id;
                    const proposals: Proposal[] = await getProposals({ candidateId, employerId });
                    const isCandidateHired = proposals.some(proposal => proposal.state === 'hired');
                    setIsContactInfoVisible(isCandidateHired);
                } 
                    
                if (candidate.image) {
                    setAvatar(candidate.image.url);
                }
                setName(candidate.enFirstName);
                setMiddleName(candidate.enMiddleName);
                setLastName(candidate.enLastName);
                setNameJP(candidate.jaFirstName);
                setMiddleNameJP(candidate.jaMiddleName);
                setLastNameJP(candidate.jaLastName)
                setAge(String(candidate.age));
                setGender(candidate.gender);
                setNationality(String(candidate.nationalityId));
                setVisaStatus(candidate.visaStatus);
                setAddress(candidate.currentAddress);
                setConversationSkills(candidate.japaneseConversationSkills);
                setJlptLevel(candidate.jlptLevel);
                setWorkHours(candidate.currentWorkHours);
                setNearestPossibleDate(candidate.nearestPossibleDate);
                setJobTypeId(String(candidate.jobTypeId));
                setContact(candidate.email);
                setLineId(candidate.lineId);
                setFacebook(candidate.facebookProfileLink);
                setUploads(candidate.additionalInformations.map((information) => ({
                    type: String(information.typeId),
                    file: information.url,
                })));
                setReceiveScout(candidate.receiveScout);
            } catch (e) {
                // TODO: error handling
            }
            dispatch({
                type: ACTIONS.STOP_LOADING,
            })
        })();
    }, []);

    return (
        <LocalPage>
            <Row center style={{ width: '100%', justifyContent: 'space-between'}}>
                {(state.isLoggedIn && !state.isEmployer && !state.isPartner)
                    ? (
                        <LocalPageHeading>{t('core.profile')}</LocalPageHeading>
                    )
                    : (
                        <LocalPageHeading>{name}{middleName && ` ${middleName}`} {lastName}</LocalPageHeading>
                    )
                }
                {((state.isCandidate || state.isPartner) && isDesktopDevice) && (
                    <ActionContainer>
                        { receiveScout !== undefined &&
                            <ScoutableSelect
                                initialValue={receiveScout}
                                onValueChange={onReceiveScoutValueChange}
                            />
                        }
                        <Button
                            variant="quart"
                            onClick={() => navigate('./edit')}
                            style={{ height: 30, padding: "5px 20px" }}
                        >
                            {t('core.edit')}
                        </Button>
                    </ActionContainer>
                )}
            </Row>

            <BasicInformationSection
                avatar={avatar}
                firstNameEn={name}
                middleNameEn={middleName}
                lastNameEn={lastName}
                firstNameJp={nameJP}
                middleNameJp={middleNameJP}
                lastNameJp={lastNameJP}
                age={age}
                gender={gender}
                nationality={state.nationalities[Number(nationality)]?.name}
                visaStatus={visaStatus}
                address={address}
                conversationSkills={conversationSkills}
                jlptLevel={jlptLevel}
                workHours={workHours}
                nearestPossibleDate={nearestPossibleDate}
            />

            <SkillsSection
                jobType={jobTypeId}
            />

            {   isContactInfoVisible &&
                <ContactInformationSection
                    email={contact}
                    lineId={lineId}
                    facebookLink={facebook}
                />
            }

            <AdditionalInformationSection
                additionalInformationTypes={additionalInformationTypes}
                uploads={uploads}
            />

            {(((state.isLoggedIn && !state.isEmployer && !state.isPartner) || state.isPartner)
                && isMobileDevice
            ) && (
                <ActionBar>
                    <Button
                        onClick={() => navigate('./edit')}
                    >
                        {t('core.edit')}
                    </Button>
                </ActionBar>
            )}
        </LocalPage>
    )
};

export default CandidateDetailsPage;