import axios from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import moment from 'moment';

import { Job } from '../job/types/api';


export const apiUtil = applyCaseMiddleware(
    axios.create({
        baseURL: process.env.REACT_APP_API_HOST,
        withCredentials: true,
    }),
    {
        ignoreHeaders: true,
        preservedKeys: [
            '_destroy',
        ],
    },
);

export const wait = (seconds: number) => new Promise<void>(
    (resolve) => {
        setTimeout(() => {
            resolve();
        }, seconds * 1000);
    },
);

export const isEmptyObject = (obj: object) => Object.keys(obj).length === 0;

export const isValidPostalCode = (code: string) => /^\d{7}$/.test(code);

export const isValidEmailAddress = (email?: string) => {
    if (!email) 
        return false;
    
    return /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
}

export const getJobLocation = (job: Job, language: string): string => {
    return (
        language === 'ja'
        ? `${job.jaPrefecture} ${job.jaCity} ${job.jaTown}` 
        : `${job.enPrefecture} ${job.enCity} ${job.enTown}`
    )
}

export const getSalary = (job: Job): string => {
    const isMontly = job.salaryType === 'monthly';
    const salary = isMontly? job.grossSalary : job.hourlyDailySalary;
    const upperLimit = isMontly? job.grossSalaryUpperLimit : job.hourlyDailySalaryUpperLimit;
    return upperLimit ? `${salary?.toLocaleString()} ~ ${upperLimit.toLocaleString()}` : salary?.toString() ?? ""
};

export const formatTime = (time: Date | string) => {
    return moment(time).format('YYYY/MM/DD HH:mm');
};

export const convertBytesToMB = (bytes: number, decimals = 1): string => {
    const MB = bytes / (1024 * 1024);
    return MB.toFixed(decimals);
  }

export const decodeHTMLEntities = (text: string | undefined): string | undefined => {
    if (!text)
        return;

    const txtArea = document.createElement("textarea");
    txtArea.innerHTML = text;
    return txtArea.value;
}

export const getLanguageIdFromCode = (languageCode: string): number | undefined => {
    switch (languageCode) {
        case 'ja': return 1;
        case 'en': return 2;
        case 'vi': return 3;
        default: return undefined;
    }
};

export const compareDate = (date1: Date, date2: Date) => {
    const time1 = date1.getTime();
    const time2 = date2.getTime();
    if (time1 < time2) {
        return -1;
    } else if (time1 > time2) {
        return 1;
    }
    return 0;
}