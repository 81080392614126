import { FunctionComponent, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AdditionalInformation, AdditionalInformationType } from '../types/api';
import CategoryHeading from '../../core/components/CategoryHeading';
import DownloadButton from '../../core/components/DownloadButton';
import Label from '../../core/components/Label';
import PageHeading from '../../core/components/PageHeading';
import Text from '../../core/components/Text';
import { MOBILE_DEVICE } from '../../core/constants/styles';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';

type CandidateOverviewProps = HTMLAttributes<HTMLDivElement> & {
    additionalInformationTypes?: { [key: number]: AdditionalInformationType };
    minimized?: boolean;
    avatar?: string;
    name?: string;
    age?: number;
    gender?: string;
    nationality?: string;
    japaneseLevel?: string;
    visa?: string;
    address?: string;
    workHours?: string;
    nearestPossibleDate?: string;
    additionalInformation?: AdditionalInformation[];
};

type LocalProps = {
    minimized?: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  @media ${MOBILE_DEVICE} {
    width: 100%;
  }
`;

const TopContainer = styled.div<LocalProps>`
  display: flex;
  flex-direction: ${(props: LocalProps) => props.minimized ? 'column' : 'row'};
  align-items: flex-start;
  
  @media ${MOBILE_DEVICE} {
    flex-direction: column;
  }
`;

const TopRightContainer = styled.div<LocalProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${(props: LocalProps) => props.minimized ? '11px' : '0'};
  margin-left: ${(props: LocalProps) => props.minimized ? '0' : '94px'};
  
  > *:not(:first-child) {
    margin-top: ${(props: LocalProps) => props.minimized ? '11px' : '8px'};
  }

  @media ${MOBILE_DEVICE} {
    margin-top: 20px;
    margin-left: 0;
    
    > *:not(:first-child) {
      margin-top: 5px;
    }
  }
`;

const MiddleContainer = styled.div<LocalProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${(props: LocalProps) => props.minimized ? '11px' : '23.5px'};

  > *:not(:first-child) {
    margin-top: 11px;
  }
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 30px;

  > *:not(:first-child) {
    margin-top: 11px;
  }
`;

const FieldRow = styled.div<LocalProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  > *:first-child {
    display: ${(props: LocalProps) => props.minimized ? 'none' : 'block'};
    width: 187px;
    margin-right: 8px;
    color: #999999;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media ${MOBILE_DEVICE} {
    flex-direction: column;
    align-items: flex-start;

    > *:first-child {
      margin-right: unset;
      margin-bottom: 4px;
    }
  }
`;

const FileRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > *:first-child {
    width: 185px;
    margin-right: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Avatar = styled.img`
  width: 101px;
  height: 101px;
  border-radius: 5px;
`;

const Link = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-height: 36px;
  min-height: 36px;
  padding: 0 20px;
  color: #017698;
  
  &.odd {
    background: #F6F6F6;
  }

  &.even {
    background: #FFFFFF;
  }
`;

const CandidateOverview: FunctionComponent<CandidateOverviewProps> = ({
    additionalInformationTypes,
    minimized,
    avatar,
    name,
    age,
    gender,
    nationality,
    japaneseLevel,
    visa,
    address,
    workHours,
    nearestPossibleDate,
    additionalInformation,
    ...props
}) => {
    const { t, i18n } = useTranslation();
    const isMobileDevice = useMediaQuery({
        query: MOBILE_DEVICE,
    });

    return (
        <Container {...props}>
            <TopContainer minimized={minimized}>
                <Avatar
                    alt="avatar"
                    src={avatar ?? '/images/avatar.png'}
                />
                <TopRightContainer minimized={minimized}>
                    {minimized
                        ? <CategoryHeading>{name}</CategoryHeading>
                        : <PageHeading>{name}</PageHeading>
                    }
                    <Text>{/*t('tokuty.years_old', age)*/}</Text>
                    <Text>{t(`core.${gender}`)}</Text>
                </TopRightContainer>
            </TopContainer>

            <MiddleContainer minimized={minimized}>
                {minimized
                    ? (
                        <>
                            <Text>{nationality ?? '-'}</Text>
                            <Text>{japaneseLevel?.toUpperCase() ?? '-'}</Text>
                            <Text>{t(`candidate.${visa}`)}</Text>

                            {/* This field is a required field but since it didn't exist before Oct 2023, 
                                still need to check if there is. */}
                            { nearestPossibleDate &&
                                <Text style={{ gap: 20 }}>
                                    <span style={{ color: "#626161"}}>
                                        {t("candidate.nearest_possible_date")}
                                    </span>   
                                    <span>
                                        {moment(nearestPossibleDate).format('YYYY/MM/DD')}
                                    </span>
                                </Text>
                            }
                        </>
                    )
                    : (
                        <>
                            <FieldRow>
                                <Text>{t('candidate.nationality')}</Text>
                                <Text>{nationality ?? '-'}</Text>
                            </FieldRow>
                            <FieldRow>
                                <Text>{t('candidate.japanese_level')}</Text>
                                <Text>{japaneseLevel?.toUpperCase() ?? '-'}</Text>
                            </FieldRow>
                            <FieldRow>
                                <Text>{t('candidate.candidate_visa_status')}</Text>
                                <Text>{t(`candidate.${visa}`)}</Text>
                            </FieldRow>
                            <FieldRow>
                                <Text>{t('candidate.current_address')}</Text>
                                <Text>{address ? address : '-'}</Text>
                            </FieldRow>
                            <FieldRow>
                                <Text>{t('candidate.current_work_hours')}</Text>
                                <Text>{workHours ? workHours : '-'}</Text>
                            </FieldRow>
                            <FieldRow>
                                <Text>{t('candidate.nearest_possible_date')}</Text>
                                <Text>{nearestPossibleDate 
                                  ? moment(nearestPossibleDate).format('YYYY/MM/DD')
                                  : '-'
                                }
                                </Text>
                            </FieldRow>
                        </>
                    )
                }
            </MiddleContainer>

            {!minimized && (
                <BottomContainer>
                    <Label>{t('candidate.attached_files')}</Label>
                    {additionalInformation?.map((information, index) => (isMobileDevice
                        ? (
                            <Text key={information.id} style={{ width: '100%' }}>
                                <Link className={index % 2 ? 'even' : 'odd' } download href={information.url}>
                                    {i18n.exists(`candidate.additional_information_types.${additionalInformationTypes?.[information.typeId]?.tkey}`)
                                        ? t(`candidate.additional_information_types.${additionalInformationTypes?.[information.typeId]?.tkey}`)
                                        : information.name
                                    }
                                </Link>
                            </Text>
                        )
                        : (
                            <FileRow key={information.id}>
                                <Text>
                                    {i18n.exists(`candidate.additional_information_types.${additionalInformationTypes?.[information.typeId]?.tkey}`)
                                        ? t(`candidate.additional_information_types.${additionalInformationTypes?.[information.typeId]?.tkey}`)
                                        : information.name
                                    }
                                </Text>
                                <DownloadButton href={information.url} />
                            </FileRow>
                        )
                    ))}
                </BottomContainer>
            )}
        </Container>
    );
};

export default CandidateOverview;