import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { COMMUTE_METHOD_ITEMS } from '../../constants/options';
import ButtonGroup from '../../../core/components/ButtonGroup';
import Column from '../../../core/components/Column';
import Field from '../../../core/components/Field';
import HelperText from '../../../core/components/HelperText';
import Label from '../../../core/components/Label';
import Radio from '../../../core/components/Radio';
import Row from '../../../core/components/Row';
import Section from '../../../core/components/Section';
import SectionHeading from '../../../core/components/SectionHeading';
import Select from '../../../core/components/Select';
import Text from '../../../core/components/Text';
import TextInput from '../../../core/components/TextInput';


type HousingSectionProps = {
    dormitoryAvailable?: boolean;
    dormitoryType?: string;
    bedroomCount?: string;
    housingAssisted?: boolean;
    leaseOwner?: string;
    commuteMethod?: string;
    commuteTime?: string;
    furnitureProvided?: boolean;
    movingTransportationExpenses?: boolean;
    movingTransportationExpensesAllowance?: string;
    movingBaggageExpenses?: boolean;
    movingBaggageExpensesAllowance?: string;
    onDormitoryAvailableChange?: (dormitoryAvailable: boolean) => void;
    onDormitoryTypeChange?: (dormitoryType: string) => void;
    onBedroomCountChange?: (bedroomCount: string) => void;
    onHousingAssistedChange?: (housingAssisted: boolean) => void;
    onLeaseOwnerChange?: (leaseOwner: string) => void;
    onCommuteMethodChange?: (commuteMethod: string) => void;
    onCommuteTimeChange?: (commuteTime: string) => void;
    onFurnitureProvidedChange?: (furnitureProvided: boolean) => void;
    onMovingTransportationExpensesChange?: (movingTransportationExpanses: boolean) => void;
    onMovingTransportationExpensesAllowanceChange?: (movingTransportationExpansesAllowance: string) => void;
    onMovingBaggageExpensesChange?: (movingBaggageExpanses: boolean) => void;
    onMovingBaggageExpensesAllowanceChange?: (movingBaggageExpansesAllowance: string) => void;
};

const HousingSection: FunctionComponent<HousingSectionProps> = ({
    dormitoryAvailable,
    dormitoryType,
    bedroomCount,
    housingAssisted,
    leaseOwner,
    commuteMethod,
    commuteTime,
    furnitureProvided,
    movingTransportationExpenses,
    movingTransportationExpensesAllowance,
    movingBaggageExpenses,
    movingBaggageExpensesAllowance,
    onDormitoryAvailableChange,
    onDormitoryTypeChange,
    onBedroomCountChange,
    onHousingAssistedChange,
    onLeaseOwnerChange,
    onCommuteMethodChange,
    onCommuteTimeChange,
    onFurnitureProvidedChange,
    onMovingTransportationExpensesChange,
    onMovingTransportationExpensesAllowanceChange,
    onMovingBaggageExpensesChange,
    onMovingBaggageExpensesAllowanceChange,
}) => {
    const { t } = useTranslation();

    return (
        <Section>
            <SectionHeading>{t('job.housing')}</SectionHeading>

            <Row>
                <Field>
                    <Label>{t('job.dormitory')}</Label>
                    <ButtonGroup style={{ marginTop: '8px' }}>
                        <Radio
                            checked={dormitoryAvailable === true}
                            onChange={() => onDormitoryAvailableChange && onDormitoryAvailableChange(true)}
                        >
                            {t('core.option_available')}
                        </Radio>
                        <Radio
                            checked={dormitoryAvailable === false}
                            onChange={() => onDormitoryAvailableChange && onDormitoryAvailableChange(false)}
                        >
                            {t('core.option_not_available')}
                        </Radio>
                    </ButtonGroup>
                </Field>
            </Row>

            <Row>
                {dormitoryAvailable === false
                    ? (
                        <>
                            <Field>
                                <Label>{t('job.employer_will_assist_in_finding_residence')}</Label>
                                <ButtonGroup style={{ marginTop: '8px' }}>
                                    <Radio
                                        checked={housingAssisted === true}
                                        onValueChange={() => onHousingAssistedChange && onHousingAssistedChange(true)}
                                    >
                                        {t('core.yes')}
                                    </Radio>
                                    <Radio
                                        checked={housingAssisted === false}
                                        onValueChange={() => onHousingAssistedChange && onHousingAssistedChange(false)}
                                    >
                                        {t('core.no')}
                                    </Radio>
                                </ButtonGroup>
                            </Field>
                            <Field style={{ marginLeft: '60px' }}>
                                <Label>{t('job.person_who_contracts_lease')}</Label>
                                <ButtonGroup style={{ marginTop: '8px' }}>
                                    <Radio
                                        value="employee"
                                        checked={leaseOwner === 'employee'}
                                        onValueChange={onLeaseOwnerChange}
                                    >
                                        {t('job.employee')}
                                    </Radio>
                                    <Radio
                                        value="employer"
                                        checked={leaseOwner === 'employer'}
                                        onValueChange={onLeaseOwnerChange}
                                    >
                                        {t('job.employer')}
                                    </Radio>
                                </ButtonGroup>
                            </Field>
                        </>
                    )
                    : (
                        <>
                            <Field>
                                <Label>{t('job.dormitory_type')}</Label>
                                <ButtonGroup style={{ marginTop: '8px' }}>
                                    <Radio
                                        value="apartment"
                                        checked={dormitoryType === 'apartment'}
                                        onValueChange={onDormitoryTypeChange}
                                    >
                                        {t('job.apartment')}
                                    </Radio>
                                    <Radio
                                        value="single_house"
                                        checked={dormitoryType === 'single_house'}
                                        onValueChange={onDormitoryTypeChange}
                                    >
                                        {t('job.single_house')}
                                    </Radio>
                                </ButtonGroup>
                            </Field>
                            <Field style={{ marginLeft: '30px' }}>
                                <Label>{t('job.bedroom')}</Label>
                                <Row center>
                                    <TextInput
                                        type="number"
                                        placeholder="1"
                                        style={{ width: '45px' }}
                                        value={bedroomCount}
                                        onTextChange={onBedroomCountChange}
                                    />
                                    <Text style={{ marginLeft: '4px' }}>{t('job.persons_room')}</Text>
                                </Row>
                            </Field>
                        </>
                    )
                }
            </Row>

            <Field>
                <Label>{t('job.commute_method_and_time')}</Label>
                <Row>
                    <Column>
                        <Select
                            placeholder="core.select"
                            options={COMMUTE_METHOD_ITEMS}
                            value={commuteMethod}
                            onValueChange={onCommuteMethodChange}
                        />
                        <HelperText style={{ marginTop: '4px' }}>
                            {t('job.commute_method_description')}
                        </HelperText>
                    </Column>
                    <Column style={{ marginLeft: '8px' }}>
                        <Row center>
                            <TextInput
                                type="number"
                                placeholder="0"
                                style={{ width: '45px' }}
                                value={commuteTime}
                                onTextChange={onCommuteTimeChange}
                            />
                            <Text style={{ marginLeft: '4px' }}>{t('job.minutes')}</Text>
                        </Row>
                        <HelperText style={{ marginTop: '4px' }}>
                            {t('job.commute_time_description')}
                        </HelperText>
                    </Column>
                </Row>
            </Field>

            <Field>
                <Label>{t('job.furniture_appliances')}</Label>
                <ButtonGroup style={{ marginTop: '8px' }}>
                    <Radio
                        checked={furnitureProvided === true}
                        onValueChange={() => onFurnitureProvidedChange && onFurnitureProvidedChange(true)}
                    >
                        {t('job.provided')}
                    </Radio>
                    <Radio
                        checked={furnitureProvided === false}
                        onValueChange={() => onFurnitureProvidedChange && onFurnitureProvidedChange(false)}
                    >
                        {t('job.not_provided')}
                    </Radio>
                </ButtonGroup>
            </Field>

            <Column>
                <Row>
                    <Field>
                        <Label>{t('job.moving_expenses_transportation')}</Label>
                        <ButtonGroup style={{ marginTop: '8px' }}>
                            <Radio
                                checked={movingTransportationExpenses === true}
                                onValueChange={() => onMovingTransportationExpensesChange && onMovingTransportationExpensesChange(true)}
                            >
                                {t('job.covered_by_employer')}
                            </Radio>
                            <Radio
                                checked={movingTransportationExpenses === false}
                                onValueChange={() => onMovingTransportationExpensesChange && onMovingTransportationExpensesChange(false)}
                            >
                                {t('job.paid_by_employee')}
                            </Radio>
                        </ButtonGroup>
                    </Field>
                    <Field style={{ marginLeft: '60px' }}>
                        <Label>{t('job.support_allowance')}</Label>
                        <Row center>
                            <TextInput
                                type="number"
                                placeholder="0"
                                style={{ width: '97px' }}
                                disabled={!movingTransportationExpenses}
                                value={movingTransportationExpensesAllowance}
                                onTextChange={onMovingTransportationExpensesAllowanceChange}
                            />
                        </Row>
                    </Field>
                </Row>
                <HelperText style={{ marginTop: '4px' }}>
                    {t('job.moving_expenses_transportation_description')}
                </HelperText>
            </Column>

            <Row>
                <Field>
                    <Label>{t('job.moving_expenses_baggage')}</Label>
                    <ButtonGroup style={{ marginTop: '8px' }}>
                        <Radio
                            checked={movingBaggageExpenses === true}
                            onValueChange={() => onMovingBaggageExpensesChange && onMovingBaggageExpensesChange(true)}
                        >
                            {t('job.covered_by_employer')}
                        </Radio>
                        <Radio
                            checked={movingBaggageExpenses === false}
                            onValueChange={() => onMovingBaggageExpensesChange && onMovingBaggageExpensesChange(false)}
                        >
                            {t('job.paid_by_employee')}
                        </Radio>
                    </ButtonGroup>
                </Field>
                <Field style={{ marginLeft: '60px' }}>
                    <Label>{t('job.support_allowance')}</Label>
                    <Row center>
                        <TextInput
                            type="number"
                            placeholder="0"
                            style={{ width: '97px' }}
                            disabled={!movingBaggageExpenses}
                            value={movingBaggageExpensesAllowance}
                            onTextChange={onMovingBaggageExpensesAllowanceChange}
                        />
                    </Row>
                </Field>
            </Row>
        </Section>
    );
};

export default HousingSection;