import { FunctionComponent, HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

type HelperTextProps = HTMLAttributes<HTMLSpanElement> & {
    error?: boolean;
    children?: ReactNode;
};

type ContainerProps = HelperTextProps & {
};

const Container = styled.span<ContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 20px;
  color: ${(props: ContainerProps) => props.error ? '#E93232' : '#999999'};
  zoom: 0.5;
`;

const HelperText: FunctionComponent<HelperTextProps> = ({
    children,
    ...props
}) => {
    return (
        <Container {...props}>{children}</Container>
    );
};

export default HelperText;